import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import ShowCard from "../ShowCard/ShowCard";
import { ReactComponent as Arrow } from "../../assets/Icons/filledArrow.svg";
import Translate from "../MultiLanguage/Translate";

const ShowsRow = ({thumbnailOrientation,season, data,type, title, metaData,watchVideoHandler,setVideoPlayer,similarVideos }) => {
  const [seasonButton, setSeasonButton] = useState(false);
  const [details, setDetails] = useState();
  const [seasons, setSeasons] = useState([]);
  useEffect(() => {
    if (data && !season) {
      setDetails(data);
    } else {
        setDetails(data[0]?.videos)
      setSeasons(data);
    }
  }, [data]);
  return (
    <div className="showsRow">
      {title && <h1 className="heading">{title}</h1>}
      {season && (
        <div
          className="seasonContainer"
          onClick={() => setSeasonButton(!seasonButton)}
        >
          <span className="selected">Season 1</span>

          <div className={seasonButton ? "list active" : "list"}>
            {seasons?.map((item, index) => (
              <div className="item" key={index} onClick={()=>setDetails(item?.videos)}>
                <span>{item?.season}</span>
              </div>
            ))}
          </div>
          <Arrow className={seasonButton ? "active" : "no-active"} />
        </div>
      )}
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={4}
        navigation={true}
        watchSlidesProgress
        loop={false}
        breakpoints={{
          320: {
            slidesPerView: thumbnailOrientation=="PORTRAIT"?2:1,
            spaceBetween: 2,
          },
          480:{
            slidesPerView: thumbnailOrientation=="PORTRAIT"?3:2,
            spaceBetween: 5,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: thumbnailOrientation=="PORTRAIT"?6:4,
            spaceBetween: 1.5,
          },
          1200:{
            slidesPerView: thumbnailOrientation=="PORTRAIT"?6:4,
            spaceBetween: 1.5,
          }
        }}
      >
        {details?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="show" onClick={()=>similarVideos?setVideoPlayer(null):watchVideoHandler(item)}>
              <ShowCard
                season={season ? true : false}
                data={item}
                imageUrl={true}
                metaData={metaData ? true : false}
                type={type}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ShowsRow;
