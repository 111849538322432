import { Fragment, memo, useEffect, useState } from "react";
import SectionSlider from "../slider/SectionSlider";
import LandscapeThumbnailCard from "../cards/LandscapeThumbnailCard";

const LandscapeCard = memo(({ data }) => {
  const [watching, setWatching] = useState([]);
  let itemLink;
  useEffect(() => {
    setWatching(data?.shows)
  }, [data]);

  return (
    <Fragment>
      <SectionSlider
        title={data?.category_name}
        list={watching}
        className="continue-watching-block landscape-margin-bottom"
        slidesPerView={6}
        data={data}
        styleElement={false}
        landscapeCard = {true}
        margin = {true}
      >
        {(data) => {
          if (data.type === "UPCOMING_EVENT" || data.type === "LIVE_EVENT") {
            itemLink = `/event/${data?.event_id}`
          } else if (data.type === "PODCAST") {
            itemLink = `/podcast/${data.podcast_id}`
          } else if (data.type === "SHOW") {
            itemLink = {
              pathname: `/show-details/${data?.vanity_url}`,
              state: { data: data }
            };
          } else if (data.type === "NEWS") {
            itemLink = "/news"
          }
          return <LandscapeThumbnailCard
            image={data?.logo_thumb }
            title={data?.show_name}
            movieTime={data?.duration_text}
            watchlistLink="/playlist"
            link={itemLink}
            data={data}
            share={true}
            vanity_url={data?.vanity_url}
            watchlistStatus={data?.watchlist_flag}
            showWatchList={true}
          ></LandscapeThumbnailCard>
        }}
      </SectionSlider>
    </Fragment>
  );
});

LandscapeCard.displayName = LandscapeCard;
export default LandscapeCard;
