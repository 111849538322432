import { fetchApiData, fetchApiDataV2 } from "../../utils/utils"

// export const getAllShowsOfCategory=async (appInfo,categoryId)=>{
//     const api=`category/${categoryId}/shows/list`
//     return await fetchApiData(appInfo,api)
// }
export const getAllShowsOfCategory=async (appInfo,careers)=>{
    const api=`category/${careers}`
    // if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
        return await fetchApiDataV2(appInfo,api)
    // }else{
    //     return await fetchApiData(appInfo,api)
    // }
}

