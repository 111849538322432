import { fetchApiData, fetchApiDataV2 } from "../../utils/utils";

export const fetchWatchlistShows= async (appInfo) => {
    const api = "show/watchlist";
    // if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
        return await fetchApiDataV2(appInfo,api)
    // }else{
    //     return await fetchApiData(appInfo,api)
    // }
}
