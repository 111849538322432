import { Fragment, memo } from "react";

//react bootstrap
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";

//function
// import { generateImgPath } from "../StaticData/data";

import { generateImgPath } from "./StaticData/data";
//img
const imagePath = generateImgPath("/assets/images/pages/01.webp");

const BreadCrumbWidget = memo((props) => {
  return (
    <Fragment>
      <div
        className={props.imgUrl?"iq-breadcrumb":"iq-breadcrumb_noImg"}
        style={{ backgroundImage:props.imgUrl? `url(${props.imgUrl})`: `url(${imagePath})` }}
      >
        <Container fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <nav className="text-center">
                <h2 className="title text-capitalize">{props.title}</h2>
                <Breadcrumb
                  className="main-bg"
                  listProps={{
                    className: "text-center justify-content-center",
                  }}
                >
                  {/* <Breadcrumb.Item >Home/</Breadcrumb.Item>
                  <Breadcrumb.Item active>View ALL</Breadcrumb.Item> */}
                </Breadcrumb>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});

BreadCrumbWidget.displayName = "BreadCrumbWidget";
export default BreadCrumbWidget;
