import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as service from "./service";
import Translate from "../../../Components/MultiLanguage/Translate";

const ForgotPassword = () => {
  const [values, setValues] = useState();
  const [errors, setErrors] = useState({});
  const accessToken = useSelector((state) => state.accessToken?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const navigate = useNavigate();
  const appInfo = {
    accessToken: accessToken,
    projectDetails: projectInfo,
  };
  const handleChange = (item) => {
    setValues(item?.target?.value);
  };

  const validateEmail = () => {
    let error = {};
    let emailValid = true;
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!values) {
      error.email = <Translate textKey={'email_required_message'}/>;
      emailValid = false;
    } else if (!values.match(emailRegex)) {
      error.email = <Translate textKey={'valid_email_message'} />;
      emailValid = false;
    }
    setErrors(error);
    return emailValid;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const emailValid = validateEmail();
      if (emailValid) {  
        const response = await service.forgotPasswordEmailVerify(appInfo, values);
        if (response?.status === 200) {
          toast.success(response?.data?.message, {
            position: "top-center",
          });
        } else {
          toast.error(response?.data?.message, {
            position: "top-center",
          });
        }
      } else {
        event.preventDefault();
        return false;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
    }
  };
  return (
    <div className="forgotPassword">
      <ToastContainer />
      <div className="wrapper">
        <div className="forgotPasswordContainer">
          <h1 className="heading"><Translate textKey={'reset_your_password'}/></h1>
          <div className="formContainer">
            <form action="post">
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={(item) => {
                  handleChange(item);
                }}
              />
              {errors.email && <span className="error">{errors.email}</span>}

              <div
                className="buttonContainer"
                onClick={(event) => {
                  handleSubmit(event);
                }}
              >
                <div className="background"></div>
                <button type="submit"><Translate textKey={'submit'} /></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
