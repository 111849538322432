export const generateImgPath = (path) => {
  return window.origin + "" + path;
};

export const geners = [
  {
    id: 1,
    slug: "action",
    title: "Action",
    thumbnail: generateImgPath("/assets/images/genre/01.webp"),
    type: "movie",
  },
  {
    id: 2,
    slug: "adventure",
    title: "Adventure",
    thumbnail: generateImgPath("/assets/images/genre/02.webp"),
    type: "movie",
  },
  {
    id: 3,
    slug: "animation",
    title: "Animation",
    thumbnail: generateImgPath("/assets/images/genre/03.webp"),
    type: "movie",
  },
  {
    id: 4,
    slug: "comedy",
    title: "Comedy",
    thumbnail: generateImgPath("/assets/images/genre/04.webp"),
    type: "movie",
  },
  {
    id: 5,
    slug: "drama",
    title: "Drama",
    thumbnail: generateImgPath("/assets/images/genre/05.webp"),
    type: "movie",
  },
  {
    id: 6,
    slug: "family",
    title: "Family",
    thumbnail: generateImgPath("/assets/images/genre/06.webp"),
    type: "movie",
  },
  {
    id: 7,
    slug: "fantacy",
    title: "Fantacy",
    thumbnail: generateImgPath("/assets/images/genre/07.webp"),
    type: "movie",
  },
];

export const tvShowGenres = [
  {
    id: 1,
    slug: "action",
    title: "Action",
    thumbnail: generateImgPath("/assets/images/genre/01.webp"),
    type: "movie",
  },
  {
    id: 2,
    slug: "adventure",
    title: "Adventure",
    thumbnail: generateImgPath("/assets/images/genre/02.webp"),
    type: "movie",
  },
  {
    id: 3,
    slug: "animation",
    title: "Animation",
    thumbnail: generateImgPath("/assets/images/genre/03.webp"),
    type: "movie",
  },
  {
    id: 4,
    slug: "comedy",
    title: "Comedy",
    thumbnail: generateImgPath("/assets/images/genre/04.webp"),
    type: "movie",
  },
  {
    id: 5,
    slug: "drama",
    title: "Drama",
    thumbnail: generateImgPath("/assets/images/genre/05.webp"),
    type: "movie",
  },
  {
    id: 6,
    slug: "family",
    title: "Family",
    thumbnail: generateImgPath("/assets/images/genre/06.webp"),
    type: "movie",
  },
  {
    id: 7,
    slug: "fantacy",
    title: "Fantacy",
    thumbnail: generateImgPath("/assets/images/genre/07.webp"),
    type: "movie",
  },
];

export const videoGenres = [
  {
    id: 1,
    slug: "action",
    title: "Action",
    thumbnail: generateImgPath("/assets/images/genre/01.webp"),
    type: "movie",
  },
  {
    id: 2,
    slug: "adventure",
    title: "Adventure",
    thumbnail: generateImgPath("/assets/images/genre/02.webp"),
    type: "movie",
  },
  {
    id: 3,
    slug: "animation",
    title: "Animation",
    thumbnail: generateImgPath("/assets/images/genre/03.webp"),
    type: "movie",
  },
  {
    id: 4,
    slug: "comedy",
    title: "Comedy",
    thumbnail: generateImgPath("/assets/images/genre/04.webp"),
    type: "movie",
  },
  {
    id: 5,
    slug: "drama",
    title: "Drama",
    thumbnail: generateImgPath("/assets/images/genre/05.webp"),
    type: "movie",
  },
  {
    id: 6,
    slug: "family",
    title: "Family",
    thumbnail: generateImgPath("/assets/images/genre/06.webp"),
    type: "movie",
  },
  {
    id: 7,
    slug: "fantacy",
    title: "Fantacy",
    thumbnail: generateImgPath("/assets/images/genre/07.webp"),
    type: "movie",
  },
];
export const cast = [
  {
    id: 1,
    profile_image: generateImgPath("/assets/images/cast/01.webp"),
    slug: "karen-gilchrist",
    title: "Karen Gilchrist",
    designation: "Production",
    known_credits: "10",
    birthday: "15-07-1985",
    birth_place: "New York",
    also_known_as: "David Backam",
    // movies: [filterMovies('sand-dust')],
    // tvShows: [filtertvShows('zombie-world')]
  },
  {
    id: 2,
    profile_image: generateImgPath("/assets/images/cast/02.webp"),
    slug: "debbi-bossi",
    title: "Debbi Bossi",
    designation: "Production",
    known_credits: "8",
    birthday: "",
    birth_place: "Recklinghausen",
    also_known_as: "Emery Camacho",
  },
  {
    id: 3,
    profile_image: generateImgPath("/assets/images/cast/03.webp"),
    slug: "james-chinlund",
    title: "James Chinlund",
    designation: "Art",
    known_credits: "10",
    birthday: "",
    birth_place: "Coldstream",
    also_known_as: "Shana Warren",
  },
  {
    id: 4,
    profile_image: generateImgPath("/assets/images/cast/04.webp"),
    slug: "brenda-chapman",
    title: "Brenda Chapman",
    designation: "Writing",
    known_credits: "11",
    birthday: "11-07-2012",
    birth_place: "Tournefeuille",
    also_known_as: "Abdul Harmon",
  },
  {
    id: 5,
    profile_image: generateImgPath("/assets/images/cast/05.webp"),
    slug: "mark-livolsi",
    title: "Mark Livolsi",
    designation: "Editing",
    known_credits: "9",
    birthday: "",
    birth_place: "Provost",
    also_known_as: "Hop Mcclure",
  },
  {
    id: 6,
    profile_image: generateImgPath("/assets/images/cast/02.webp"),
    slug: "hans-zimmer",
    title: "Hans Zimmer",
    designation: "Sound",
    known_credits: "8",
    birthday: "",
    birth_place: "Bayeux",
    also_known_as: "Mason Hansley",
  },
  {
    id: 7,
    profile_image: generateImgPath("/assets/images/cast/06.webp"),
    slug: "caleb-deschanel",
    title: "Caleb Deschanel",
    designation: "Camera",
    known_credits: "9",
    birthday: "12-01-1988",
    birth_place: "Bleid",
    also_known_as: "Harding Martinez",
  },
  {
    id: 8,
    profile_image: generateImgPath("/assets/images/cast/01.webp"),
    slug: "john-bartnicki",
    title: "John Bartnicki",
    designation: "Production",
    known_credits: "11",
    birthday: "06-07-1990",
    birth_place: "Perk",
    also_known_as: "Ali Miles",
  },
  {
    id: 9,
    profile_image: generateImgPath("/assets/images/cast/04.webp"),
    slug: "jeffrey-silver",
    title: "Jeffrey Silver",
    designation: "Production",
    known_credits: "4",
    birthday: "06-12-1995",
    birth_place: "Krasnoarmeysk",
    also_known_as: "Ingrid Carver",
  },
  {
    id: 10,
    profile_image: generateImgPath("/assets/images/cast/05.webp"),
    slug: "linda-woolverton",
    title: "Linda Woolverton",
    designation: "Writing",
    known_credits: "6",
    birthday: "02-11-1995",
    birth_place: "Rochester",
    also_known_as: "Forest Brooks",
  },
  {
    id: 11,
    profile_image: generateImgPath("/assets/images/cast/02.webp"),
    slug: "christoph-johnson",
    title: "Christoph Johnson",
    designation: "Acting , Directing",
    known_credits: "3",
    birthday: "28-10-1990",
    birth_place: "USA",
    also_known_as: "Christoph",
  },
  {
    id: 12,
    profile_image: generateImgPath("/assets/images/cast/06.webp"),
    slug: "robert-romanson",
    title: "Robert Romanson",
    designation: "Acting , Directing",
    known_credits: "3",
    birthday: "28-10-1989",
    birth_place: "London",
    also_known_as: "Roman",
  },
  {
    id: 13,
    profile_image: generateImgPath("/assets/images/cast/04.webp"),
    slug: "anna-romanson",
    title: "Anna Romanson",
    designation: "Acting , Directing",
    known_credits: "1",
    birthday: "28-10-1990",
    birth_place: "USA",
    also_known_as: "Roman",
  },
  {
    id: 14,
    profile_image: generateImgPath("/assets/images/cast/06.webp"),
    slug: "donald-glover",
    title: "Donald Glover",
    designation: "Acting",
    known_credits: "1",
    birthday: "",
    birth_place: "Warren",
    also_known_as: "Adena Beck",
  },
  {
    id: 15,
    profile_image: generateImgPath("/assets/images/cast/05.webp"),
    slug: "beyoncé-knowles",
    title: "Beyoncé Knowles",
    designation: "Acting",
    known_credits: "1",
    birthday: "",
    birth_place: "Herk-de-Stad",
    also_known_as: "Thane Hurst",
  },
  {
    id: 16,
    profile_image: generateImgPath("/assets/images/cast/01.webp"),
    slug: "james-earl-jones",
    title: "James Earl Jones",
    designation: "Acting",
    known_credits: "10",
    birthday: "",
    birth_place: "Girona",
    also_known_as: "Geoffrey Castillo",
  },
  {
    id: 17,
    profile_image: generateImgPath("/assets/images/cast/06.webp"),
    slug: "chiwetel-ejiofor",
    title: "Chiwetel Ejiofor",
    designation: "Acting",
    known_credits: "1",
    birthday: "02-05-1986",
    birth_place: "Sheffield",
    also_known_as: "Stella Neilsen",
  },
  {
    id: 18,
    profile_image: generateImgPath("/assets/images/cast/05.webp"),
    slug: "alfre-woodard",
    title: "Alfre Woodard",
    designation: "Acting",
    known_credits: "1",
    birthday: "",
    birth_place: "Daiano",
    also_known_as: "Keelie Maddox",
  },
  {
    id: 19,
    profile_image: generateImgPath("/assets/images/cast/02.webp"),
    slug: "john-oliver",
    title: "John Oliver",
    designation: "Acting",
    known_credits: "2",
    birthday: "",
    birth_place: "Sint-Kruis-Winkel",
    also_known_as: "Maile Burnett",
  },
  {
    id: 20,
    profile_image: generateImgPath("/assets/images/cast/01.webp"),
    slug: "john-kani",
    title: "John Kani",
    designation: "Acting",
    known_credits: "4",
    birthday: "",
    birth_place: "Durg",
    also_known_as: "Maris Fisher",
  },
  {
    id: 21,
    profile_image: generateImgPath("/assets/images/cast/04.webp"),
    slug: "seth-rogen",
    title: "Seth Rogen",
    designation: "Acting",
    known_credits: "1",
    birthday: "",
    birth_place: "Rouvreux",
    also_known_as: "Gretchen Cunningham",
  },
  {
    id: 22,
    profile_image: generateImgPath("/assets/images/cast/02.webp"),
    slug: "billy-eichner",
    title: "Billy Eichner",
    designation: "Acting",
    known_credits: "1",
    birthday: "",
    birth_place: "Bellefontaine",
    also_known_as: "Cassidy Hayden",
  },
  {
    id: 23,
    profile_image: generateImgPath("/assets/images/cast/02.webp"),
    slug: "eric-andré",
    title: "Eric André",
    designation: "Acting",
    known_credits: "2",
    birthday: "",
    birth_place: "Eisenhüttenstadt",
    also_known_as: "Chester Porter",
  },
  {
    id: 24,
    profile_image: generateImgPath("/assets/images/cast/05.webp"),
    slug: "florence-kasumba",
    title: "Florence Kasumba",
    designation: "Acting",
    known_credits: "2",
    birthday: "",
    birth_place: "Harbour Grace",
    also_known_as: "Adam Wolfe",
  },
  {
    id: 25,
    profile_image: generateImgPath("/assets/images/cast/04.webp"),
    slug: "keegan-michael-key",
    title: "Keegan-Michael Key",
    designation: "Acting",
    known_credits: "2",
    birthday: "",
    birth_place: "Monacillioni",
    also_known_as: "Amanda Harrington",
  },
  {
    id: 26,
    profile_image: generateImgPath("/assets/images/cast/06.webp"),
    slug: "jd-mccrary",
    title: "JD McCrary",
    designation: "Acting",
    known_credits: "2",
    birthday: "06-10-2017",
    birth_place: "Vielsalm",
    also_known_as: "Summer Mccullough",
  },
  {
    id: 27,
    profile_image: generateImgPath("/assets/images/cast/01.webp"),
    slug: "shahadi-wright-joseph",
    title: "Shahadi Wright Joseph",
    designation: "Acting",
    known_credits: "2",
    birthday: "06-02-1983",
    birth_place: "María Pinto",
    also_known_as: "Medge Holman",
  },
  {
    id: 28,
    profile_image: generateImgPath("/assets/images/cast/05.webp"),
    slug: "amy-sedaris",
    title: "Amy Sedaris",
    designation: "Acting",
    known_credits: "2",
    birthday: "03-12-1984",
    birth_place: "Broken Arrow",
    also_known_as: "Gretchen Shelton",
  },
  {
    id: 29,
    profile_image: generateImgPath("/assets/images/cast/06.webp"),
    slug: "jon-favreau",
    title: "Jon Favreau",
    designation: "Directing",
    known_credits: "2",
    birthday: "",
    birth_place: "Zutendaal",
    also_known_as: "Perry Glass",
  },
  {
    id: 30,
    profile_image: generateImgPath("/assets/images/cast/02.webp"),
    slug: "jeff-nathanson",
    title: "Jeff Nathanson",
    designation: "Writing",
    known_credits: "10",
    birthday: "",
    birth_place: "Ribeirão das Neves",
    also_known_as: "Amelia Mann",
  },
  {
    id: 31,
    profile_image: generateImgPath("/assets/images/cast/01.webp"),
    slug: "irene-mecchi",
    title: "Irene Mecchi",
    designation: "Writing",
    known_credits: "5",
    birthday: "",
    birth_place: "Beauvechain",
    also_known_as: "Lev Cleveland",
  },
  {
    id: 32,
    profile_image: generateImgPath("/assets/images/cast/06.webp"),
    slug: "jonathan-roberts",
    title: "Jonathan Roberts",
    designation: "Writing",
    known_credits: "4",
    birthday: "",
    birth_place: "Saint Luis",
    also_known_as: "Zane Weaver",
  },
  {
    id: 33,
    profile_image: generateImgPath("/assets/images/cast/03.webp"),
    slug: "jennifer-lonez",
    title: "Jennifer Lonez",
    designation: "Acting , Directing",
    known_credits: "",
    birthday: "",
    birth_place: "",
    also_known_as: "",
  },
  {
    id: 34,
    profile_image: generateImgPath("/assets/images/cast/04.webp"),
    slug: "mars-shelley",
    title: "Mars Shelley",
    designation: "Acting , Directing",
    known_credits: "",
    birthday: "",
    birth_place: "",
    also_known_as: "",
  },
  {
    id: 35,
    profile_image: generateImgPath("/assets/images/cast/05.webp"),
    slug: "rosa-romanez",
    title: "Rosa Romanez",
    designation: "Acting , Directing",
    known_credits: "",
    birthday: "",
    birth_place: "",
    also_known_as: "",
  },
  {
    id: 36,
    profile_image: generateImgPath("/assets/images/cast/06.webp"),
    slug: "anna-martez",
    title: "Anna martez",
    designation: "Acting , Directing",
    known_credits: "",
    birthday: "",
    birth_place: "",
    also_known_as: "",
  },
  {
    id: 37,
    profile_image: generateImgPath("/assets/images/cast/01.webp"),
    slug: "martin-mcKandy",
    title: "Martin McKandy",
    designation: "Acting , Directing",
    known_credits: "",
    birthday: "",
    birth_place: "",
    also_known_as: "",
  },
  {
    id: 38,
    profile_image: generateImgPath("/assets/images/cast/02.webp"),
    slug: "robert-rodriguez",
    title: "Robert Rodriguez",
    designation: "Acting , Directing",
    known_credits: "",
    birthday: "",
    birth_place: "",
    also_known_as: "sadfsaf",
  },
  {
    id: 39,
    profile_image: generateImgPath("/assets/images/cast/03.webp"),
    slug: "lili-morgen",
    title: "Lili Morgen",
    designation: "Acting , Directing",
    known_credits: "",
    birthday: "",
    birth_place: "",
    also_known_as: "",
  },
  {
    id: 40,
    profile_image: generateImgPath("/assets/images/cast/04.webp"),
    slug: "cristina-morgan",
    title: "Cristina Morgan",
    designation: "Acting , Directing",
    known_credits: "",
    birthday: "",
    birth_place: "",
    also_known_as: "",
  },
];
export const movieTags = [
  {
    id: 1,
    slug: "action",
    title: "Action",
    type: "movie",
  },
  {
    id: 2,
    slug: "adventure",
    title: "Adventure",
    type: "movie",
  },
  {
    id: 3,
    slug: "animation",
    title: "Animation",
    type: "movie",
  },
  {
    id: 4,
    slug: "comedy",
    title: "Comedy",
    type: "movie",
  },
  {
    id: 5,
    slug: "drama",
    title: "Drama",
    type: "movie",
  },
  {
    id: 6,
    slug: "family",
    title: "Family",
    type: "movie",
  },
  {
    id: 7,
    slug: "fantacy",
    title: "Fantacy",
    type: "movie",
  },
  {
    id: 8,
    slug: "history",
    title: "History",
    type: "movie",
  },
  {
    id: 9,
    slug: "horror",
    title: "Horror",
    type: "movie",
  },
  {
    id: 10,
    slug: "mystery",
    title: "Mystery",
    type: "movie",
  },
  {
    id: 11,
    slug: "recommended",
    title: "Recommended",
    type: "movie",
  },
  {
    id: 12,
    slug: "sci-fi",
    title: "Sci-Fi",
    type: "movie",
  },
  {
    id: 13,
    slug: "space",
    title: "Space",
    type: "movie",
  },
  {
    id: 14,
    slug: "thriller",
    title: "Thriller",
    type: "movie",
  },
];

export const tvShowsTags = [
  {
    id: 1,
    slug: "4k-ultra",
    title: "4K Ultra",
    type: "tv-show",
  },
  {
    id: 2,
    slug: "brother",
    title: "Brother",
    type: "tv-show",
  },
  {
    id: 3,
    slug: "brother-relationship",
    title: "Brother Relationship",
    type: "tv-show",
  },
  {
    id: 4,
    slug: "kings",
    title: "Kings",
    type: "tv-show",
  },
  {
    id: 5,
    slug: "music",
    title: "Music",
    type: "tv-show",
  },
  {
    id: 6,
    slug: "mythology",
    title: "Mythology",
    type: "tv-show",
  },
  {
    id: 7,
    slug: "premieres",
    title: "Premieres",
    type: "tv-show",
  },
  {
    id: 8,
    slug: "vikings",
    title: "Vikings",
    type: "tv-show",
  },
];

export const videoTags = [
  {
    id: 1,
    slug: "action",
    title: "Action",
    type: "video",
  },
  {
    id: 2,
    slug: "adventure",
    title: "Adventure",
    type: "video",
  },
  {
    id: 3,
    slug: "animation",
    title: "Animation",
    type: "video",
  },
  {
    id: 4,
    slug: "comedy",
    title: "Comedy",
    type: "video",
  },
  {
    id: 5,
    slug: "crime",
    title: "Crime",
    type: "video",
  },
  {
    id: 6,
    slug: "drama",
    title: "Drama",
    type: "video",
  },
  {
    id: 7,
    slug: "family",
    title: "Family",
    type: "video",
  },
  {
    id: 8,
    slug: "fantacy",
    title: "fantacy",
    type: "video",
  },
  {
    id: 9,
    slug: "horror",
    title: "Horror",
    type: "video",
  },
  {
    id: 10,
    slug: "romance",
    title: "Romance",
    type: "video",
  },
  {
    id: 11,
    slug: "thriller",
    title: "Thriller",
    type: "video",
  },
];

export const sectionSliders = [
  {
    image: generateImgPath("/assets/images/movies/related/01.webp"),
    title: "Giikre",
    movieTime: "2hr: 12mins",
  },
  {
    image: generateImgPath("/assets/images/movies/related/02.webp"),
    title: "YoShi",
    movieTime: "1hr: 22mins",
  },
  {
    image: generateImgPath("/assets/images/movies/related/03.webp"),
    title: "We Gare",
    movieTime: "1hr: 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/related/04.webp"),
    title: "Avengers",
    movieTime: "1hr: 45mins",
  },
  {
    image: generateImgPath("/assets/images/movies/related/05.webp"),
    title: "Chosfies",
    movieTime: "1hr: 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/related/06.webp"),
    title: "Tf Oaler",
    movieTime: "1hr: 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/related/07.webp"),
    title: "Another Danger",
    movieTime: "1hr: 30mins",
  },
];

export const populerSlider = [
  {
    image: generateImgPath("/assets/images/movies/popular/01.webp"),
    title: "CRW",
    movieTime: "2hr: 12mins",
  },
  {
    image: generateImgPath("/assets/images/movies/popular/02.webp"),
    title: "Batte Wiire",
    movieTime: "1hr: 22mins",
  },
  {
    image: generateImgPath("/assets/images/movies/popular/03.webp"),
    title: "Goal",
    movieTime: "2hr: 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/popular/04.webp"),
    title: "Dandacg",
    movieTime: "1hr: 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/popular/05.webp"),
    title: "Mexcan",
    movieTime: "1hr: 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/popular/06.webp"),
    title: "Oit moleld",
    movieTime: "1hr: 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/popular/07.webp"),
    title: "Another Danger",
    movieTime: "1hr: 30mins",
  },
];

export const ottVerticleLatestMovies = [
  {
    image: generateImgPath("/assets/images/top-10/01.webp"),
    title: "lostti n sacee",
    movieTime: "2 hr 30 minute",
  },
  {
    image: generateImgPath("/assets/images/top-10/02.webp"),
    title: "Ret Seap",
    movieTime: "3 hr: 0 minute",
  },
  {
    image: generateImgPath("/assets/images/top-10/03.webp"),
    title: "X-Men",
    movieTime: "1 hr: 45mins",
  },
  {
    image: generateImgPath("/assets/images/top-10/04.webp"),
    title: "Logan",
    movieTime: "1hr: 22mins",
  },
  {
    image: generateImgPath("/assets/images/top-10/05.webp"),
    title: "Black Queen",
    movieTime: "1hr: 45mins",
  },
];

export const verticleLatestMovies = [
  {
    image: generateImgPath("/assets/images/top-10/05.webp"),
    title: "Black Queen",
    movieTime: "2hr: 30mins",
  },
  {
    image: generateImgPath("/assets/images/top-10/06.webp"),
    title: "wars of dragons",
    movieTime: "3hr: 00mins",
  },
  {
    image: generateImgPath("/assets/images/top-10/07.webp"),
    title: "the Escape",
    movieTime: "1hr: 45mins",
  },
  {
    image: generateImgPath("/assets/images/top-10/08.webp"),
    title: "X-Men",
    movieTime: "1hr: 22mins",
  },
  {
    image: generateImgPath("/assets/images/top-10/09.webp"),
    title: "Logan",
    movieTime: "1hr: 45mins",
  },
];

export const recommendedforYou = [
  {
    image: generateImgPath("/assets/images/movies/recommended/01.webp"),
    title: "Giikre",
    movieTime: "2hr: 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/recommended/02.webp"),
    title: "Arrival",
    movieTime: "3hr: 00mins",
  },
  {
    image: generateImgPath("/assets/images/movies/recommended/03.webp"),
    title: "Pricess",
    movieTime: "1hr: 45mins",
  },
  {
    image: generateImgPath("/assets/images/movies/recommended/04.webp"),
    title: "Soull Meate",
    movieTime: "2hr: 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/recommended/05.webp"),
    title: "Dangacg",
    movieTime: "1hr: 45mins",
  },
  {
    image: generateImgPath("/assets/images/movies/recommended/06.webp"),
    title: "crcikeft",
    movieTime: "2hr: 45mins",
  },
  {
    image: generateImgPath("/assets/images/movies/recommended/07.webp"),
    title: "Avengrs",
    movieTime: "1hr: 45mins",
  },
];

export const tabSlider = [
  {
    image: generateImgPath("/assets/images/tab-slider/01.webp"),
    title: "The hunter",
    movieTime: "in Series Today",
    seasons: [
      {
        title: "Season 1",
        episodes: [
          {
            image: generateImgPath("/assets/images/tv-show/season/01.webp"),
            title: "Fire and Blood",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/02.webp"),
            title: "The Pointy Endr",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/03.webp"),
            title: "You Win or You Die",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/04.webp"),
            title: "The Black Queen",
            movieTime: "45 minutes",
          },
        ],
      },
      {
        title: "Season 2",
        episodes: [
          {
            image: generateImgPath("/assets/images/tv-show/season/05.webp"),
            title: "Valar Morghulis",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/06.webp"),
            title: "Blackwater",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/07.webp"),
            title: "The Ghost of Harrenhal",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/08.webp"),
            title: "Garden of Bones",
            movieTime: "45 minutes",
          },
        ],
      },
      {
        title: "Season 3",
        episodes: [
          {
            image: generateImgPath("/assets/images/tv-show/season/09.webp"),
            title: "Mhysa",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/10.webp"),
            title: "Second Sons",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/11.webp"),
            title: "The Climb",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/12.webp"),
            title: "Kissed by Fire",
            movieTime: "45 minutes",
          },
        ],
      },
    ],
  },
  {
    image: generateImgPath("/assets/images/tab-slider/02.webp"),
    title: "Pirates of Dayones",
    movieTime: "in Series Yesterday",
    seasons: [
      {
        title: "Season 1",
        episodes: [
          {
            image: generateImgPath("/assets/images/tv-show/season/13.webp"),
            title: "The Curse of the Black Pearl",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/14.webp"),
            title: "Dead Man's Chest",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/15.webp"),
            title: "At World's End",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/16.webp"),
            title: "On Stranger Tides",
            movieTime: "45 minutes",
          },
        ],
      },
      {
        title: "Season 2",
        episodes: [
          {
            image: generateImgPath("/assets/images/tv-show/season/17.webp"),
            title: "Dead Men Tell No Tales",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/18.webp"),
            title: "Tales of the Code",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/19.webp"),
            title: "Romance Dawn",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/20.webp"),
            title: "The Man in the Straw Hat",
            movieTime: "45 minutes",
          },
        ],
      },
      {
        title: "Season 3",
        episodes: [
          {
            image: generateImgPath("/assets/images/tv-show/season/21.webp"),
            title: "Tell No Tales",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/22.webp"),
            title: "The Pirates Are Coming",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/23.webp"),
            title: "Eat at Baratie!",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/24.webp"),
            title: "The Chef and the Chore Boy",
            movieTime: "45 minutes",
          },
        ],
      },
    ],
  },
  {
    image: generateImgPath("/assets/images/tab-slider/03.webp"),
    title: "peaky blinders",
    movieTime: "in Series tomorrow",
    seasons: [
      {
        title: "Season 1",
        episodes: [
          {
            image: generateImgPath("/assets/images/tv-show/season/25.webp"),
            title: "Episode 1",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/26.webp"),
            title: "Episode 2",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/27.webp"),
            title: "Episode 3",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/28.webp"),
            title: "Episode 4",
            movieTime: "45 minutes",
          },
        ],
      },
      {
        title: "Season 2",
        episodes: [
          {
            image: generateImgPath("/assets/images/tv-show/season/29.webp"),
            title: "Episode 1",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/30.webp"),
            title: "Episode 2",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/31.webp"),
            title: "Episode 3",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/32.webp"),
            title: "Episode 4",
            movieTime: "45 minutes",
          },
        ],
      },
      {
        title: "Season 3",
        episodes: [
          {
            image: generateImgPath("/assets/images/tv-show/season/33.webp"),
            title: "Episode 1",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/34.webp"),
            title: "Episode 2",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/35.webp"),
            title: "Episode 3",
            movieTime: "45 minutes",
          },
          {
            image: generateImgPath("/assets/images/tv-show/season/36.webp"),
            title: "Episode 4",
            movieTime: "45 minutes",
          },
        ],
      },
    ],
  },
];

export const topPics = [
  {
    image: generateImgPath("/assets/images/movies/top-picks/01.webp"),
    title: "Frzzen",
    movieTime: "2hr : 12mins",
  },
  {
    image: generateImgPath("/assets/images/movies/top-picks/02.webp"),
    title: "The Crew",
    movieTime: "1hr : 22mins",
  },
  {
    image: generateImgPath("/assets/images/movies/top-picks/03.webp"),
    title: "Cromeus",
    movieTime: "1hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/top-picks/04.webp"),
    title: "Reodg",
    movieTime: "1hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/top-picks/05.webp"),
    title: "guiility",
    movieTime: "1hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/top-picks/06.webp"),
    title: "syyvcihonic",
    movieTime: "1hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/top-picks/07.webp"),
    title: "tittanc",
    movieTime: "1hr : 30mins",
  },
];

export const latestMovie = [
  {
    image: generateImgPath("/assets/images/movies/latest/01.webp"),
    title: "mortal nories",
    movieTime: "2hr : 12mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/02.webp"),
    title: "advetre",
    movieTime: "1hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/03.webp"),
    title: "net ailo",
    movieTime: "2hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/04.webp"),
    title: "ariivaal",
    movieTime: "2hr : 45mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/05.webp"),
    title: "dramma",
    movieTime: "1hr : 55mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/06.webp"),
    title: "aune",
    movieTime: "1hr : 25mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/07.webp"),
    title: "everest",
    movieTime: "1hr : 45mins",
  },
];

export const suggested = [
  {
    image: generateImgPath("/assets/images/movies/suggested/01.webp"),
    title: "choflief",
    movieTime: "2hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/suggested/02.webp"),
    title: "lost",
    movieTime: "3hr : 0mins",
  },
  {
    image: generateImgPath("/assets/images/movies/suggested/03.webp"),
    title: "red hamald",
    movieTime: "1hr : 45mins",
  },
  {
    image: generateImgPath("/assets/images/movies/suggested/04.webp"),
    title: "pandap",
    movieTime: "2hr : 45mins",
  },
  {
    image: generateImgPath("/assets/images/movies/suggested/05.webp"),
    title: "femllaeemll",
    movieTime: "1hr : 55mins",
  },
  {
    image: generateImgPath("/assets/images/movies/suggested/06.webp"),
    title: "ragnarorr",
    movieTime: "2hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/suggested/07.webp"),
    title: "sonil",
    movieTime: "2hr : 15mins",
  },
];

export const spacialLatestMovie = [
  {
    image: generateImgPath("/assets/images/movies/latest/01.webp"),
    title: "mortal nories",
    movieTime: "2hr : 12mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/02.webp"),
    title: "advetre",
    movieTime: "1hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/03.webp"),
    title: "net ailo",
    movieTime: "2hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/04.webp"),
    title: "ariivaal",
    movieTime: "2hr : 45mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/05.webp"),
    title: "dramma",
    movieTime: "1hr : 55mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/06.webp"),
    title: "aune",
    movieTime: "1hr : 25mins",
  },
  {
    image: generateImgPath("/assets/images/movies/latest/07.webp"),
    title: "everest",
    movieTime: "1hr : 45mins",
  },
];
export const upcommingMovie = [
  {
    image: generateImgPath("/assets/images/movies/upcoming/01.webp"),
    title: "dinoosaur",
    movieTime: "2hr : 12mins",
  },
  {
    image: generateImgPath("/assets/images/movies/upcoming/02.webp"),
    title: "godilla",
    movieTime: "1hr : 22mins",
  },
  {
    image: generateImgPath("/assets/images/movies/upcoming/03.webp"),
    title: "batter caill",
    movieTime: "1hr : 55mins",
  },
  {
    image: generateImgPath("/assets/images/movies/upcoming/04.webp"),
    title: "the co nouerllng",
    movieTime: "1hr : 30mins",
  },
  {
    image: generateImgPath("/assets/images/movies/upcoming/05.webp"),
    title: "fast furious",
    movieTime: "2hr : 45mins",
  },
  {
    image: generateImgPath("/assets/images/movies/upcoming/06.webp"),
    title: "spiderman",
    movieTime: "1hr : 45mins",
  },
  {
    image: generateImgPath("/assets/images/movies/upcoming/07.webp"),
    title: "onepeoc",
    movieTime: "2hr : 30mins",
  },
];
