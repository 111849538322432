import React from 'react'
import Translate from "../MultiLanguage/Translate"

const PlayNextEpisode = ({ episodes, findIndex, watchVideoHandler, setNextEpisodeModal }) => {
  let episodeCount = 0
  const playNextEpisodeHandler = () => {
    episodeCount = findIndex() + 1
    watchVideoHandler(episodes[episodeCount])
    setNextEpisodeModal(false)
  }

  return (
    <div className="playNextEpisode">
      <div className="overlayModal"></div>
      <div className="contents">
        <h1><Translate textKey={'next_episode_text'} /></h1>
        <div className="buttons">
          <div className="buttonContainer" onClick={() => playNextEpisodeHandler()} >
            <div className="background"></div>
            <button><Translate textKey={'yes'} /></button>
          </div>

          <div className="buttonContainer" onClick={() => setNextEpisodeModal(false)}>
            <div className="background"></div>
            <button  ><Translate textKey={'no'} /></button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default PlayNextEpisode
