import { useEffect, useRef, useState, memo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainLoading from "../../Components/Loading/MainLoading";
import { getMoreInfo, getOverlay } from "../../Redux/MoreInfo/moreInfoSlice";
import { getRandomItem } from "../../utils/utils";
import Categories from "./Components/Categories";
import Featured from "./Components/Featured";
import Live from "./Components/Live";
import * as service from "./service";
import "animate.css";
import { useLocation, useParams } from "react-router-dom";
import { getHomeData } from "../../Redux/HomeData/homeDataSlice";
import { getEventMoreInfo } from "../../Redux/MoreInfo/eventMoreInfoSlice";
// import EventDetailsScreenModal from "../EventDetailsScreenModal/EventDetailsScreenModal";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import { useNavigate } from "react-router-dom/dist";
import ContentSections from "../../Components/sections/ContentSections";

const HomePage = memo(() => {

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const homeCollection = useSelector((state) => state?.homeData?.value);
  const isMoreInfo = useSelector((state) => state?.moreInfo?.value);
  const isEventMoreInfo = useSelector((state) => state?.eventMoreInfo?.value);
  const isMoreInfoOther = useSelector((state) => state?.moreInfoOther?.value);
  const user = useSelector((state) => state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);

  const [featured, setFeatured] = useState()
  const [loading, setLoading] = useState(true);
  const [homeData, setHomeData] = useState([]);
  const [homeDataSliceCount, setHomeDataSliceCount] = useState(8);
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [fontClass, setFontClass] = useState("");
  const dispatch = useDispatch()
  const location = useLocation()
  const homePageContainerRef = useRef(null);
  const scrollPosition = useScrollPosition();

  let fontStylesClassName = ["gagalin", "gota"];
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  const navigate = useNavigate()

  useEffect(() => {

    if (projectInfo?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED === "true") {

      if (userDetails?.language_id === null || !userDetails?.language_id || userDetails?.language_id === "") {
        navigate("/language-selection")
        localStorage.setItem("redirectURL", "/home")
      }
    }
  }, [userDetails])

  useEffect(() => {
    setLoading(true);
    fetchShowDetails();
    setFontClass(getRandomItem(fontStylesClassName));
  }, [user]);

  useEffect(() => {

    if (!loading && homeDataSliceCount < homeData?.length) {
      lazyLoad()
    }
  }, [loading, scrollPosition, homeData])

  useEffect(() => {

    if (homeCollection?.length > 0) {
      setHomeData(homeCollection)
    }
  }, [homeCollection])

  useEffect(() => {

    if (projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT") {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {

    if (location?.pathname) {
      let id = location?.pathname.split("/")[2];
      let path = location?.pathname.split("/")[1];

      if (id !== "null" && id !== "undefined" && id && path === "show-details") {
        dispatch(
          getMoreInfo({
            moreInfo: true,
          })
        )
      }
      else if (id !== "null" && id !== "undefined" && id && path === "event") {
        dispatch(
          getEventMoreInfo({
            eventMoreInfo: {
              isModal: true
            },
          })
        )
      }
    }
  }, [location?.pathname]);

  const fetchShowDetails = async () => {
    try {
      const response = await service.getShowsByCategory(appInfo);

      if (response?.status === 200) {
        setFeatured(response?.data?.data?.find(item => item.type === "FEATURED"))
        dispatch(
          getHomeData({
            homeData: response?.data?.data
          })
        )
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  const bottomOfPageRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {

      if (
        bottomOfPageRef.current?.getBoundingClientRect().top <=
        window.innerHeight
      ) {
        console.log("User has reached the end of the window!");
        // You can trigger any actions you need here
      }
    };


    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const lazyLoad = () => {
    const containerHeight = homePageContainerRef?.current?.clientHeight;

    if (scrollPosition + 2500 >= containerHeight) {
      setHomeDataSliceCount(homeDataSliceCount + 5)
    }
  }

  if (loading) {
    return <MainLoading />;
  }

  return (
    <Fragment>
      <div ref={homePageContainerRef}>
        {
          homeData?.slice(0, homeDataSliceCount).map((item, index) => {
            return <ContentSections key={`ContentSections${index}`} fontClass={fontClass} data={item} />
          })
        }
        <div style={{paddingBottom:"6rem"}}></div>
      </div>
    </Fragment>
  );
});

HomePage.displayName = "HomePage";
export default HomePage;
