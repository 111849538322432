import React from "react";
import { Epg, Layout } from "planby";
import { useSelector } from "react-redux";
// Import hooks
import { useApp } from "./useApp";

// Import components
import { Timeline, ChannelItem, ProgramItem } from "./components";

function LiveEpgContainer() {
  const { isLoading, getEpgProps, getLayoutProps, channelLength } = useApp();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const pubid = projectInfo?.projectConfig?.pubid;
  return (
    <div className="liveEpgContainer">
      <div className={pubid?.toString() === "50120"  ?"wrapper":""}>
      <div style={{ height: channelLength <= 9 ? "100vh" :"190vh", width: "100%" }}>
        <Epg isLoading={isLoading} {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}
            renderTimeline={(props) => <Timeline {...props} />}
            renderProgram={({ program, ...rest }) => (
              <ProgramItem key={program.data.id} program={program} {...rest} />
            )}
            renderChannel={({ channel }) =>
            (
              <ChannelItem  channel={channel} />
            )
          }
          />
        </Epg>
      </div>
      </div>
    </div>
  );
}

export default LiveEpgContainer;
