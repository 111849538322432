import React from "react";
import { useState } from "react";
import { Country, State } from "country-state-city";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { updateContactSuppport } from "./service";
import { useSelector } from "react-redux";
import Translate from "../../Components/MultiLanguage/Translate";

const ContactSupport = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);

  const urlParams = new URLSearchParams(window.location.search);
  const advertise = urlParams.get("adv");

  const [values, setValues] = useState({});
  const [states, setstates] = useState();
  const [country, setCountry] = useState();
  const [disableblock, setDisableblock] = useState(false);
  const [errors, setErrors] = useState({});

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    let country = Country.getAllCountries();
    setCountry(country);
  }, []);

  useEffect(() => {
    advertise && setValues({
      ...values,
      ["issues"]: "Advertise with us",
    });
  }, []);

  const formValidation = () => {
    const { country, email, issues, name, state, message, platform, topic } =
      values;
    let validationStatus = true;
    let error = {};
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!country || country === "Select Country") {
      error.country = <Translate textKey={'required'} />;
      validationStatus = false;
    }

    if (!email) {
      error.email = <Translate textKey={'required'} />;
      validationStatus = false;
    } else if (!email?.match(emailRegex)) {
      error.email = <Translate textKey={'valid_email_message'} />;
      validationStatus = false;
    }

    if (!issues) {
      error.issues = <Translate textKey={'required'} />;
      validationStatus = false;
    } else if (issues === "Technical Issue") {
      if (!platform || platform === "Select Platform") {
        error.platform = <Translate textKey={'required'} />;
        validationStatus = false;
      }
      if (!topic || topic === "Select Topic") {
        error.topic = <Translate textKey={'required'} />;
        validationStatus = false;
      }
    }

    if (!name) {
      error.name = <Translate textKey={'required'} />;
      validationStatus = false;
    }

    if (!state || state === "Select State") {
      error.state = <Translate textKey={'required'} />;
      validationStatus = false;
    }

    if (!message) {
      error.message = <Translate textKey={'required'} />;
      validationStatus = false;
    } else if (message?.length < 5) {
      error.message = <Translate textKey={'minimum_character_required'} />;
      validationStatus = false;
    }
    setErrors(error)
    return validationStatus;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      let state = State.getStatesOfCountry(value);
      setstates(state);
      setValues({
        ...values,
        [name]: value,
      });
    } else if (name === "issues") {
      setValues({
        ...values,
        [name]: value,
      });
      if (value === "Technical Issue") {
        setDisableblock(true);
      } else {
        setDisableblock(false);
      }
    } else {

      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault()
    if (formValidation()) {
      // let country = Country.getAllCountries();
      // const countryArray = country.filter(
      //   (countryItem) => countryItem.isoCode == values?.country
      // );
      // values.country = countryArray[0]?.name;
      updateContact();
    }
  }

  const updateContact = async () => {
    try {
      const response = await updateContactSuppport(appInfo, values);
      if (response?.status === 200) {
        toast.success(<Translate textKey={'thanks_message'} />, {
          position: "bottom-center",
        });
        // setValues(null)
        // setDisableblock(false)
      }
    } catch (err) {
      toast.error(err?.message, {
        position: "bottom-center",
      });
    }

  }

  return (
    <div className="contactSupport">
      <ToastContainer />
      <div className="wrapper">
        <h1 className="heading"><Translate textKey={'support'} /></h1>
        <div className="formContainer">
          <form>
            <div className="full">
              <input
                type="text"
                name="name"
                placeholder='Name'
                onChange={handleChange}
                value={values?.name || ""}
              />
              {
                errors?.name &&
                <span className="error">{errors?.name}</span>
              }
            </div>
            <div className="full">
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                value={values?.email || ""}

              />
              {
                errors?.email &&
                <span className="error">{errors?.email}</span>
              }
            </div>
            <div className="full">
              <select name="country" id="country" onChange={handleChange}>
                <option><Translate textKey={'select_country'} /></option>
                {country?.map((item, index) => {
                  return (
                    <option key={index} value={item.isoCode}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {
                errors?.country &&
                <span className="error">{errors?.country}</span>
              }
            </div>
            <div className="full">
              <select name="state" id="state" onChange={handleChange}>
                <option><Translate textKey={'select_state'} /></option>
                {states?.map((item, index) => {
                  return (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {
                errors?.state &&
                <span className="error">{errors?.state}</span>
              }
            </div>
            <div className="full">
              <input
                type="text"
                name="movie"
                placeholder="Movie/show title (optional)"
                onChange={handleChange}
                value={values?.movie || ""}

              />
              {
                errors?.movie &&
                <span className="error">{errors?.movie}</span>
              }
            </div>
            <div className="full">
              {advertise ? (
                <input type="text" value="Advertise with us" disabled />
              ) : (
                <select name="issues" id="issues" onChange={handleChange}>
                  <option>Select One</option>
                  <option value="Technical Issue">Technical Issue</option>
                  <option value="Advertise with us" >Advertise with us</option>
                  <option value="Partner with us">Partner with us</option>
                  <option value="Other">Other</option>
                </select>
              )}
              {
                errors?.issues &&
                <span className="error">{errors?.issues}</span>
              }
            </div>
            {disableblock === true && (
              <>
                <div className="full">
                  <select name="platform" id="platform" onChange={handleChange}>
                    <option><Translate textKey={'select_platform'} /></option>
                    <option value="Website">Website</option>
                    <option value="Roku">Roku</option>
                    <option value="Android">Android</option>
                    <option value="Ios">iOS</option>
                    <option value="Apple TV">Apple TV</option>
                    <option value="Android TV">Android TV</option>
                    <option value="Amazon Fire">Amazon Fire</option>
                  </select>
                  {
                    errors?.platform &&
                    <span className="error">{errors?.platform}</span>
                  }
                </div>
                <div className="full">
                  <select name="topic" id="topic" onChange={handleChange}>
                    <option value="Select Topic"><Translate textKey={'select_topic'} /></option>
                    <option value="Account"><Translate textKey={'account'} /></option>
                    <option value="Audio"><Translate textKey={'audio'} /></option>
                    <option value="Content"><Translate textKey={'Content'} /></option>
                    <option value="Content Request"><Translate textKey={'content_request'} /></option>
                    <option value="Registration"><Translate textKey={'registration'} /></option>
                    <option value="Sign-In"><Translate textKey={'sign_in'} /></option>
                    <option value="Subtitle"><Translate textKey={'subtitle_caption'} /></option>
                    <option value="Video Playlist"><Translate textKey={'Video Playlist'} /></option>
                    <option value="Other"><Translate textKey={'other'} /></option>
                  </select>
                  {
                    errors?.topic &&
                    <span className="error">{errors?.topic}</span>
                  }
                </div>
              </>
            )}

            <div className="full message">
              <label htmlFor="message"><Translate textKey={'message'} /></label>
              <textarea name="message" onChange={handleChange} value={values?.message || ""}></textarea>
              {
                errors?.message &&
                <span className="error">{errors?.message}</span>
              }
            </div>
            <div className="full">
              <div className="buttonContainer">
                <button onClick={(e) => submitHandler(e)}><Translate textKey={'submit'} /></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSupport;
