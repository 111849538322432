import React, { useEffect, useState } from 'react';
import SubscriptionPlanDetails from '../../Components/SubscriptionPlanDetails/SubscriptionPlanDetails';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '../../Components/Loading/Loading';

function CeyFlixSubcriptionPage() {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const [loading, setLoading] = useState(true);
    const navigate= useNavigate()

    useEffect(()=>{
        setLoading(true)
        window.scrollTo(0, 0);
        if(projectInfo?.projectConfig?.pubid !== 50014){
            return(
               navigate('/404') 
            )
        }
        setLoading(false)
    },[])

    if(loading){
        return(
          <Loading/>
        )
      }

    
    return (
        <div className='ceyFlixSubscriptionPage'>
            <SubscriptionPlanDetails />
            <div className='wrapper'>
                <div className='subscriptionFeatures'>
                    {/* <h3>Terms and conditions</h3> */}
                    <div className="subscriptionConditionList">
                        <ul className='conditionList'>
                            <li>Subscribe to get unlimited access to the stream from thousands of hours of premium films, dramas, and series. No Lock-IN Contract and can Cancel anytime.</li>
                            <li>Payment will be charged through your stripe or PayPal account on purchase confirmation.</li>
                            <li>Subscription will auto-renew every month/year based on the package you have purchased unless you cancel at least 24 hours before the end of the current subscription period.</li>
                            <li>You can manage your subscription package and payments by going to your Credit or Debit card and stripe/PayPal or Dialog Mobile account settings after purchase.</li>
                            <li>Any unused portion of a cancelled subscription is not subject to refund.</li>
                            <li>You cannot share your login details with anyone else.</li>
                            <li>RENT(Pay-Per-View) - This will enable a viewer to pay to watch an individual event, movie or episode privately after downloading the programs and be able to watch for one week. The rental prices are subject to adjustment based on the prevailing program.</li>
                            <li>CEYFLIX LIVE TV - Can be watched free by subscribing the channel via website.</li>
                            <li style={{ color: "red" }}>WARNING All dramas, movies and videos streamed or broadcast by CeyFLiX are protected by international copyright laws. Reproduction and distribution without the written permission of CeyFLiX is prohibited. </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CeyFlixSubcriptionPage