import { fetchApiData, fetchApiDataV2 } from "../../utils/utils";

export const getShows = async (appInfo, key) => {
  const api = "show/search";
  const params = {
    key,
  };
  // if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
    return await fetchApiDataV2(appInfo,api,params)
// }else{
//     return await fetchApiData(appInfo,api,params)
// }
};
