import React,{useEffect,useState} from 'react'
import { useNavigate,useSearchParams } from "react-router-dom";
import Loading from '../../Components/Loading/Loading';
import { subscriptionCheck } from './service';
import { useSelector } from 'react-redux';
function PaymentProcessing() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const transaction_id = searchParams.get('transaction_id');
    const token = searchParams.get('token');
    const [loading, setLoading] = useState(true);
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const appInfo = {
      projectDetails: projectInfo,
      accessToken: accessToken,
    };
    useEffect(() => {
      let pollingInterval;
      let stopPolling = false;
      const pollPaymentStatus = async () => {
        if (stopPolling) {
          clearInterval(pollingInterval); 
          return;
        }
        try {
          const response = await subscriptionCheck(appInfo,transaction_id, token);         
          if (response.data.data === "SUCCESS") {
            setLoading(false)
            navigate(`/success?cinetPay=true`)        
            stopPolling = true;
          } else if(response.data.data === "FAILED"){
            setLoading(false);
            navigate(`/failed?cinetPay=false`);
            stopPolling = true;
          }else{
            stopPolling = false;
            setLoading(true)
          }
        } catch (error) {
          console.error("Error calling processing API:", error);
        }
      };
      pollingInterval  = setInterval(pollPaymentStatus, 5000);  
      return () => {
        clearInterval(pollingInterval ); 
      };
    }, []);
  return (
    <div className="paymentProcessingScreen">
    <div className="wrapper">
      <div className="paymentContainer">
   {   loading &&
<Loading/>}
      </div>
    </div>
    </div>
  )
}

export default PaymentProcessing
