import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDeviceSubscription } from '../../../Redux/DeviceSubscriptionData/deviceSubscriptionSlice';
import { updatePayment } from '../PaymentSuccess/service';
import Translate from '../../../Components/MultiLanguage/Translate';

const PaymentFailed = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const navigate=useNavigate()
  const [isAndroid,setIsAndroid] = useState(false)
  const dispatch = useDispatch()
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(()=>{
    const android = localStorage.getItem("isAndroid");
    const cinetPay = urlParams.get("cinetPay")
    setIsAndroid(android)
    if(cinetPay){
      console.log("No API calls");
    }else{
      updatePaymentApi()
    }

  },[])

  const updatePaymentApi = async () => {
    const updatePaymentResponse = await updatePayment(appInfo,null,null,"error");
    
  }

  const handleClick = () => {
    if (isAndroid == "true") {
      window.location.href = window.location.origin + "/webviewfailed"; // android error redirect
    }
    localStorage.removeItem("isAndroid")
    localStorage.removeItem("selectedAmount")
    localStorage.removeItem("selectedSubId")
    localStorage.removeItem("couponId")
    localStorage.removeItem("deviceType")
    localStorage.removeItem("previousSubId")
    localStorage.removeItem("showId")
    localStorage.removeItem("vanityUrl")
    const antkn = {
      androidToken: null,
      isAndroid: false,
    }
    dispatch(getDeviceSubscription({
      deviceSubscription: antkn,
    }))
    navigate("/")

  }
 
  return (
    <div className='failed'>
        <div className="failedContainer">

        <div className="crossIcon">
            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#fff" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>
        </div>
        <h5><Translate textKey={'error_message'} /></h5>
        <p><Translate textKey={'payment_failed_message'} /><br/><Translate textKey={'try_again_message'} /></p>
        <button onClick={()=>handleClick()}><Translate textKey={'ok'} /></button>
        </div>
    </div>
  )
}

export default PaymentFailed
