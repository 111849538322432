import React, { memo, Fragment, useState, useEffect } from "react";

// react-router-dom
import { Link } from "react-router-dom";
import imgLogo from "../../assets/Images/imdb-logo.svg"
// components
import CustomButton from "../CustomButton";
import Translate from "../MultiLanguage/Translate"

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Thumbs } from "swiper";
import { useSelector } from "react-redux";
import { imageUrlCheck, convertMinutesToHoursAndMinutes, translateText } from "../../utils/utils";
import { Pagination } from "react-bootstrap";


// Redux Selector / Action
// import { theme_scheme_direction } from "../../store/setting/selectors";

const OttHeroSlider = memo(({ data, fontClass }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  // const themeSchemeDirection = useSelector(theme_scheme_direction);
  const themeSchemeDirection = '';
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [render, setRender] = useState(true)
  // useEffect(() => {
  //   setThumbsSwiper(null)
  //   setRender(false)
  //   setTimeout(() => {
  //     setRender(true)
  //   }, 100);
  //   return () => {};
  // }, [themeSchemeDirection]);

  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }

  return (
    <Fragment>
      <div className="iq-banner-thumb-slider">
        <div className="slider">
          <div className="position-relative slider-bg d-flex justify-content-end">
            <div className="position-relative my-auto">
              <div
                className="horizontal_thumb_slider"
                data-swiper="slider-thumbs-ott"
              >
                <div className="banner-thumb-slider-nav">
                  <Swiper
                    key={themeSchemeDirection}
                    dir={themeSchemeDirection}
                    tag="ul"
                    thumbs={{
                      swiper:
                        thumbsSwiper && !thumbsSwiper.destroyed
                          ? thumbsSwiper
                          : null,
                    }}
                    direction="horizontal"
                    navigation={{
                      prevEl: ".slider-prev",
                      nextEl: ".slider-next",
                    }}
                    spaceBetween={24}
                    loop={true}
                    autoplay={{
                      delay: 4000,
                      disableOnInteraction: false,
                    }}
                    slidesPerView={2}
                    breakpoints={{
                      0: {
                        direction: "horizontal",
                        slidesPerView: 1,
                      },
                      768: {
                        direction: "horizontal",
                        slidesPerView: 2,
                      },
                    }}
                    watchSlidesProgress={true}
                    modules={[Navigation, Thumbs, Autoplay]}
                    className="swiper-horizontal swiper-container mb-0"
                  >
                    {data?.shows?.map((data, index) => (
                      <SwiperSlide className="swiper-bg" key={index}>
                        <Link
                          to={`/show-details/${data?.vanity_url}`}
                        >
                          <div className="block-images position-relative">
                            <div className="img-box">
                              {
                                imageUrlCheck(data?.banner_portrait) === true ? (
                                  <img className="img-fluid" src={data?.banner_portrait} onError={(e) => replaceImage(e)} alt="Banner1" />


                                ) : (
                                  <img className="img-fluid" src={`${process.env.REACT_APP_IMAGE_URL}${data?.banner_portrait}`} onError={(e) => replaceImage(e)} alt="Banner1" />

                                )
                              }
                              <div className="block-description ps-3">
                                <h6 className="iq-title fw-500 mb-0">{data?.show_name}</h6>
                                <span className="fs-12">{data?.duration_text}</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                    ))}

                  </Swiper>
                  <div className="slider-prev swiper-button">
                    <i className="fa fa-chevron-left" style={{ height: "18px" }}></i>
                  </div>
                  <div className="slider-next swiper-button">
                    <i className="fa fa-chevron-right" style={{ height: "18px" }}></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="slider-images" data-swiper="slider-images-ott">
              <Swiper
                key={themeSchemeDirection}
                dir={themeSchemeDirection}
                tag="ul"
                onSwiper={setThumbsSwiper}
                slidesPerView={1}
                modules={[Navigation, Thumbs, Autoplay]}
                watchSlidesProgress={true}
                allowTouchMove={true}
                className="swiper-container"
              >

                {data?.shows?.map((data, index) => (
                  <SwiperSlide className="p-0">
                    <div className="slider--image block-images">
                      {
                        imageUrlCheck(data?.banner) === true ? (
                          <img loading="lazy" className="img-fluid" src={data?.banner} onError={(e) => replaceImage(e)} alt="Banner" />


                        ) : (
                          <img loading="lazy" className="img-fluid" src={`${process.env.REACT_APP_IMAGE_URL}${data?.banner}`} onError={(e) => replaceImage(e)} alt="Banner" />

                        )
                      }
                    </div>
                    <div className="description">
                      <div className="row align-items-center h-100">
                        <div className="col-lg-6 col-md-12">
                          <div className="slider-content">
                            <div className="d-flex align-items-center RightAnimate mb-3">
                              <span className="badge rounded-0 text-dark text-uppercase px-3 py-2 me-3 bg-white mr-3">
                                {data?.rating}
                              </span>
                              <ul className="p-0 mb-0 list-inline d-flex flex-wrap align-items-center movie-tag">

                                {data?.categories?.length > 0 &&
                                  data?.categories?.map((category, index) => {
                                    return <li key={index} className="position-relative text-capitalize font-size-14 letter-spacing-1">
                                      <Link
                                        to={category?.key ? `/category/${category.key}?career=${category.key}` : null}
                                        className="text-decoration-none"
                                      >
                                        {category.category_name}
                                      </Link>
                                    </li>
                                  })
                                }
                              </ul>
                            </div>
                            <h1 className="texture-text big-font letter-spacing-1 line-count-1 text-capitalize RightAnimate-two">
                              {data?.show_name}
                            </h1>
                            <p className="line-count-3 RightAnimate-two">
                              {data?.synopsis}
                            </p>
                            <div className="RightAnimate-three">
                              <div className="d-flex flex-wrap align-items-center gap-3 ">
                                {data?.imdb_rating && <div className="slider-ratting d-flex align-items-center">
                                  <ul className="ratting-start p-0 m-0 list-inline text-warning d-flex align-items-center justify-content-left">
                                    <li>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </li>
                                  </ul>
                                  <span className="text-white ms-2 font-size-14 fw-500">
                                    {data?.imdb_rating}
                                  </span>
                                  <span className="ms-2">
                                    <img
                                      src={imgLogo}
                                      alt="imdb logo"
                                      className="img-fluid"
                                    />

                                  </span>
                                </div>}
                                <span className="font-size-14 fw-500">
                                  {data?.duration_text}
                                </span>
                                {data?.categories && data?.categories[0]?.category_name && <div className="text-primary font-size-14 fw-500 text-capitalize">
                                  <Translate textKey={'genres'} />:{" "}
                                  <Link
                                    className="text-decoration-none ms-1"
                                  >
                                    {data?.categories[0]?.category_name}
                                  </Link>
                                </div>}
                                {data?.director && <div className="text-primary font-size-14 fw-500 text-capitalize">
                                  <Translate textKey={'director'} />:{" "}
                                  <span
                                    className="text-decoration-none ms-1"
                                  >
                                    {data?.director}
                                  </span>
                                </div>}

                              </div>
                              {data?.show_cast && <div className="text-primary font-size-14 fw-500 text-capitalize">
                                <Translate textKey={'cast'} />{" "}
                                <span
                                  className="text-decoration-none ms-1"
                                >
                                  {data?.show_cast}
                                </span>
                              </div>}
                            </div>
                          </div>
                          {/* <CustomButton
                            buttonTitle="play now"
                            link="/movies-detail"
                            linkButton="false"
                          /> */}
                          <div className="iq-button ">
                            <Link
                              to={`/show-details/${data?.vanity_url}`}
                              className="btn text-uppercase position-relative px-4 py-3"
                            >
                              <span className="button-text"><Translate textKey={'play_now'} /></span>
                              <i className="fa-solid fa-play"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>))}

              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

OttHeroSlider.displayName = OttHeroSlider;
export default OttHeroSlider;
