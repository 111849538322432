import React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as LoadingRing } from "../../assets/Icons/loadingRing.svg";

const Loading = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const buttonColor = projectInfo?.projectConfig?.config?.BUTTON_COLOR || '#000';
  return (
    <div className="loadingContainer">
      <div className="loadingContent">
        <div className="loadingOverlay"></div>
        <div className="loading-container">
          { projectInfo?.projectConfig?.config?.CUSTOM_LOADER? <img 
            src={projectInfo?.projectConfig?.config?.CUSTOM_LOADER} 
            alt="Loading..." 
          /> : <span class="loader" style={{ '--button-color': buttonColor }}></span>}
        </div>
      </div>
    </div>
  );
};

export default Loading;
