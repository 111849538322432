import React, { memo, Fragment, useState, useEffect } from "react";

//components
import SectionSlider from "../slider/SectionSlider";
import TopTenCard from "../../Components/cards/TopTenCard";
import { useSelector } from "react-redux";

//function

const TopTenMoviesToWatch = memo(({ data }) => {
  const [title, setTitle] = useState("");
  const [shows, setShows] = useState([]);
  let count = 0;
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  useEffect(() => {
    setShows(data?.shows)
    setTitle(data?.category_name)
   
  }, [data]);
  

  return (
    <Fragment>
      {projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT" ? <SectionSlider title={title} list={shows} className="top-ten-block" data={data}>
        {(shows) => {
          count += 1;
          return (
            <TopTenCard
              imagePath={shows.logo}
              countValue={count}
              link={`/show-details/${shows?.vanity_url}`}
            />
          )
        }}
      </SectionSlider>
      :
      <SectionSlider title={title} list={shows} className="top-ten-block" data={data} slidesPerView={5} styleElement={false} landscapeElement={true}>
        {(shows) => {
          count += 1;
          return (
            <TopTenCard
              imagePath={shows.logo_thumb}
              countValue={count}
              link={`/show-details/${shows?.vanity_url}`}
            />
          )
        }}
      </SectionSlider>
      }
    </Fragment>
  );
});

TopTenMoviesToWatch.displayName = "TopTenMoviesToWatch";
export default TopTenMoviesToWatch;
