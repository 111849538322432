import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as LiveIcon } from "../../assets/Icons/live.svg";
import { ReactComponent as InfoIcon } from "../../assets/Icons/info.svg";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import ScheduleDetailsModal from "../../Components/Modals/ScheduleDetailsModal";
import LiveEpgContainer from "./LiveEpgContainer";
import { useDispatch, useSelector } from "react-redux";
import { getVideoSubscription } from "../Subscription/service"
import { fetchLiveChannels } from "./service";
import { getLiveScheduleData } from "../../Redux/LiveScheduleData/liveScheduleDataSlice";
import { convertUTCToLocal, getCurrentTime, getCurrentTimeAndDate, getDiffInMinutes, imageUrlCheck, onVideoPlayFunction, timeConversion } from "../../utils/utils";
import { getUserSubscription } from "../../network/service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import ContinueWithSubscriptionModal from "../../Components/Modals/ContinueWithSubscriptionModal";
import parse from "html-react-parser";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { getSelectedSchedule } from "../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";
import NoScheduleAvailableModal from "../../Components/Modals/NoScheduleAvailableModal";
import { fetchFastChannels } from "./service";
import LivePlayerCenter from "./LivePlayerCenter";

let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let initialLoad = true;
const LiveChannels = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const scheduleModal = useSelector((state) => state?.scheduleModal?.value);
  const liveScheduleData = useSelector((state) => state?.liveSchedule?.value);
  const selectedSchedule = useSelector((state) => state?.selectedSchedule?.value);
  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const pubid = projectInfo?.projectConfig?.pubid;
  
  const [sliceCount, setSliceCount] = useState(550);
  const [channelData, setChannelData] = useState();
  const [livePlayer, setLivePlayer] = useState();
  const [isReadMore, setIsReadMore] = useState(true);
  const [timeLeft, setTimeLeft] = useState()
  const [liveUrl, setLiveUrl] = useState("")
  const [loading, setLoading] = useState(true)
  const [isContinueWithSubscriptionModal, setIsContinueWithSubscriptionModal] = useState(false);
  const [fastchannelData,setFastChannelData] = useState();
  const [isNoScheduleAvailableModal , setIsNoScheduleAvailableModal] = useState(false);

  const playerRef = useRef(null);
  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,
  };

  // useEffect(() => {
  //   if(!selectedSchedule?.channel_id){
  //     if (liveScheduleData?.channels) {
  //       setChannelData(liveScheduleData?.channels[0]);
  //     }
  //   }
  // }, [liveScheduleData]);
  useEffect(() => {
    setChannelData(null)
    setFastChannelData(null)
    setLivePlayer(null)
    setLiveUrl(null)
    if (liveScheduleData) {
      if ( Object.entries(liveScheduleData).length > 0 && liveScheduleData.channels?.length > 0 && liveScheduleData?.schedules?.length > 0 ) {
        setLoading(false)
        setIsNoScheduleAvailableModal(false);
        if (selectedSchedule?.channel_id) {
          const filteredChannel = liveScheduleData?.channels?.filter((item) => selectedSchedule?.channel_id === item?.id)
          const channelInfo = filteredChannel[0]
          if (filteredChannel) {
            setChannelData({  ...channelInfo,...selectedSchedule });
          }
        } else {
          dispatch(
            getSelectedSchedule({
              selectedSchedule: { channel_id: liveScheduleData?.schedules[0]?.channel_id }
            })
          )
        }
      } 
      
      else
      {
        if ( liveScheduleData.channels?.length === 0 && liveScheduleData.schedules?.length === 0 ) {
          setIsNoScheduleAvailableModal(true); 
        } else {
          setIsNoScheduleAvailableModal(false);
        }
      }
    }
    return () => localStorage.setItem("initialScheduleAdded", "false") //for showing first item from the schedule on nowplaying section
  }, [selectedSchedule, liveScheduleData]);

  useEffect(() => {
    if (channelData?.live_link) {

      checkSubscription()
      setTimeLeft(null)
      let diff = timeDifferenceHandler()
      if (diff > 60) {
        setTimeLeft(timeConversion(diff))
      } else if (diff > 0) {
        let leftTime = diff + "m"
        setTimeLeft(leftTime)
      }
    }
  }, [channelData])
  useEffect(() => {
    if (fastchannelData?.now_playing?.start_time) {
      const interval = setInterval(() => {
        let diff = timeDifferenceHandler()
        if (diff > 60) {
          setTimeLeft(timeConversion(diff))
        } else if (diff > 0) {
          let leftTime = diff + "m"
          setTimeLeft(leftTime)
        }
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [fastchannelData, timeLeft])

  const timeDifferenceHandler = () => {
    let time = getCurrentTimeAndDate()
    let currentTime = moment(time)
    let convertedEndTime = moment(fastchannelData?.now_playing?.end_time)
    let differenceInMinutes = convertedEndTime.diff(currentTime, "minutes")
    return differenceInMinutes
  }



  useEffect(() => {
    if (liveUrl) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${liveUrl}`,
            type: "application/x-mpegURL",
          },
        ],
      };
      setLivePlayer(
        <VideoJS options={videoJsOptions} liveData={fastchannelData} onReady={handlePlayerReady} />
      );
    }
  }, [liveUrl]);

  useEffect(() => {
    window.scroll(0, 0)
    fetchUserSubscriptionDetails();
  }, []);

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };
 

  const checkSubscription = async () => {
    if(!initialLoad)
    {
      setIsContinueWithSubscriptionModal();

      const channelId = channelData?.channel_id;
      const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
      };
  
      const videoSubRespone = await getVideoSubscription(appInfo, channelId, 'channel');
      const isSubscribedUser = matchSubscription(userSubscriptionData?.data, videoSubRespone?.data?.data)
      const fastChannelResponse = await fetchFastChannels(appInfo,channelId);
      let fastchannelInfo;
      if (fastChannelResponse?.status === 200) {
         fastchannelInfo = fastChannelResponse?.data?.data[0]
           if (fastchannelInfo ) {
                setFastChannelData({ ...fastchannelInfo });
              }    
      }
      if (videoSubRespone?.data?.data.length == 0) {
        setLiveUrl(fastchannelInfo?.live_link);
      } else {
        if (isSubscribedUser === true) {
          setLiveUrl(fastchannelInfo?.live_link);
        } else {
          setIsContinueWithSubscriptionModal(true);
        }
      }
    }
    else{
      initialLoad = false;
    }
   


  }

  const matchSubscription = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) => videoSub.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
  };


  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      updateLiveAnalytics(
        channelData,
        event,
        player.currentTime()
      );
    });

    player.on("timeupdate", function (e) {
      let event = "POP03";

      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        updateLiveAnalytics(
          channelData,
          event,
          player.currentTime()
        );
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      updateLiveAnalytics(
        channelData,
        event,
        player.currentTime()
      );
    });
    player.on("ended", () => {
      let event = "POP05";
      updateLiveAnalytics(
        channelData,
        event,
        player.currentTime()
      );
    });


    player.on("dispose", () => {
      videoStarted = false;
      // player.pause();
    });
  };

  const updateLiveAnalytics = async (nowPlaying, event, time) => {
    const isLive = "1";
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      nowPlaying,
      event,
      time,
      isLive
    );
  };

  const readMoreOrLessHandler = (type) => {
    if (type === "more") {
      if (channelData?.video_description) {
        setSliceCount(channelData?.video_description?.length);
        setIsReadMore(false);
      }
    } else {
      setSliceCount(550);
      setIsReadMore(true);
    }
  };
  return (
    <div className={ pubid?.toString() === "50120" ? "livePlayerContainer" : "liveChannelsContainer"}>
          {scheduleModal?.isOpen && <ScheduleDetailsModal />}

          {isContinueWithSubscriptionModal && (<ContinueWithSubscriptionModal
              setIsContinueWithSubscriptionModal={setIsContinueWithSubscriptionModal}
              channelData={channelData}
              appInfo={appInfo}
            />
          )}
          
          {isNoScheduleAvailableModal && <NoScheduleAvailableModal />}
          
          {
          !loading && 
            pubid?.toString() === "50120" ? (
              <LivePlayerCenter
                loading={loading}
                channelData={channelData}
                fastchannelData={fastchannelData}
                timeLeft={timeLeft}
                livePlayer={livePlayer}
                projectInfo={projectInfo}
              />
            ) : (
            <div className="topContainer">
              <div
                className="bgImage"
                style={
                  channelData?.image
                    ? imageUrlCheck(channelData?.image)
                      ? { backgroundImage: `url(${channelData?.image})` }
                      : { backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL + channelData?.image})` }
                    : { backgroundImage: `url(${projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE})` }
                }
              >
              </div>
              <div className="wrapper">
                <div className="bannerContainer">
          
                  <div className="info">
                    <div className="channelLogo">
                    { fastchannelData?.logo ? (
                  imageUrlCheck(fastchannelData?.logo) === true ? (
                    <img src={fastchannelData?.logo} alt="Logo" />
                  ) : (
                    <img
                        src={`${process.env.REACT_APP_IMAGE_URL}${fastchannelData?.logo}`}
                        alt="Logo"
                      />
                    )
                  ) : null}
                </div>
                <div className="liveLabel">
                  <LiveIcon />
                  <span>LIVE</span>
                </div>

                    <h1 className="title">{fastchannelData?.now_playing?.video_title}</h1>
                    <div className="additionalInfo">
                      {timeLeft && <h6 className="timeLeft">{timeLeft} left</h6>}
                      {channelData?.rating && <span className="rating">{channelData?.rating}</span>}
                    </div>
                    <p className="description">
                      {fastchannelData?.now_playing?.video_description && (
                        <>
                          {parse(
                            fastchannelData?.now_playing?.video_description
                              ?.slice(0, sliceCount)
                              ?.split("\n")
                              ?.join("<br>")
                          )}
                          {fastchannelData?.now_playing?.video_description?.length > sliceCount && (
                            <>
                              {isReadMore ? (
                                <>
                                  <span>...</span>
                                  <span className="readMore" onClick={() => readMoreOrLessHandler("more")}>
                                    Read More
                                  </span>
                                </>
                              ) : null}
                            </>
                          )}
                          {!isReadMore && (
                            <span className="readLess" onClick={() => readMoreOrLessHandler("less")}>
                              Read Less
                            </span>
                          )}
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <div className="videoContainer">{livePlayer}</div>
              </div>
              <div className="gradient"></div>
            </div>
          )
          }

          <LiveEpgContainer />
    </div>
  );
};

export default LiveChannels;
