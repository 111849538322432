import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as service from "./service";
import Translate from "../../../Components/MultiLanguage/Translate";

const ResetPassword = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const accessToken = useSelector((state) => state.accessToken?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const { token } = useParams();
  const appInfo = {
    accessToken: accessToken,
    projectDetails: projectInfo,
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };
  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const validatePassword = () => {
    let error = {};
    let validateStatus = true;
    const { password, confirmPassword } = values;
    let passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    if (!password) {
      error.password = <Translate textKey={'password_required'} />;
      validateStatus = false;
    }
    // else if (!password?.match(passwordRegex)) {
    //   error.password =
    //     <Translate textKey={'password_validation_message'} />;
    //   validateStatus = false;
    // }
    if (password != confirmPassword) {
      error.confirmPassword = <Translate textKey={'password_not_matching_message'} />;
      validateStatus = false;
    }
    setErrors(error);
    return validateStatus;
  };

  const data = {
    password: values?.password,
    token: token,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const validateStatus = validatePassword();
      if (validateStatus) {
        const response = await service.resetPassword(appInfo, data);
        if (response?.status === 200) {
          toast.success(response?.data?.message, {
            position: "top-center",
          });
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          let error = {};
          error.message = response?.data?.message;
          setErrors(error);
        }
      } else {
        event.preventDefault();
        return false;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
    }
    // const validateStatus = await validatePassword();
    // if (validateStatus) {
    //   service.resetPassword(data, appInfo).then((response) => {
    //     if (response?.success === true) {
    //       toast.success(response?.message, {
    //         position: "top-center",
    //       });
    //       setTimeout(() => {
    //        navigate('/login')
    //       }, 2000);
    //     } else {
    //       let error = {};
    //       error.message = response?.data?.message;
    //       setErrors(error);
    //     }
    //   });
    // } else {
    //   event.preventDefault();
    //   return false;
    // }
  };

  return (
    <div className="resetPassword">
      <ToastContainer />
      <div className="wrapper">
        <h1 className="heading"><Translate textKey={'reset_password'} /></h1>
        <div className="contents">
          <div className="formSection">
            <form>
              <div className="inputContainer">
                <label htmlFor="newpassword"><Translate textKey={'new_password'} /></label>
                <input
                  type="password"
                  id="newpassword"
                  name="password"
                  onChange={(e) => handleUpdate(e)}
                />

                {errors?.password && (
                  <span className="error">{errors.password}</span>
                )}
              </div>
              <div className="inputContainer">
                <label htmlFor="#confirmpassword"><Translate textKey={'confirm_password'} /></label>
                <input
                  type="password"
                  id="confirmpassword"
                  name="confirmPassword"
                  onChange={(e) => handleUpdate(e)}
                />

                {errors.confirmPassword && (
                  <span className="error">{errors.confirmPassword}</span>
                )}
              </div>

              <div
                className="buttonContainer"
                onClick={(event) => {
                  handleSubmit(event);
                }}
              >
                <div className="background"></div>
                <button type="submit"><Translate textKey={'reset_password'} /></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
