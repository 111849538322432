import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import { appInfo } from "../../network/service";
import { unsubscribe } from "../../Screens/Accounts/AccountSettings/service";
import Translate from "../MultiLanguage/Translate"

const CancelSubscriptionModal = ({ setCancelSubModal, subId, fetchAccountDetails }) => {

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [cancelSubError, setCancelSubError] = useState({ error: false, message: null })

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const cancelSubscriptionHandler = (status) => {
    if (status === "yes") {
      cancelSubscription()
    } else {
      setCancelSubModal(false)
    }
  }

  const cancelSubscription = async () => {
    try {

      const cancelSubscriptionResponse = await unsubscribe(appInfo, subId)
      if (cancelSubscriptionResponse?.status === 200) {
        toast.success("Subscription cancelled", {
          position: "bottom-center",
        });
        setCancelSubModal(false)
        fetchAccountDetails()
      } else if (cancelSubscriptionResponse?.status === 201) {
        setCancelSubError({ error: true, message: cancelSubscriptionResponse?.data?.message })
      }
    } catch (err) {
      toast.error(err.message, {
        position: "bottom-center",
      });

    }
  }
  return (
    <div className="cancelSub">

      <div className="overlayModal"></div>

      <div
        className="closeIconContainer"
        onClick={() => setCancelSubModal(false)}
      >
        <CloseIcon />
      </div>

      <div className="contents">
        {
          !cancelSubError.error ? (
            <h1><Translate textKey={'cancel_subscription_alert'} /></h1>
          ) : (
            <h1>{cancelSubError?.message}</h1>
          )
        }

        <div className="buttons">
          {
            !cancelSubError.error ? (
              <>
                <div className="buttonContainer" onClick={() => cancelSubscriptionHandler("yes")}>
                  <div className="background"></div>
                  <button><Translate textKey={'yes'} /></button>
                </div>
                <div className="buttonContainer" onClick={() => cancelSubscriptionHandler("no")}>
                  <div className="background"></div>
                  <button><Translate textKey={'no'} /></button>
                </div>
              </>
            ) : (
              <div className="buttonContainer" onClick={() => cancelSubscriptionHandler("no")}>
                <div className="background"></div>
                <button><Translate textKey={'ok'} /></button>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default CancelSubscriptionModal;
