import { fetchApiData , fetchApiDataV2} from "../../utils/utils"

export const getMenuItems=async (appInfo)=>{
  const api='menu/items'
  return await fetchApiData(appInfo,api)
}


export const getSearchData = async (appInfo, key) => {
  const api = "show/search";
  const params = {
    key,
  };
  return await fetchApiDataV2(appInfo, api, params);
};
