import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

const ProtectedRoutes = () => {
  const user = useSelector((state) => state?.user?.value);
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    if (!user) {
        const searchParams = new URLSearchParams(location.search);
        const queryParams = searchParams.toString();
        const pathWithParams = location.pathname + (queryParams ? `?${queryParams}` : '');
        navigate('/login', { state: {...location.state, path: pathWithParams } });
    }
}, [user, navigate, location]);
    if(user){
        return(
            <Outlet/>
        )
    }else{
        return <Navigate to="/login" state={location?.pathname==="/tv"?{tv:true,location:location}:{}} />
    }
 
}

export default ProtectedRoutes
