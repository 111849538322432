import { Fragment, memo, useState } from "react";

//react-bootstrap
import { Col, Row } from "react-bootstrap";

//react-router-dom
import { Link } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

// Redux Selector / Action
import { useSelector } from "react-redux";
// import { theme_scheme_direction } from "../../store/setting/selectors";

//react fs-lightbox
import FsLightbox from "fslightbox-react";
import Translate from "../MultiLanguage/Translate"


import { imageUrlCheck, convertMinutesToHoursAndMinutes, translateText } from "../../utils/utils";
import { getTrailer } from "./service";
import TrailerModal1 from "../Modals/TrailerModel1";


const TvShowHeroSlider = memo(({ data, fontClass }) => {
  // const themeSchemeDirection = useSelector(theme_scheme_direction);
  const themeSchemeDirection = '';
  const [toggler, setToggler] = useState(false);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [trailerLink,setTrailerLink] = useState()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }
  const trailerButtonHandle = async(teaser) => {
    const response = await getTrailer(appInfo, teaser )
    setTrailerLink(response.data.data.teaser)
    setToggler(!toggler)
  }
  return (
    <Fragment>
      <section className="banner-container ">
        <div className="movie-banner">
          <div id="banner-detail-slider" className="banner-container">
            <Swiper
            key={themeSchemeDirection}
            dir={themeSchemeDirection}
              navigation={{
                prevEl: ".swiper-banner-button-prev",
                nextEl: ".swiper-banner-button-next",
              }}
              slidesPerView={1.2}
              modules={[Navigation, Autoplay]}
              loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              centeredSlides={true}
              className="swiper-banner-container"
            >
              {data?.shows?.map((data, index) => (<SwiperSlide>
                <div className="movie-banner-image">
                {
                              imageUrlCheck(data?.banner || data?.logo) === true ? (
                                <img src={data?.banner} onError={(e) => replaceImage(e)} alt="Banner" />


                              ) : (
                                <img src={`${process.env.REACT_APP_IMAGE_URL}${data?.banner}`} onError={(e) => replaceImage(e)} alt="Banner" />

                              )
                            }
                </div>
                <div className="shows-content h-100">
                  <Row className="row align-items-center h-100">
                    <Col lg="7" md="12">
                      <h1
                        className="texture-text big-font letter-spacing-1 line-count-1 text-uppercase RightAnimate-two"
                        data-animation-in="fadeInLeft"
                        data-delay-in="0.6"
                      >
                        {data?.show_name}
                      </h1>
                      <div
                        className="flex-wrap align-items-center fadeInLeft animated"
                        data-animation-in="fadeInLeft"
                        style={{ opacity: 1 }}
                      >
                        {/* <div className="slider-ratting d-flex align-items-center gap-3">
                          <ul className="ratting-start p-0 m-0 list-inline text-primary d-flex align-items-center justify-content-left">
                            <li>
                              <i className="fas fa-star" aria-hidden="true"></i>
                            </li>
                            <li>
                              <i className="fas fa-star" aria-hidden="true"></i>
                            </li>
                            <li>
                              <i className="fas fa-star" aria-hidden="true"></i>
                            </li>
                            <li>
                              <i
                                className="fa fa-star-half"
                                aria-hidden="true"
                              ></i>
                            </li>
                          </ul>
                          <span className="text-white">3.5(lmdb)</span>
                        </div> */}
                        <div className="d-flex flex-wrap align-items-center gap-3 movie-banner-time">
                          {/* <span className="badge bg-secondary p-2">
                            <i className="fa fa-eye"></i>
                            PG
                          </span> */}
                          {/* <span className="font-size-6">
                            <i className="fa-solid fa-circle"></i>
                          </span> */}
                          <span className="trending-time font-normal">
                            {" "}
                            {data?.duration_text}
                          </span>
                          {/* <span className="font-size-6">
                            <i className="fa-solid fa-circle"></i>
                          </span>
                          <span className="trending-year font-normal">
                            {" "}
                            Feb 2018{" "}
                          </span> */}
                        </div>
                        <p
                          className="movie-banner-text line-count-3"
                          data-animation-in="fadeInUp"
                          data-delay-in="1.2"
                        >
                          {data?.synopsis}
                        </p>
                      </div>
                      <div className="iq-button ">
                            <Link
                              to={`/show-details/${data?.vanity_url}`}
                              className="btn text-uppercase position-relative px-4 py-3"
                            >
                              <span className="button-text"><Translate textKey={'play_now'} /></span>
                              <i className="fa-solid fa-play"></i>
                            </Link>
                          </div>
                    </Col>
                    {data?.teaser_vanity_url && <Col
                      lg="5"
                      md="12"
                      className="trailor-video iq-slider d-none d-lg-block"
                    >
                      <Link to="#" className="video-open playbtn" tabIndex="0">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="80px"
                          height="80px"
                          viewBox="0 0 213.7 213.7"
                          enableBackground="new 0 0 213.7 213.7"
                          xmlSpace="preserve"
                          onClick={() => trailerButtonHandle(data?.teaser_vanity_url)}
                        >
                          <polygon
                            className="triangle"
                            fill="none"
                            strokeWidth="7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            points="73.5,62.5 148.5,105.8 73.5,149.1 "
                          ></polygon>
                          <circle
                            className="circle"
                            fill="none"
                            strokeWidth="7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            cx="106.8"
                            cy="106.8"
                            r="103.3"
                          ></circle>
                        </svg>
                        <span
                          className="w-trailor"
                          onClick={() => trailerButtonHandle(data?.teaser_vanity_url)}
                        >
                          <Translate textKey={'watch_trailer'} />
                        </span>
                      </Link>
                    </Col>}
                  </Row>
                </div>
              </SwiperSlide>))}
              <div className="swiper-banner-button-next">
              <i className="fa fa-chevron-right" style={{marginRight:"30px"}}></i>
              </div>
              <div className="swiper-banner-button-prev">
              <i className="fa fa-chevron-left" style={{marginLeft:"30px"}}></i>
              </div>
            </Swiper>
          </div>
        </div>
      </section>

      {toggler && trailerLink!== null && <TrailerModal1 teaser={trailerLink} setTrailerModal={setToggler} appInfo={appInfo}/>}

    </Fragment>
  );
});

TvShowHeroSlider.displayName = TvShowHeroSlider;
export default TvShowHeroSlider;
