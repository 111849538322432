import { Fragment, memo, useState } from "react";

//react-router-dom
import { Link } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

//react fs-lightbox
import FsLightbox from "fslightbox-react";

// Redux Selector / Action
import { useSelector } from "react-redux";
import { imageUrlCheck, convertMinutesToHoursAndMinutes, translateText } from "../../utils/utils";
import TrailerModal1 from "../Modals/TrailerModel1";
import { getTrailer } from "./service";
import Translate from "../MultiLanguage/Translate";
// import { theme_scheme_direction } from "../../store/setting/selectors";

const HomeHeroSlider = memo(({ data, fontClass }) => {
  // const themeSchemeDirection = useSelector(theme_scheme_direction);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const themeSchemeDirection = '';
  const [toggler, setToggler] = useState(false);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [trailerLink,setTrailerLink] = useState()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }

  const trailerButtonHandle = async(teaser) => {
    const response = await getTrailer(appInfo, teaser )
    setTrailerLink(response.data.data.teaser)
    setToggler(!toggler)
  }

  return (
    <Fragment>
      <Swiper
            key={themeSchemeDirection}
            dir={themeSchemeDirection}
              navigation={{
                prevEl: ".swiper-banner-button-prev",
                nextEl: ".swiper-banner-button-next",
              }}
              slidesPerView={1}
              modules={[Navigation, Autoplay]}
              loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              id="home-banner-slider"
              className="iq-main-slider banner-home-swiper overflow-hidden mb-0"
            >
        {data?.shows?.map((data, index) => (<SwiperSlide>
          <div className="banner-home-swiper-image">
            {
              imageUrlCheck(data?.banner || data?.logo) === true ? (
                <img src={data?.banner} onError={(e) => replaceImage(e)} alt="Banner" />


              ) : (
                <img src={`${process.env.REACT_APP_IMAGE_URL}${data?.banner}`} onError={(e) => replaceImage(e)} alt="Banner" />

              )
            }
          </div>
          <div className="container-fluid position-relative h-100">
            <div className="slider-inner h-100">
              <div className="row align-items-center iq-ltr-direction h-100">
                <div className="col-lg-7 col-md-12">
                  <h1 className="texture-text big-font-5 letter-spacing-1 line-count-1 text-uppercase mb-0 RightAnimate">
                    {data?.show_name}
                  </h1>
                  <div className="d-flex flex-wrap align-items-center r-mb-23 RightAnimate-two">
                    {/* <div className="slider-ratting d-flex align-items-center">
                      <ul className="ratting-start p-0 m-0 list-inline text-warning d-flex align-items-center justify-content-left">
                        <li>
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </li>
                        <li>
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </li>
                        <li>
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </li>
                        <li>
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </li>
                        <li>
                          <i className="fa fa-star-half" aria-hidden="true"></i>
                        </li>
                      </ul>
                      <span className="text-white ms-2 font-size-14 fw-500">
                        4.3/5
                      </span>
                      <span className="ms-2">
                        <img
                          src="/assets/images/movies/imdb-logo.svg"
                          alt="imdb logo"
                          className="img-fluid"
                        />
                      </span>
                    </div> */}
                    {/* <span className="badge rounded-0 text-white text-uppercase p-2 mx-3 bg-secondary">
                    {data?.category_names[0]}
                    </span> */}
                    <span className="font-size-14 fw-500 time">
                    {data?.duration_text}
                    </span>
                  </div>
                  <p className="line-count-3 RightAnimate-two">{data?.synopsis}</p>
                  {data?.categories?.length > 0 && <div className="trending-list RightAnimate-three">
                    <div className="text-primary genres fw-500">
                    <Translate textKey={'genres'} />:
                      <Link
                        
                        className="fw-normal text-white text-decoration-none ms-2"
                      >
                        {" "}
                        {data?.categories[0].category_name}{" "}
                      </Link>
                    </div>
                    <div className="text-primary tag fw-500">
                    <Translate textKey={'tags'} />:
                      {data?.categories.map((category, index) => {
                        return <Link
                          to={category?.key ? `/category/${category.key}?career=${category.key}` : null}
                          className="fw-normal text-white text-decoration-none ms-2"
                        >
                          {" "} {category.category_name},{" "}
                        </Link>
                      })}

                    </div>
                  </div>}
                  <div className="RightAnimate-four">
                  <div className="iq-button ">
                            <Link
                              to={`/show-details/${data?.vanity_url}`}
                              className="btn text-uppercase position-relative px-4 py-3"
                            >
                              <span className="button-text"><Translate textKey={'play_now'} /></span>
                              <i className="fa-solid fa-play"></i>
                            </Link>
                          </div>
                  </div>
                </div>
                {data?.teaser_vanity_url && <div className="col-lg-5 col-md-12 trailor-video iq-slider d-none d-lg-block">
                  <Link to="#" className="video-open playbtn" tabIndex="0">
                    <svg
                      onClick={() => trailerButtonHandle(data?.teaser_vanity_url)}
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="80px"
                      height="80px"
                      viewBox="0 0 213.7 213.7"
                      enableBackground="new 0 0 213.7 213.7"
                      xmlSpace="preserve"
                    >
                      <polygon
                        className="triangle"
                        fill="none"
                        strokeWidth="7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeiterlimit="10"
                        points="73.5,62.5 148.5,105.8 73.5,149.1 "
                      ></polygon>
                      <circle
                        className="circle"
                        fill="none"
                        strokeWidth="7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeiterlimit="10"
                        cx="106.8"
                        cy="106.8"
                        r="103.3"
                      ></circle>
                    </svg>
                    <span
                      className="w-trailor"
                      onClick={() =>{ trailerButtonHandle(data?.teaser_vanity_url)}}
                    >
                      <Translate textKey={'watch_trailer'} />
                    </span>
                  </Link>
                </div>}
              </div>
            </div>
          </div>
        </SwiperSlide>))}

        <div
          className="swiper-banner-button-prev swiper-nav"
          id="home-banner-slider-prev"
        >
          <i></i>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 44 44"
            width="44"
            height="44"
            fill="none"
            stroke="currentColor"
          >
            <circle r="20" cy="22" cx="22"></circle>
          </svg>
        </div>
        <div
          className="swiper-banner-button-next swiper-nav"
          id="home-banner-slider-next"
        >
          <i></i>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 44 44"
            width="44"
            height="44"
            fill="none"
            stroke="currentColor"
          >
            <circle r="20" cy="22" cx="22"></circle>
          </svg>
        </div>
      </Swiper>
      {toggler && trailerLink!== null && <TrailerModal1 teaser={trailerLink} setTrailerModal={setToggler} appInfo={appInfo}/>}
    </Fragment>
  );
})

HomeHeroSlider.displayName = "HomeHeroSlider"
export default HomeHeroSlider