import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { getIdeabizToken } from "./service";
import IdeabizSubscribeModal from '../../Components/Modals/IdeabizSubscribeModal';
import IdeabizMobilePinModal from '../../Components/Modals/IdeabizMobilePinModal';
import IdeabizPurchaseModal from '../../Components/Modals/IdeabizPurchaseModal';

export default function Ideabiz() {
    const location = useLocation();

    const [ideabizToken, setIdeabizToken] = useState()
    const [subscribeModal, setSubscribeModal] = useState(false)
    const [pinVerifyModal, setPinVerifyModal] = useState(false)
    const [purchaseModal, setPurchaseModal] = useState(false)
    const [serverRef, setServerRef] = useState()
    const [subscriberPhone, setSubscriberPhone] = useState()

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location?.state?.subscription) {
            fetchIdeabizToken()
        }
    }, [])

    const fetchIdeabizToken = async () => {
        const ideabizTokenResponse = await getIdeabizToken(location?.state?.appInfo)
        if (ideabizTokenResponse.status == 200) {
            setIdeabizToken(ideabizTokenResponse?.data?.access_token)
            setSubscribeModal(true)
        } else {
            console.log('ideabizTokenFailed');
        }

    }

    return (
        <div className='ideabiz-containter'>
            {subscribeModal && <IdeabizSubscribeModal appInfo={location?.state?.appInfo} ideabizToken={ideabizToken} ideabizPlan={location?.state?.subscription} setSubscribeModal={setSubscribeModal} setPinVerifyModal={setPinVerifyModal} setServerRef={setServerRef} setSubscriberPhone={setSubscriberPhone} />}
            {pinVerifyModal && <IdeabizMobilePinModal appInfo={location?.state?.appInfo} ideabizToken={ideabizToken} setPinVerifyModal={setPinVerifyModal} serverRef={serverRef} setPurchaseModal={setPurchaseModal} />}
            {purchaseModal && <IdeabizPurchaseModal appInfo={location?.state?.appInfo} ideabizToken={ideabizToken} ideabizPlan={location?.state?.subscription} subscriberPhone={subscriberPhone} />}
        </div>
    )
}
