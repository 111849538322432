import { fetchApiData, fetchApiDataV2 } from "../../utils/utils"

export const getShowDetails=async (appInfo,showId)=>{
    
    const api=`show/${showId}`
    // if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
        return await fetchApiDataV2(appInfo,api)
    // }else{
    //     return await fetchApiDataV2(appInfo,api)
    // }
}
export const getSimilarVideos=async (appInfo,showId)=>{
    const api=`show/similar/${showId}`
    // if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
        return await fetchApiDataV2(appInfo,api)
    // }else{
    //     return await fetchApiData(appInfo,api)
    // }
}

export const updateLike = async (appInfo,showId,flag) => {
    const api = `like/show/${showId}/${flag}`
    return await fetchApiData(appInfo,api)
}
