import React,{useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useNavigate,useSearchParams } from "react-router-dom";
import Loading from '../../Components/Loading/Loading';
import { squareSubscriptionCheck } from "./service";

const SquarePaymentProcessing = () =>{
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const subscription_id = searchParams.get('subscriptionId');

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    let checkingInterval;
    let stopChecking = false;
    const checkPaymentStatus = async () => {
      if (stopChecking) {
        clearInterval(checkingInterval); 
        return;
      }
      try {
        const response = await squareSubscriptionCheck (appInfo,subscription_id);      
        if (response.data.data === "SUCCESS") {  
          localStorage.setItem('subscription_id', subscription_id);
          setLoading(false)
          navigate(`/success?square=true`)        
          stopChecking = true;
        } 
        else{
          stopChecking = false;
          setLoading(true)
        }
      } catch (error) {
        console.error("Error calling processing API:", error);
      }
    };
    checkingInterval  = setInterval(checkPaymentStatus, 5000);  
    return () => {
      clearInterval(checkingInterval ); 
    };
  }, []);

    return(
        <div className="squarepaymentProcessingScreen">
        <div className="wrapper">
          <div className="paymentContainer">
            {loading &&
                <Loading/>
            }
          </div>
        </div>
        </div>
    );
}
export default SquarePaymentProcessing;