import React, { useEffect, useState } from 'react'
import { fetchAppInfo } from '../../network/service';
import parse from "html-react-parser";
import Loading from '../../Components/Loading/Loading';
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import Translate from "../../Components/MultiLanguage/Translate"


const About = () => {
  const { t } = useTranslation();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const languageModal = useSelector(
    (state) => state?.languageModal?.value
  );

  useEffect(() => {
    window.scroll(0,0)
    fetchAboutUs();
  }, []);

  const fetchAboutUs = async () => {
    setLoading(true)
    try{
      const params = {
        type: "ABOUT_US",
      };
      const response = await fetchAppInfo(params);
      if (response?.status === 200) {
        setData(response?.data?.data?.value);
        setLoading(false);

      }
    }catch(err){
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
    
  };

  if(loading){
    return(
      <Loading/>
    )
  }

  return (
    <div className="aboutUs">
     
      <ToastContainer />
      <div className="wrapper"> {data ? parse(data) : <h1 className='noData'><Translate textKey={'no_data'} /></h1>}</div>
      {/* <div dangerouslySetInnerHTML={{__html: '<strong>strong text</strong>'}} /> */}
    </div>
  )
}

export default About
