import { memo, useRef } from "react";

// react-bootstrap
import { Container } from "react-bootstrap";

// react-router-dom
import { Link, useNavigate } from "react-router-dom";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

// Redux Selector / Action
import { useSelector } from "react-redux";
// import { theme_scheme_direction } from "../../store/setting/selectors";

const modules = [Autoplay, Navigation];

function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const SectionSlider = memo(
  ({
    children,
    title,
    list,
    slidesPerView = 6,
    loop = false,
    spaceBetween = 0,
    className = "",
    link,
    data,
    notViewAll=false,
    episode= false,
    episodeTitle,
    styleElement = true,
    margin = false,
    landscapeElement = false,
    shorts = false,
    landscapeCard = false
  }) => {
    // const themeSchemeDirection = useSelector(theme_scheme_direction);
    const themeSchemeDirection = '';
    const slider = useRef(null);
    const projectInfo = useSelector((state) => state?.projectInfo?.value);

    const initSwiper = (swiper) => {
      addCustomClassToVisibleSlides(swiper);
    };
const path = data?.type == 'SHORTS' ? '/shorts' :`/category/${data?.key}?career=${data?.key}`
    const addCustomClassToVisibleSlides = (swiper) => {
      if (slider.current) {
        if (swiper) {
          slider.current
            .querySelectorAll(".swiper-slide")
            .forEach((separateSlide) => separateSlide.classList.remove("last"));

          const swiperSlide = slider.current.querySelectorAll(
            ".swiper-slide-visible"
          );

          const lastVisibleSlide = swiperSlide[swiperSlide.length - 1];

          setTimeout(() => {
            if (lastVisibleSlide) {
              lastVisibleSlide.classList.add("swiper-active", "last");
            }
          }, 0);
        }
      }
    };

    return (
      <div className={className}>
        <Container fluid>
          <div className={"overflow-hidden card-style-slider" + (margin ? " margincontainer" : "")} ref={slider} >
            <div className={"d-flex align-items-center justify-content-between pr-3 " + (landscapeCard ? " mt-xl-3 mt-4 mb-xl-5 mb-md-4 mb-4" : " my-4 ")}>
              <h5 className="main-title text-capitalize mb-0 pl-0 title-margin-left" style={{width:"80%"}}>{title}</h5>
              {!notViewAll && <Link
                className="text-primary iq-view-all text-decoration-none"
                to={path}
                state={{"type":data?.type}}
              >
                View All
              </Link>}
              {episode && <Link
                className="text-primary iq-view-all text-decoration-none"
                to={`/episodes`}
                state={[list,episodeTitle && episodeTitle]}
              >
                View All ({list?.length})
              </Link>}
            </div>
            <Swiper
              key={themeSchemeDirection}
              dir={themeSchemeDirection}
              className={"position-relative swiper swiper-card" + (margin ? " minimumHeightContainer" : "")}
              slidesPerView={slidesPerView}
              loop={loop}
              watchSlidesProgress
              spaceBetween={spaceBetween}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                425:landscapeCard ? {
                  slidesPerView: 2,
                  spaceBetween: 0,
                }:{
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                576: {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
                768: shorts ? {
                  slidesPerView:4,
                  spaceBetween: 0,
                } :styleElement ? {
                  slidesPerView:5,
                  spaceBetween: 0,
                } :
                {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                980:shorts ? {
                  slidesPerView:4,
                  spaceBetween: 0,
                } :landscapeElement ? {
                  slidesPerView:3.5,
                  spaceBetween: 0,
                } :styleElement ? {
                  slidesPerView:6,
                  spaceBetween: 0,
                } :
                {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                1025: shorts ? {
                  slidesPerView:5,
                  spaceBetween: 0,
                } :landscapeElement ? {
                  slidesPerView:4.5,
                  spaceBetween: 0,
                } :styleElement ? {
                  slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT - 1 : 7,
                  spaceBetween: 0,
                } :
                {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
                1500: shorts ? {
                  slidesPerView:5,
                  spaceBetween: 0,
                } :landscapeElement ? {
                  slidesPerView:4.5,
                  spaceBetween: 0,
                } :styleElement ? {
                  slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT  : 8,
                  spaceBetween: 0,
                } :
                {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
              }}
              onSwiper={initSwiper}
              onSlideChange={initSwiper}
              modules={modules}
            >
              {list.map((data, index) => (
                <SwiperSlide tag="li" key={index + generateUUID() + "slider"}>
                  {children(data)}
                </SwiperSlide>
              ))}
              <div className="swiper-button swiper-button-next"></div>
              <div className="swiper-button swiper-button-prev"></div>
            </Swiper>
          </div>
        </Container>
      </div>
    );
  }
);

SectionSlider.displayName = "SectionSlider";

export default SectionSlider;
