import { ChannelBox, ChannelLogo } from "planby";
import { useDispatch } from "react-redux";
import { getSelectedChannel } from "../../../Redux/SelectedChannel/selectedChannelSlice";

export const ChannelItem = ({ channel }) => {
  const { position, logo,uuid } = channel;
  const dispatch = useDispatch()

  const selectedChannelHandler = () => {
    dispatch(
      getSelectedChannel({
        selectedChannel:{channel_id:uuid}
      })
    )
  }
  return (
    <ChannelBox {...position} onClick={()=>selectedChannelHandler()}>
      {/* Overwrite styles by add eg. style={{ maxHeight: 52, maxWidth: 52,... }} */}
      {/* Or stay with default styles */}
      <ChannelLogo
        src={logo}
        alt="Logo"
        style={{ maxHeight: 52, maxWidth: 52 }}
      />
    </ChannelBox>
  );
};
