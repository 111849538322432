import axios from "axios";
import { fetchApiData, fetchApiDataV2, updateApiDataV2,commentGifUpload,commentImageUpload } from "../../utils/utils";

export const getVideoDetails = async (appInfo,id) =>{
    const api =`video/${id}`
    // if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
        return await fetchApiDataV2(appInfo,api)
    // }else{
    //     return await fetchApiData(appInfo,api)
    // }
}

export const getLiveInfo = async (appInfo)=>{
    const api="channel/list"
    if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
        return await fetchApiData(appInfo,api)
    }else{
        return await fetchApiData(appInfo,api)
    }
    
  }


  export const commentImageUploadThumbnail = async (data,appInfo) =>{
    const api =`comments/image`
  
    return await commentImageUpload(data,appInfo,api)
  }
  export const commentGifUploadThumbnail = async (data,appInfo) =>{
    const api =`comments/gif`
  
    return await commentGifUpload(data,appInfo,api)
  }
