import { createSlice } from "@reduxjs/toolkit";

export const eventMoreInfo=createSlice({
    name:"eventMoreInfo",
    initialState:{
        value:{
            isModal:false
        }
    },
    reducers:{
        getEventMoreInfo:(state,action)=>{
            state.value=action?.payload?.eventMoreInfo
        }
    }
})

export const {getEventMoreInfo}=eventMoreInfo.actions;
export default eventMoreInfo.reducer