import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Translate from "../MultiLanguage/Translate"

const CurrentPasswordModal = (props) => {
  const [input, setInput] = useState("");
 
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    
    
  }, []);

  const validationPasword = () => {
    const {currentPassword} = props?.values
    let validationStatus =  true;
    let errors = {}
    if (!currentPassword) {
      errors.currentPassword = <Translate textKey={'current_password_required'} />
      validationStatus = false
    }
    props?.setErrors(errors)
    return validationStatus
  }

  const submitButton = (e) => {
    e.preventDefault();
    if (validationPasword() === true) {
      props?.updateAccountDetails()
    }

  }

  return (
    <div className="currentPasswordModal">
      <div className="overlayModal" onClick={()=>props.setPasswordModal(false)}></div>
      <div className="currentPasswordContainer">
        <label htmlFor="currentPassword"><Translate textKey={'enter_current_password'} /></label>
        <input
          type="password"
          name="currentPassword"
          id="currentPassword"
          value={props?.values?.currentPassword || ""}
          onChange={(item)=>props?.handleUpdateItem(item)}
        />
        {props?.errors?.currentPassword && <span className="error">{props?.errors?.currentPassword}</span>}
        <div className="buttonContainer" >
          <button onClick={(e) => submitButton(e)}><Translate textKey={'update'} /></button>
        </div>
      </div>
    </div>
  );
};

export default CurrentPasswordModal;
