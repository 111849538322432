import React, { useEffect, useState } from 'react'
import SubscriptionPlanDetails from '../../Components/SubscriptionPlanDetails/SubscriptionPlanDetails'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Loading from '../../Components/Loading/Loading';
import NotFound from '../NotFound/NotFound';

function CeyFlixHelpCenterPage() {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const [loading, setLoading] = useState(true);
    const navigate= useNavigate()

    useEffect(()=>{
        setLoading(true)
        window.scrollTo(0, 0);
        if(projectInfo?.projectConfig?.pubid !== 50014){
            return(
               navigate('/404') 
            )
        }
        setLoading(false)
    },[])

    if(loading){
        return(
          <Loading/>
        )
      }

    return (
        <div className='ceyFlixHelpCenter'>
            <div className="wrapper">
                <div className="helpPage">
                    <h2>Help Center</h2>
                    <div className='helpPagePoints'>
                        <div className="helpPoint">
                            <h3>What will CeyFLiX include?</h3>
                            <p>CeyFLiX is a streaming service offering ad-free programming with a variety of original feature-length films, documentaries, live-action and animated series, and short-form content, along with unprecedented access to an incredible library of film and television entertainment.</p>
                        </div>

                        <div className="helpPoint">
                            <h3>How much is CeyFLiX?</h3>
                        </div>
                        <SubscriptionPlanDetails />
                        <p className='paddingContainer'>Subscribe to get unlimited access to the stream from thousands of hours of premium films, dramas, and series. No Lock-IN Contract and can Cancel anytime.</p>

                        <div className="helpPoint">
                            <h3>How to contact CeyFLiX?</h3>
                            <p>If you have any issues with CeyFlix, please contact directly via email:<a className='linkStyle' href={`mailto:support@ausflix.com.au`}> support@ausflix.com.au </a></p>
                        </div>

                        <div className='helpPoint'>
                            <h3>How to watch CeyFLiX?</h3>
                            <p>CeyFlix supports playback on mobile devices, desktop browsers, and smart TVs. </p>
                            <p>Desktop web browser: - <Link to={'https://ceyflix.com'} > www.ceyflix.com </Link></p>
                            <ul>
                                <li>Firefox</li>
                                <li>Google chrome</li>
                                <li>Microsoft Edge</li>
                                <li>Safari browser</li>
                            </ul>
                            <p>Mobile Devices and Tablets </p>
                            <ul>
                                <li>Android and Apple</li>
                            </ul>
                            <p>Smart TV</p>
                            <ul>
                                <li>Android TV (LG or Samsung TV-Not support yet)</li>
                                <li>Apple TV </li>
                                <li>Roku TV</li>
                                <li>Fire TV</li>
                            </ul>
                        </div>

                        <div className="helpPoint">
                            <h3>How do I subscribe to CeyFLiX and where can I buy it? </h3>
                            <p>CeyFLiX will be available for purchase on the web (<Link to={'https://ceyflix.com'} > www.ceyflix.com </Link>) </p>
                        </div>

                        <div className="helpPoint">
                            <h3>Signup from Computer</h3>
                            <ol>
                                <li>Visit <Link to={'https://ceyflix.com/login'} > https://web.ceyflix.com/login </Link></li>
                                <li>Press the SUBSCRIBE button.</li>
                                <li>Choose the plan that's right for you.</li>
                                <li>Create an account by entering your email address, creating a password and submit.</li>
                                <li>Enter Email verification code.</li>
                                <li>Enter a payment method and wait till confirm the payment.</li>
                                <li>That's it and enjoy the channel.</li>
                            </ol>
                        </div>

                        <div className="helpPoint">
                            <h3>Signup from iPhone, iPad, or iPod Touch</h3>
                            <ol>
                                <li>Visit web browser  <Link to={'https://ceyflix.com/login'} > https://web.ceyflix.com/login </Link></li>
                                <p className='note'>NOTE: Sign up is not supported on the CeyFLiX ios app.</p>
                                <li>After signup from web, download the app and login to your account.</li>
                            </ol>
                        </div>

                        <div className="helpPoint">
                            <h3>Signup from Android</h3>
                            <ol>
                                <li>Visit web browser  <Link to={'https://ceyflix.com/login'} > https://web.ceyflix.com/login </Link></li>
                                <p className='note'>NOTE: Sign up is not supported on the CeyFLiX google play store app.</p>
                                <li>2.	After signup from web, download the app and login to your account.</li>
                            </ol>
                        </div>

                        <div className="helpPoint">
                            <h3>Smart TV and Streaming Media Players</h3>
                            <ol>
                                <li>Search the CeyFLiX app from store and open.</li>
                                <li>Visit web browser <Link to={'https://ceyflix.com/login'} > https://web.ceyflix.com/login </Link></li>
                                <p className='note'>NOTE: Sign up is not supported on the CeyFLiX any Tv app store.</p>
                                <li>After signup from web, then login to your account. </li>
                            </ol>
                        </div>

                        <div className="helpPoint">
                            <h3>What devices can I watch CeyFLiX?</h3>
                            <ul>
                                <li>Apple (iPhone, iPad, iPod touch and Apple TV, and fully integrated with the Apple TV app; customers can subscribe to CeyFlix via purchase from web site)</li>
                                <li>Google (Android phones, Android TV devices, Google Chromecast and Chromecast built-in devices)</li>
                                <li>Sony / Sony Interactive Entertainment (all Android based Sony TVs)</li>
                                <li>Roku (Roku® streaming players and Roku TV™ models)</li>
                                <li>Fire Tv</li>
                                <li>Samsung (Currently not available) </li>
                                <li>LG TV (Currently not available)</li>
                            </ul>
                        </div>

                        <div className="helpPoint">
                            <h3>Can I download Movies and Shows on CeyFLiX?</h3>
                            <p>Users are not allowed to download or record the movies and shows in CeyFlix. It’s an offence to do in the application.</p>
                        </div>

                        <div className="helpPoint">
                            <h3>How many screens can I watch CeyFlix?</h3>
                            <p>It is in the PRICE AND PLAN SECTION</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default CeyFlixHelpCenterPage