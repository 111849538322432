import React, { useState, useEffect } from 'react';
import { AutoSizer, List } from 'react-virtualized';

const IosLandingVideo = () => {
  const [currentIndex, setCurrentIndex] = useState(1); // Start from 1 for the first image
  const noOfImages = 695;
  let imageURL = 'https://gizmeon.mdc.akamaized.net/PUB-50057/thumbnails/landing/output_';
  const screenWidth = document.documentElement.clientWidth

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex % noOfImages) + 1);
    }, 100);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [noOfImages]);

  const rowRenderer = ({ key, style }) => (
    <div key={key} style={style}  >
      <img src={`${imageURL}${currentIndex.toString().padStart(4, '0')}.png`} alt={`Image ${currentIndex}`}  className="bannerImage" style={{width:screenWidth}} />
    </div>
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={15000} 
          width={screenWidth}
          rowCount={1} 
          rowHeight={15000}
          rowWidth={4000}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};

export default IosLandingVideo;