import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { fetchAppInfo } from "../../network/service";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";
import Translate from "../../Components/MultiLanguage/Translate";

const FAQ = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");

  useEffect(() => {
    window.scroll(0, 0);
    fetchFAQData();
  }, []);

  const fetchFAQData = async () => {
    setLoading(true);
    try {
      const params = {
        type: "FAQ",  
      };
      const response = await fetchAppInfo(params);
      if (response?.status === 200) {
        setData(response?.data?.data?.value);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="privacyPolicy">
      <ToastContainer />
      <div className="wrapper">
        {data ? parse(data) : <h1 className="noData"><Translate textKey={"no_data"} /></h1>}
      </div>
    </div>
  );
};

export default FAQ;
