import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import ShowCard from "../../Components/ShowCard/ShowCard";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as AddToWatchListButton } from "../../assets/Icons/addToWatchlist.svg";

import * as service from "./service";
import MainLoading from "../../Components/Loading/MainLoading";
import { useWindowWidth } from "@react-hook/window-size";
import { rowItemCount } from "../../utils/utils";
import Translate from "../../Components/MultiLanguage/Translate";
import CardStyle from "../../Components/cards/CardStyle";
import LandscapeThumbnailCard from "../../Components/cards/LandscapeThumbnailCard";

const MyList = () => {
  const isModalOpen = useSelector((state) => state?.moreInfoOther?.value);

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const myListRedux = useSelector((state) => state?.myList?.value);
  const [shows, setShows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [publisherName, setPublisherName] = useState("");
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [rowItemsClass, setRowItemsClass] = useState("");
  const [showInvisibleShow, setShowInvisibleShow] = useState(false);
  const windowSize = useWindowWidth();

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    if (windowSize > 980) {
      if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) {
        setRowItemsClass(
          rowItemCount(
            projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
          )
        );
      } else {
        if(projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"){
          setRowItemsClass("rowCount7");
        }else{
          setRowItemsClass("rowCount4");
        }
      }
    } else {
      setRowItemsClass("");
    }

    if (
      projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "3" &&
      windowSize > 980
    ) {
      if (shows?.length % 3 !== 0) {
        setShowInvisibleShow(true);
      }
    } else if (
      projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "4" &&
      windowSize > 980
    ) {
      if (shows?.length % 4 !== 0) {
        setShowInvisibleShow(true);
      }
    } else if (
      projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "5" &&
      windowSize > 980
    ) {
      if (shows?.length % 5 !== 0) {
        setShowInvisibleShow(true);
      }
    } else if (
      projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "6" &&
      windowSize > 980
    ) {
      if (shows?.length % 6 !== 0) {
        setShowInvisibleShow(true);
      }
    } else {
      setShowInvisibleShow(false);
    }

    if (shows?.length === 1) {
      setShowInvisibleShow(true);
    }
  }, [projectInfo, windowSize]);

  useEffect(() => {
    window.scroll(0, 0);
    fetchWatchlistShows();
  },[]);
  useEffect(() => {
    if (myListRedux) {
      setShows(myListRedux);
    }
  }, [myListRedux]);
  useEffect(() => {
    if (projectInfo) {
      setPublisherName(projectInfo?.projectConfig?.config?.TAB_TITLE);
    }
  }, [projectInfo]);
  const fetchWatchlistShows = async () => {
    try {
      const response = await service.fetchWatchlistShows(appInfo);
      if (response?.status === 200) {
        setShows(response?.data?.data);
        
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  if (loading) {
    return <MainLoading />;
  }
  return (
    <div className="myList">
      {/* {isModalOpen?.isModal && !isModalOpen?.isFromSearch && (
        <DetailScreenModal />
      )} */}

      <div className="wrapper">
        <div className="top">
          <h1 className="heading"><Translate textKey={'my_list_caps'}/></h1>
          
        </div>
        <div className="bottom">
          {shows?.length === 0 && (
            <div className="noContentInfo">
              <h4 className="heading"><Translate textKey={'welcome_to_your_mylist_title'}/></h4>
              <p>
              <Translate textKey={'mylist_sentence_first'}/>

              <AddToWatchListButton /> 

              <Translate textKey={'button'}/>
              <Translate textKey={'mylist_sentence_second'}/>
               
                {/* You can add any film that you want to watch. Just search for it,
                and hit the <AddToWatchListButton /> button. When they are
                available on {publisherName}, they’ll appear right here. So you
                won’t miss them. Magic. */}
              </p>
            </div>
          )}
          <div className="itemsContainer">
            {shows?.map((item, index) => (
              <div
                className={
                  thumbnailOrientation === `PORTRAIT`
                    ? `items portrait ${rowItemsClass}`
                    : `items landscape ${rowItemsClass}`
                }
                key={index}
              >
              {projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT" ? <CardStyle
                image={item?.logo}
                title={item?.show_name || item?.video_title}
                movieTime={item?.duration_text}
                link={`/show-details/${item?.vanity_url}`}
                show_Id={item?.show_id}
                share={true}
                vanity_url={item?.vanity_url}
                showWatchList={true}
                data={item}
                fetchWatchlistShows={fetchWatchlistShows}
              />
              :
              <LandscapeThumbnailCard
                image={item?.logo_thumb}
                title={item?.show_name || item?.video_title}
                movieTime={item?.duration_text}
                link={`/show-details/${item?.vanity_url}`}
                show_Id={item?.show_id}
                share={true}
                vanity_url={item?.vanity_url}
                showWatchList={true}
                data={item}
                fetchWatchlistShows={fetchWatchlistShows}
              />
            }
              </div>
            ))}

            {showInvisibleShow &&
              shows?.slice(0, 1).map((item, index) => (
                <div
                  className={
                    thumbnailOrientation === `PORTRAIT`
                      ? `items portrait hidden ${rowItemsClass}`
                      : `hidden items landscape ${rowItemsClass}`
                  }
                >
                   <CardStyle
                image={item?.logo}
                title={item?.show_name || item?.video_title}
                movieTime={item?.duration_text}
                link={`/show-details/${item?.vanity_url}`}
                show_Id={item?.show_id}
                share={true}
                vanity_url={item?.vanity_url}
                showWatchList={true}
                data={item}
                fetchWatchlistShows={fetchWatchlistShows}
              />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyList;
