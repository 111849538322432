import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import LinkTvApp from './LinkTvApp';

const ActivateIndex = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  useEffect(() => {
    window.scroll(0, 0)

  }, [])
  return (
    <div className="tvAppIndex">
      <LinkTvApp />
    </div>
  )
}

export default ActivateIndex