import React from "react";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/Icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/Icons/linkedIn.svg";
import { ReactComponent as AppStore } from "../../assets/Icons/appstore.svg";
import { ReactComponent as PlayStore } from "../../assets/Icons/playstore.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Translate from "../MultiLanguage/Translate";

const Footer = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const navigate = useNavigate();

  const linkHandler = (data) => {
    if (data?.seo_url) {
      navigate(data?.seo_url);
    } else if (data?.link) {
      window.open(data?.link);
    }
  };

  return (
    <div className="footerContainer">
      <div className="wrapper">
        <div className="topContainer">
          <div className="left">
            <div className="logoContainer">
              <img src={projectInfo?.projectConfig?.config?.LOGO} alt="Logo" />
            </div>
          </div>
          <div className="right">
            <ul className="social">
              {projectInfo?.projectConfig?.config?.FACEBOOK && (
                <li onClick={() => window.open(projectInfo?.projectConfig?.config?.FACEBOOK, "blank")}>
                  <FacebookIcon />
                </li>
              )}

              {projectInfo?.projectConfig?.config?.INSTAGRAM && (
                <li onClick={() => window.open(projectInfo?.projectConfig?.config?.INSTAGRAM, "blank")}>
                  <InstagramIcon />
                </li>
              )}
              {projectInfo?.projectConfig?.config?.TWITTER && (
                <li onClick={() => window.open(projectInfo?.projectConfig?.config?.TWITTER, "blank")}>
                  <TwitterIcon />
                </li>
              )}
              {projectInfo?.projectConfig?.config?.LINKEDIN && (
                <li onClick={() => window.open(projectInfo?.projectConfig?.config?.LINKEDIN, "blank")}>
                  <LinkedInIcon />
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="centerContainer">
          <div className="informationContainer">
            {/* {projectInfo?.projectConfig?.config?.CUSTOM_FOOTERS && <div className="items">
              <h6 className="heading"><Translate textKey={'company'} /></h6>
              <ul>
                {projectInfo?.projectConfig?.config?.CUSTOM_FOOTERS?.map((item) => {
                  return <li>

                    <span onClick={() => item.link ? window.open(item.link, '_blank') : navigate(`/${item.seo_url}`)}><Translate textKey={item.title} /></span>
                  </li>
                })}
              </ul>
            </div>}
            <div className="items">
              <h6 className="heading"><Translate textKey={'support_footer'} /></h6>
              <ul>
                <li>
                  <span onClick={() => navigate("/contact-support")}><Translate textKey={'contact_support'} /></span>
                </li>
              </ul>
            </div>
            <div className="items">
              <h6 className="heading"><Translate textKey={'get_the_apps'} /></h6>
              <ul>
                {projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL && (
                  <li>
                    <span onClick={() => window.open(projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL, "blank")}>
                      iOS
                    </span>
                  </li>
                )}
                {projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL && (
                  <li>
                    <span
                      onClick={() => window.open(projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL, "blank")}
                    >
                      Android
                    </span>
                  </li>
                )}
                {projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL && (
                  <li>
                    <span
                      onClick={() => window.open(projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL, "blank")}
                    >
                      Roku
                    </span>
                  </li>
                )}
                {projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL && (
                  <li>
                    <span
                      onClick={() => window.open(projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL, "blank")}
                    >
                      Amazon Fire
                    </span>
                  </li>
                )}
                {projectInfo?.projectConfig?.config?.ANDROID_TV_PLAY_STORE_URL && (
                  <li>
                    <span
                      onClick={() =>
                        window.open(projectInfo?.projectConfig?.config?.ANDROID_TV_PLAY_STORE_URL, "blank")
                      }
                    >
                      Android TV
                    </span>
                  </li>
                )}
                {projectInfo?.projectConfig?.config?.APPLE_TV_APP_STORE_URL && (
                  <li>
                    <span
                      onClick={() => window.open(projectInfo?.projectConfig?.config?.APPLE_TV_APP_STORE_URL, "blank")}
                    >
                      Apple TV
                    </span>
                  </li>
                )}
                 {projectInfo?.projectConfig?.config?.LG_CONTENT_STORE_URL && (
                  <li>
                    <span
                      onClick={() => window.open(projectInfo?.projectConfig?.config?.LG_CONTENT_STORE_URL, "blank")}
                    >
                      LG
                    </span>
                  </li>
                )}
                 {projectInfo?.projectConfig?.config?.SAMSUNG_APP_STORE_URL && (
                  <li>
                    <span
                      onClick={() => window.open(projectInfo?.projectConfig?.config?.SAMSUNG_APP_STORE_URL, "blank")}
                    >
                      Samsung
                    </span>
                  </li>
                )}
              </ul>
            </div>
            <div className="items">
              <h6 className="heading"><Translate textKey={'legal'} /></h6>
              <ul>
                <li>
                  <span onClick={() => navigate("/privacy-policy")}><Translate textKey={'privacy_policy'} /></span>
                </li>
                <li>
                  <span onClick={() => navigate("/terms-and-conditions")}><Translate textKey={'terms_of_use'} /></span>
                </li>
              </ul>
            </div>
            {projectInfo?.projectConfig?.pubid === 50041 && (
              <div className="items">
                <h6 className="heading"><Translate textKey={'faq_s'} /></h6>
                <ul>
                  <li>
                    <span onClick={() => navigate(`/page/FAQ-MMATV-Premium-Plan`)}>FAQ - MMATV Premium Plan</span>
                  </li>
                  <li>
                    <span onClick={() => navigate(`/page/FAQ-MMATV-Basic-Plan`)}>FAQ - MMATV Basic Plan</span>
                  </li>
                </ul>
              </div>
            )} */}
            {projectInfo?.projectConfig?.config?.WEBSITE_FOOTERS?.map((data, index) => (
              <div className="items" key={index}>
                <h6 className="heading"><Translate textKey={data.title} /></h6>
                <ul>
                  {data?.items?.map((item, index) => (
                    <li key={index}>
                      <span onClick={() => linkHandler(item)}><Translate textKey={item.title} /></span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bottomContainer">
        <div className="stores">
          {projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL && (
            <AppStore onClick={() => window.open(projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL, "blank")} />
          )}
          {projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL && (
            <PlayStore
              onClick={() => window.open(projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL, "blank")}
            />
          )}
        </div>
        <p>{projectInfo?.projectConfig?.config?.COPYRIGHT_TEXT}</p>
        <p><Translate textKey={'all_rights_reserved'} /></p>
        {projectInfo?.projectConfig?.config?.FOOTER_PAYMENTS_REQUIRED === "true" && (
          <div className="typeOfPayments">
            <i className="fa fa-2x fa-cc-discover"></i>
            <i class="fa-brands fa-2x fa-cc-mastercard"></i>
            <i className="fa fa-2x fa-cc-paypal"></i>
            <i className="fa fa-2x fa-cc-visa"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
