import React, { useEffect, useState } from "react";

import { fetchChannels, fetchEpg } from "./helpers";

import { useEpg } from "planby";
import { fetchLiveChannels } from "./service";

// Import theme
import { theme } from "./helpers/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  convertTimeToLocal,
  convertUTCToLocal,
  getCurrentTime,
  getCurrentTimeAndDate,
} from "../../utils/utils";
import { getLiveScheduleData } from "../../Redux/LiveScheduleData/liveScheduleDataSlice";
import moment from "moment";
import { timeFormatter } from "../../utils/utils";

export function useApp() {
  const [channels, setChannels] = React.useState([]);
  const [epg, setEpg] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [chan, setChan] = useState([]);
  const [ep, setEp] = useState([]);
  const channelsData = React.useMemo(() => channels, [channels]);
  const epgData = React.useMemo(() => epg, [epg]);
  const dispatch = useDispatch();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const epgHourWidth = projectInfo?.projectConfig?.config?.EPG_HOUR_WIDTH_WEBSITE
  ? projectInfo.projectConfig.config.EPG_HOUR_WIDTH_WEBSITE
  : 14000;
  const selectedScheduleChannel = useSelector(
    (state) => state?.selectedScheduleChannel?.value
  );
  
  const [channelLength,setChannelLength] = useState()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,
  };
  const startDate = moment().format('YYYY-MM-DD') + 'T' + "00:00:00";
  const getEndDate = () => {
    const today = moment().format("YYYY-MM-DD");
    const  formattedDate = moment(today).add(2, 'days').format("YYYY-MM-DD") + "T00:00:00";
    return formattedDate;
  }

  
  const { getEpgProps, getLayoutProps } = useEpg({
    channels: channelsData,
    epg: epgData,
    dayWidth: epgHourWidth,
    sidebarWidth: 100,
    itemHeight: 80,
    isSidebar: true,
    isTimeline: true,
    isLine: true,
    // startDate: getCurrentTimeAndDate(),
    // endDate: convertUTCToLocal("2023-05-17T24:00:00"),
    startDate: startDate,
    endDate: getEndDate(),
    // endDate: "2023-05-24T24:00:00",
    isBaseTimeFormat: true,
    isCurrentTime: true,
    theme,
  });
 
  const handleFetchResources = React.useCallback(async () => {
    setIsLoading(true);
    const liveData = await getLiveChannels();
    setIsLoading(false);
  }, []);

  const handleKeyNameChange = (array, type) => {
    if (type === "channels") {
      return array.map((item) => ({
        uuid: item?.id,
        type: item?.type,
        title: item?.channel_name,
        logo: item?.logo,
      }));
    } else {
      return array.map((item) => ({
        ...item,
        channelUuid: item?.channel_id,
        title: item?.title,
        description: item?.video_description,
        image: item?.image,
        since: item?.start,
        till: item?.end,
        // since:item?.starttime,
        // till:item?.endtime
      }));
    }
  };

  const getLiveChannels = async () => {
    const response = await fetchLiveChannels(appInfo);
    if (response?.status === 200) {
        localStorage.setItem("fastChannel","true")
      dispatch(
        getLiveScheduleData({
          liveSchedule: response?.data?.data,
        })
      );
      setChannelLength(response?.data?.data?.channels?.length)
      // let channelsData = response?.data?.data?.channels?.filter((item)=>item?.id===370 || item?.id === 385 || item?.id === 371 );
      let channelsData = response?.data?.data?.channels
      // let epgData = response?.data?.data?.schedules?.filter((item)=>item?.channel_id===370 || item?.channel_id === 385 || item?.channel_id === 371);
      let epgData = response?.data?.data?.schedules
      setChannels(handleKeyNameChange(channelsData, "channels"));
      setEpg(handleKeyNameChange(epgData, "epg"));
    }
  };

  React.useEffect(() => {
    handleFetchResources();
  }, [handleFetchResources]);

 

  return { getEpgProps, getLayoutProps, isLoading, channelLength };
}
