import React, { useEffect, useRef, useState, memo } from "react";
import { Button, Nav, Collapse, Navbar, Offcanvas, Container, Dropdown } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "../../assets/Icons/search.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/closeFilled.svg";
import { ReactComponent as MenuIcon } from "../../assets/Icons/MenuIcon.svg";
import { ReactComponent as DownArrow } from "../../assets/Icons/filledArrow.svg";
import { ReactComponent as CircleArrow } from "../../assets/Icons/filledCircleArrow.svg";
import { ReactComponent as SignOutIcon } from "../../assets/Icons/signOut.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import {
  appInfo,
  authenticate,
  getUserSubscription,
} from "../../network/service";
import * as service from "./service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import SmallLoading from "../Loading/SmallLoading";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import { imageUrlCheck } from "../../utils/utils";
import Translate from "../MultiLanguage/Translate"
import Logo from "./logo";
import CustomToggle from "./CustomToggle";

const Header = memo(() => {

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  const isModalOpen = useSelector((state) => state?.moreInfoOther?.value);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [menuItems, setMenuItems] = useState();
  const [browseHover, setBrowseHover] = useState(false);
  const [browseMoreMediumDevice, setBrowseMoreMediumDevice] = useState(false);
  const [position, setPosition] = useState(window.pageYOffset);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [userInfo, setUserInfo] = useState(false);
  const [previousLocation, setPreviousLocation] = useState("");
  const [menuHandler, setMenuHandler] = useState(false);
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [IsSearchLoading, setIsSearchLoading] = useState(false);
  const [showNewCheckoutLink, setShowNewCheckoutLink] = useState(true)
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);
  const searchRefContainer = useRef(null);
  const searchRefSmall = useRef(null);
  const searchRefSmallContainer = useRef(null);
  const searchSuggestionRef = useRef(null);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleMenuItemClick = () => {
    setShowDropdown(false);
  };


  useEffect(() => {

    // console.log('userDetails', userDetails);

    const handleScroll = () => {
      let moving = window.pageYOffset;
      // setHeaderVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    if (searchActive) {
      // searchRef?.current.focus();
      searchRefSmall?.current?.focus();
      searchRefSmallContainer?.current?.focus();
      setPreviousLocation(location);
    }
  }, [searchActive]);

  useEffect(() => {
    if (accessToken) {
      fetchUserSubscriptionDetails();
    }
  }, [user, accessToken]);

  useEffect(() => {
    fetchMenuItems();
  }, []);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !searchRefContainer?.current?.contains(e.target) &&
        !searchRefSmallContainer?.current?.contains(e.target)
      ) {
        setInputValue("");
        setSearchActive(false); //outside click
      }

      if (!searchSuggestionRef?.current?.contains(e.target)) {
        setInputValue("");
        // setSearchActive(false)
        setSearchResults(null);
      }

      if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
        setShowDropdown(false);
      }
    };

    window.addEventListener("click", handleOutClick);
    return () => {
      window.addEventListener("click", handleOutClick);
    };
  }, [
    searchRefContainer,
    searchRefSmallContainer,
    menuRef,
    searchSuggestionRef,
  ]);

  useEffect(() => {
    if (inputValue) {
      setIsSearchLoading(true);
      fetchSearchData();
    } else {
      setIsSearchLoading(false);
      setSearchResults(null);
    }
  }, [inputValue]);
  // useEffect(() => {
  //   searchResultsShow();
  // }, [searchResults]);
  const fetchMenuItems = async () => {
    try {
      const menuResponse = await service.getMenuItems(appInfo);
      if (menuResponse?.status === 200) {
        setMenuItems(menuResponse?.data?.data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
    }
  };

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {

      if (userSubResponse?.data?.length > 0) {

        setShowNewCheckoutLink(false);
        dispatch(
          getUserSubscriptionData({
            userSubscription: userSubResponse?.data,
          })
        );
      }

    }
  };

  /// Search functionality  start
  const searchSubmit = (value) => {
    if (value) {
      setInputValue("");
      setSearchActive(false);
      if (searchResults?.length > 0) {
        navigate(`/search?query=${value}`, { state: { searchResults } });
      } else {
        navigate(`/search?query=${value}`);
      }
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
  };
  const searchSubmitBrowseMore = (value) => {
    setSearchActive(true);
    if (value) {
      setInputValue("");
      if (searchResults?.length > 0) {
        navigate(`/search?query=${value}`, { state: { searchResults } });
      } else {
        navigate(`/search?query=${value}`);
      }
      setBrowseMoreMediumDevice(null);
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
    // if (!searchActive) {
    //   setSearchActive(true);
    // } else {
    //   setSearchActive(false);
    //   setBrowseMoreMediumDevice(null);
    // }
  };

  /// end

  const logoutHandler = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedSubId");
    localStorage.removeItem("deviceType");
    tokenAuthenticate();
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      navigate("/home");
      setTimeout(() => {
        dispatch(
          getUser({
            user: null,
          })
        );
      }, 300);
    }
  };

  const fetchSearchData = async () => {
    try {
      const response = await service?.getSearchData(appInfo, inputValue);
      if (response?.status === 200) {
        setSearchResults(response?.data?.data);
        setIsSearchLoading(false)
      } else {
      }
    } catch (err) {
      setIsSearchLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const showClickHandler = (data, medium) => {
    if (medium) {
      setBrowseMoreMediumDevice(false);
    }

    navigate(`/show-details/${data?.vanity_url ? data?.vanity_url : data?.show_id}`, { state: { isFromHome: true } })

    dispatch(
      getMoreInfoOther({
        moreInfoOther: {
          isModal: true,
          showId: data?.vanity_url ? data?.vanity_url : data?.show_id,
          isFromSearch: true
        }
      })
    )
    setSearchResults(null);
  };

  const searchClose = () => {
    setSearchActive(false);
    setInputValue("");
    setSearchResults(null);
  };

  // new
  const [isMega, setIsMega] = useState(true);
  // const location = useLocation();

  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const headerSticky = document.querySelector(".header-sticky");
      if (headerSticky) {
        if (window.scrollY > 1) {
          headerSticky.classList.add("sticky");
        } else {
          headerSticky.classList.remove("sticky");
        }
      }
    };

    const updateIsMega = () => {
      setIsMega(location.pathname === "/");
    };

    window.addEventListener("scroll", handleScroll);
    updateIsMega();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);
  const handleClose = () => {
    // Set the state to hide the Offcanvas
    setShow1(false)
  };

  const linkHandler = (link) => {
    window.open(link);
  };


  const handleToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <>
      <header className="header-center-home header-default header-sticky">
        <Navbar
          expand="xl"
          className="nav navbar-light iq-navbar header-hover-menu py-xl-0"
        >
          <Container fluid className="navbar-inner">
            <div className="d-flex align-items-center justify-content-between w-100 landing-header">
              <div className="d-flex gap-3 gap-xl-0 align-items-center">
                <div>
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#navbar_main"
                    aria-controls="navbar_main"
                    className="d-xl-none btn btn-color rounded-pill p-1 pt-0 toggle-rounded-btn"
                    onClick={() => setShow1(!show1)}
                  >
                    <svg width="20px" className="icon-20" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
                      ></path>
                    </svg>
                  </button>
                </div>
                <Logo logo={projectInfo?.projectConfig?.config?.LOGO}
                ></Logo>
              </div>
              <Navbar
                expand="xl"
                className={`offcanvas mobile-offcanvas nav hover-nav horizontal-nav py-xl-0 ${show1 === true ? "show" : ""
                  } ${isMega ? "mega-menu-content" : ""}`}
                style={{
                  visibility: `${show1 === true ? "visible" : "hidden"}`,
                }}
                id="navbar_main"
              >
                <Container fluid className="container-fluid p-lg-0">
                  <Offcanvas.Header className="px-0" onHide={handleClose}>
                    <div className="navbar-brand ms-3">
                      <Logo logo={projectInfo?.projectConfig?.config?.LOGO}></Logo>
                    </div>
                    <i className="fa fa-times fa-2x text-secondary cursor-pointer" onClick={handleClose}></i>
                  </Offcanvas.Header>
                  <ul
                    className="navbar-nav iq-nav-menu list-unstyled header-menu"
                    id="header-menu"
                  >
                    <Nav.Item as="li">
                      <Nav.Link
                        aria-expanded={open}
                        onClick={() => setOpen(!open)}
                        className={`${location.pathname === "/home"
                          ? "active"
                          : ""
                          }`}
                      >
                        <span className="item-name onhover" onClick={() => { if (location.pathname !== "/home") { navigate("/home") }; handleClose() }}>Home</span>

                      </Nav.Link>

                    </Nav.Item>

                    <Nav.Item as="li">
                      <Nav.Link
                        aria-expanded={open1}
                        href="#homePages"
                        onClick={() => setOpen1(!open1)}
                        className={`${location.pathname === "/related-merchandise" ||
                          location.pathname === "/restricted-content" ||
                          location.pathname === "/playlist" ||
                          location.pathname === "/geners" ||
                          location.pathname === "/cast" ||
                          location.pathname === "/tags" || location.pathname === "/watchlist-detail"
                          ? "active"
                          : ""
                          }`}
                      >
                        <span className="item-name onhover" ><Translate textKey={'browse'} /></span>
                        <span className="menu-icon ms-2">
                          <i
                            className="fa fa-caret-down toggledrop-desktop right-icon"
                            aria-hidden="true"
                          ></i>
                          <span className="toggle-menu">
                            <i
                              className="fa fa-plus  arrow-active text-white"
                              aria-hidden="true"
                            ></i>
                            <i
                              className="fa fa-minus  arrow-hover text-white"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </span>


                      </Nav.Link>
                      <Collapse in={open1} className="sub-nav list-unstyled ">
                        <ul className="max-height-scroll" >
                          {menuItems?.map((item, index) => (
                            <li key={index}>
                              <h1 className="categoryName">{item?.key}</h1>
                              {item?.items?.map((itemShow, keyIndex) => (
                                <Nav.Item as="li" key={keyIndex}>
                                  {itemShow?.type == 'LINK' ?
                                    <Link><span onClick={() => linkHandler(itemShow?.link)}> {itemShow?.value}</span></Link>
                                    : <Link
                                      to={`/category/${itemShow?.key}?career=${itemShow?.key}`}
                                      state={{ careers: itemShow?.key }}
                                      onClick={() => { setBrowseHover(null); handleClose() }}
                                    >
                                      <span> {itemShow?.value}</span>
                                    </Link>}
                                </Nav.Item>
                              ))}
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                    </Nav.Item>

                    {showNewCheckoutLink && projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' && location.pathname === '/home' && <Nav.Item as="li">
                      <Nav.Link>
                        <span onClick={() => { navigate("/checkout") }} className="item-name onhover" >{projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_BUTTON_TEXT ? projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_BUTTON_TEXT : 'Start Free Trial'}</span>
                      </Nav.Link>
                    </Nav.Item>}


                    {projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true" ? (<Nav.Item as="li">
                      <Link className="item-name" to="/live-channels" onClick={() => { handleClose() }}><Translate textKey={'live'} /></Link></Nav.Item>
                    ) : (projectInfo?.projectConfig?.config?.LIVE_REQUIRED === "true" &&
                      <Nav.Item as="li"><Link className="item-name" to="/player" state={{ isLive: true, prevLocation: location?.search ? location?.pathname + location?.search : location?.pathname }} onClick={() => { handleClose() }}>
                        <Translate textKey={'live'} />
                      </Link></Nav.Item>
                    )}

                    {projectInfo?.projectConfig?.config?.SHORTS_REQUIRED === "true" && user &&
                      <Nav.Item as="li"><Link className="item-name" to="/shorts" onClick={() => { handleClose() }}>
                        <Translate textKey={'shorts'} />
                      </Link></Nav.Item>
                    }

                    <Nav.Item as="li">

                      {
                        user &&
                        <Link className="item-name" to="/my-list" onClick={() => { handleClose() }}>
                          <Translate textKey={'my_list'} />
                        </Link>
                      }
                      {/* </Nav.Link> */}

                    </Nav.Item>

                  </ul>
                </Container>
              </Navbar>
              <div className="right-panel">
                <Button
                  id="navbar-toggle"
                  bsPrefix="navbar-toggler"
                  type="button"
                  aria-expanded={show}
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  onClick={() => setShow(!show)}
                >
                  <span className="navbar-toggler-btn">
                    <span className="navbar-toggler-icon"></span>
                  </span>
                </Button>
                <div
                  className={`navbar-collapse ${show === true ? "collapse show" : "collapse"
                    }`}
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav d-flex align-items-center justify-content-between ms-auto mb-2 mb-xl-0">

                    {/* jos */}
                    <div
                      className={
                        searchActive ? "inputContainer-header showInput" : "inputContainer-header"
                      }
                      ref={searchRefContainer}
                      onClick={() => {
                        setSearchActive(true);
                        searchRef?.current.focus();
                      }}
                    >
                      {searchActive ? (
                        <SearchIcon
                          onClick={
                            () => {
                              searchSubmit(inputValue);
                              searchRef?.current.focus();
                            }
                            // setSearchActive(!searchActive)
                          }
                        />
                      ) : (
                        <SearchIcon
                          onClick={() => {
                            searchRef?.current.focus();
                            setSearchActive(true);
                          }}
                        />
                      )}

                      <input
                        className="headerInput"
                        ref={searchRef}
                        onClick={() => setSearchActive(true)}
                        type="text"
                        value={inputValue || ""}
                        // placeholder={searchActive && "Find Movies, TV shows and more"}
                        placeholder="Search"
                        onChange={(e) => {
                          // searchHandleChange(e?.target?.value);
                          setInputValue(e?.target?.value);
                        }}
                        onKeyDown={(e) =>
                          e.key === "Enter" ? searchSubmit(inputValue) : ""
                        }
                      />

                      {!IsSearchLoading && searchResults?.length > 0 && inputValue && (
                        <div className="closeIcon">
                          <CloseIcon onClick={() => searchClose()} />
                        </div>
                      )}

                    </div>
                    <Dropdown as="li" className="nav-item" show={showDropdown} onClose={() => setShowDropdown(false)} ref={dropdownRef}>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        href="#"
                        variant="nav-link d-flex align-items-center"
                        size="sm"
                        id="dropdownMenuButton1"
                        onClick={toggleDropdown}
                      >
                        <div className="btn-icon rounded-pill user-icons">
                          <span className="btn-inner">
                            <svg
                              className="icon-18"
                              width="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.87651 15.2063C6.03251 15.2063 2.74951 15.7873 2.74951 18.1153C2.74951 20.4433 6.01251 21.0453 9.87651 21.0453C13.7215 21.0453 17.0035 20.4633 17.0035 18.1363C17.0035 15.8093 13.7415 15.2063 9.87651 15.2063Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.8766 11.886C12.3996 11.886 14.4446 9.841 14.4446 7.318C14.4446 4.795 12.3996 2.75 9.8766 2.75C7.3546 2.75 5.3096 4.795 5.3096 7.318C5.3006 9.832 7.3306 11.877 9.8456 11.886H9.8766Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M19.2036 8.66919V12.6792"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M21.2497 10.6741H17.1597"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu

                        // as="ul"
                        as="ul"
                        className="dropdown-menu-end dropdown-user border-0 p-0 m-0"
                        style={{
                          position: 'absolute',
                          width: '16rem',
                          right: '0px',
                          borderRadius: '5px',
                          zIndex: '99999',
                          color: 'black',
                          background: '#000'
                        }}

                      >
                        <ul className="p-0" >


                          {user && (
                            <>
                              <li onClick={handleMenuItemClick}>


                                <Link to="/settings"
                                  className="iq-sub-card d-flex align-items-center gap-3">
                                  {/* <li> */}
                                  <div className="icon " >
                                    <img
                                      src={require("../../assets/Icons/user.png")}
                                      alt="Icon"
                                    ></img>
                                  </div>
                                  <span style={{ color: "#fff" }}><Translate textKey={'account'} /></span>
                                  {/* </li> */}
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/tv" className="iq-sub-card d-flex align-items-center gap-3">
                                  {/* <li> */}
                                  <div className="icon">
                                    <img
                                      src={require("../../assets/Icons/television.png")}
                                      alt="Icon"
                                    ></img>
                                  </div>
                                  <span style={{ color: "#fff" }}><Translate textKey={'link_tv_app'} /></span>
                                  {/* </li> */}
                                </Link>
                              </li>
                            </>
                          )}

                          <li onClick={handleMenuItemClick}>
                            <Link to="/contact-support"
                              className="iq-sub-card d-flex align-items-center gap-3">
                              {/* <li> */}
                              <div className="icon">
                                <img
                                  src={require("../../assets/Icons/contact.png")}
                                  alt="Icon"
                                ></img>
                              </div>
                              <span style={{ color: "#fff" }}><Translate textKey={'contact_support'} /></span>
                              {/* </li> */}
                            </Link>
                          </li>
                          {user ? (
                            <li className="logoutAndLogin " onClick={() => { logoutHandler(); handleMenuItemClick() }}>
                              <span style={{ color: "#fff" }}><Translate textKey={'sign_out_of_appname'} /></span>
                            </li>
                          ) : (
                            <li className="logoutAndLogin" onClick={() => { navigate("/login"); handleMenuItemClick() }}>
                              <span style={{ color: "#fff" }}>
                                <Translate textKey={'log_in_to_appname'} /></span>
                            </li>
                          )
                          }
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </ul>

                  {/* {IsSearchLoading && (
                    <div className="searchLoading">
                      <SmallLoading />
                    </div>
                  )}

                  {!IsSearchLoading && searchResults?.length > 0 && inputValue && (
                    <div className="closeIcon">
                      <CloseIcon onClick={() => searchClose()} />
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </Container>
        </Navbar>
      </header>
    </>
  );
});

Header.displayName = "HeaderDefault";
export default Header;
