import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from "react-redux";
import { getShorts, getSingleShorts } from './service';
// import Videos from "./Videos";
import Videos from "./ReactPlayer";
import { useNavigate, useLocation } from 'react-router-dom';

// Debounce function to limit the scroll event firing
const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const Shorts = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [shorts, setShorts] = useState([]);
  const [shortsCollections, setShortsCollections] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const shortsContainerRef = useRef(null);
  const lastScrollTop = useRef(0); // Store the previous scroll position
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [visibleVideoId, setVisibleVideoId] = useState(null);
  const [visibleVideo, setVisibleVideo] = useState(null);
  const [isInitialShorts, setIsInitialShorts] = useState(true);
  const [previousPath, setPreviousPath] = useState(null);

  const appInfo = useMemo(() => ({ projectDetails: projectInfo, accessToken, sessionId }), [projectInfo, accessToken, sessionId]);

  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    const vanityUrl = location?.pathname?.split('/shorts/')[1];

    if (vanityUrl && isInitialShorts === true) {
      fetchSpecificShort(vanityUrl)
    } else {
      fetchShorts();
    }
  }, [])

  useEffect(() => {
    if (shortsContainerRef.current) {
      shortsContainerRef.current.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    if (visibleVideoId) {
      navigate(`/shorts/${visibleVideo?.vanity_url}`);
    }
  }, [visibleVideoId, navigate, visibleVideo]);

  useEffect(() => {
    setPreviousPath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const handlePopState = () => {
      if (previousPath && previousPath.startsWith('/shorts/')) {
        navigate('/');
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [previousPath, navigate]);

  const handleScroll = () => {
    if (!shortsContainerRef.current) return;
    
    const scrollTop = shortsContainerRef.current.scrollTop;
    const scrollHeight = shortsContainerRef.current.scrollHeight;
    const clientHeight = shortsContainerRef.current.clientHeight;
    
    // Check if user is near the bottom
    if (scrollTop + clientHeight < scrollHeight - 800) return;
    
    const index = shortsCollections.findIndex(
      (item) => item?.video_id === shorts[0]?.video_id
    );
    
    if (index !== -1 && index < shortsCollections.length - 1) {
      setIsInitialShorts(false);
      const currentItem = shortsCollections[index + 1] || shortsCollections[0];
      const nextItem = shortsCollections[index + 2] || shortsCollections[1];
      const lastItem = shortsCollections[index + 3] || shortsCollections[0];
    
      setShorts([currentItem, nextItem, lastItem]);
      determineVsibleVideo(); // Ensure the next video is visible
    }
    
    // Fetch more shorts if fewer than 10 items remain
    if (shortsCollections.length - index < 10) {
      fetchMoreShorts();
    }
    
    lastScrollTop.current = scrollTop;
  };
  
  
  const determineVsibleVideo = async () => {
    const containerRect = shortsContainerRef.current.getBoundingClientRect();
    const visibleVideos = shorts
      .filter((short) => {
        const videoElement = document.getElementById(short?.video_id);
        if (!videoElement) return false;
        const videoRect = videoElement.getBoundingClientRect();
        return (
          videoRect.top < containerRect.bottom &&
          videoRect.bottom > containerRect.top
        );
      })
      .map((short) => short);

    setVisibleVideo(visibleVideos[0]);
    setVisibleVideoId(visibleVideos[0]?.video_id || null);
  }

  const fetchSpecificShort = async (vanityUrl) => {
    const response = await getSingleShorts(appInfo, vanityUrl);
    if (response?.data?.data) {
      const responseAll = await getShorts(appInfo);
      if (responseAll?.data?.data) {
        setIsLoading(false);
        setShorts([...response.data.data, ...responseAll.data.data.slice(0, 2)]);
        setShortsCollections((prev) => [...prev, ...response.data.data, ...responseAll.data.data]); // Update collection 
      }
    }
  }

  const fetchShorts = async () => {
    if (isFetching) return;

    setIsFetching(true);
    const response = await getShorts(appInfo);
    if (response?.data?.data) {
      setIsLoading(false);
      setShorts((prev) => [...prev, ...response.data.data.slice(0, 3)]); // Update shorts      
      setShortsCollections((prev) => [...prev, ...response.data.data]); // Update collection      
    }
    setIsFetching(false);
  };

  const fetchMoreShorts = async () => {
    if (isFetching) return;

    setIsFetching(true);
    const response = await getShorts(appInfo);
    if (response?.data?.data) {
      setShortsCollections((prev) => [...prev, ...response.data.data]); // Update collection
    }
    setIsFetching(false);
  };

  useEffect(() => {
    const debouncedScroll = debounce(handleScroll, 300);

    if (shortsContainerRef.current) {
      shortsContainerRef.current.addEventListener('scroll', debouncedScroll);
    }

    return () => {
      if (shortsContainerRef.current) {
        shortsContainerRef.current.removeEventListener('scroll', debouncedScroll);
      }
    };
  }, [shorts, shortsCollections]);

  return (
    <div className="shorts">
      {isLoading ? (
        <div className="loader-div"><span className="loader"></span></div>
      ) : (
        <div className="shorts__videos" ref={shortsContainerRef} tabIndex={-1}>
          {shorts
            .map((short, index) => (
              <Videos
                key={short?.video_id}
                id={short?.video_id}
                src={short.url}
                like={short.like_count}
                share={0}
                shareUrl={short.share_url}
                title={short.title}
                desc={short.description}
                comment={short.comments_count}
                projectInfo={projectInfo}
                video_time={short.video_time}
                isVisible={isInitialShorts && index == 0 ? true : short?.video_id === visibleVideoId}
                isInitial={isInitialShorts}
                appInfo={appInfo}
                vanityUrl={short.vanity_url}
                liked_flag={short.liked ? 'blue' : null}
                poster={short?.thumbnail}
                handleScroll={handleScroll}
                shortsContainerRef={shortsContainerRef}
                debounce={debounce}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default Shorts;