import React from 'react'
import { useSelector } from 'react-redux';
import Translate from "../MultiLanguage/Translate";

const OpenApp = ({setIsOpenAppFeature}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const notNowHandler = () => {
    setIsOpenAppFeature(false)
  }
  // window.addEventListener('visibilitychange', function() {
  //   clearTimeout(openAppTimeout);
  // });

  const openAppHandler = () => {
    window.location.href = projectInfo?.projectConfig?.config?.APPLINK_URL
  }
  return (
    <div className='openAppContainer'>
      <h1><Translate textKey={'switch_to_app_title'} /></h1>
      <p><Translate textKey={'switch_to_app_sub_text'} /></p>
      <div className="buttons">
        <button onClick={notNowHandler} className="notNow"><Translate textKey={'switch_to_app_deny'} /></button>
        <button onClick={openAppHandler} className="openApp"><Translate textKey={'switch_to_app_confirm'} /></button>
      </div>
    </div>
  )
}

export default OpenApp
