import { Fragment, memo, useState } from "react";
import Translate from "../MultiLanguage/Translate"

//react-router-dom
import { Link } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Thumbs } from "swiper";
import { imageUrlCheck, convertMinutesToHoursAndMinutes, translateText } from "../../utils/utils";
import { useSelector } from "react-redux";

const VerticalSectionSlider = memo(({ sliderData }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }

  return (
    <Fragment>
      <div className="verticle-slider section-padding-bottom d-none d-md-block">
        <div className="slider">
          <div className="slider-flex position-relative">
            <div className="slider--col position-relative">
              <div
                className="vertical-slider-prev swiper-button"
                tabIndex="0"
                role="button"
                aria-label="Previous slide"
                aria-controls="swiper-wrapper-b130b4e10e1468117"
              >
                <i className="fa fa-chevron-up" style={{height:"22px"}}></i>
              </div>
              <div className="slider-thumbs" data-swiper="slider-thumbs">
                <div
                  className="swiper-container"
                  data-swiper="slider-thumbs-inner"
                >
                  <Swiper
                    spaceBetween={24}
                    slidesPerView={3}
                    navigation={{
                      nextEl: ".vertical-slider-next",
                      prevEl: ".vertical-slider-prev",
                    }}
                    loop={true}
                    autoplay={{
                      delay: 4000,
                      disableOnInteraction: false,
                    }}
                    watchSlidesProgress={true}
                    modules={[Navigation, Thumbs, Autoplay]}
                    thumbs={{
                      swiper:
                        thumbsSwiper && !thumbsSwiper.destroyed
                          ? thumbsSwiper
                          : null,
                    }}
                    direction="vertical"
                    className="swiper-wrapper top-ten-slider-nav"
                    
                  >
                    {sliderData?.shows?.map((data) => (
                      <SwiperSlide
                        key={data.title + "tranding-thumb"}
                        tag="li"
                        className="swiper-slide swiper-bg"
                      >
                        <div className="block-images position-relative">
                          <div className="img-box slider--image">
                            {
                              imageUrlCheck(data?.logo || data?.banner) === true ? (
                                <img className="img-fluid" src={data?.banner || data?.logo || data?.logo_thumb || data?.banner} onError={(e) => replaceImage(e)} alt="Banner1" loading="lazy" />


                              ) : (
                                <img className="img-fluid" src={`${process.env.REACT_APP_IMAGE_URL}${data?.banner || data?.logo}`} onError={(e) => replaceImage(e)} alt="Banner1" loading="lazy" />

                              )
                            }

                          </div>
                          <div className="block-description">
                            <h6 className="iq-title">
                              <Link >{data.title}</Link>
                            </h6>
                            <div className="movie-time d-flex align-items-center my-2">
                              <span className="text-body">
                                {data.movieTime}
                              </span>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="slider-next btn-verticle">
                    <i className="ri-arrow-down-s-line vertical-aerrow"></i>
                  </div>
                </div>
              </div>
              <div
                className="vertical-slider-next swiper-button"
                tabIndex="0"
                role="button"
                aria-label="Next slide"
                aria-controls="swiper-wrapper-b130b4e10e1468117"
              >
                <i className="fa fa-chevron-down" style={{height:"22px"}}></i>
              </div>
            </div>
            <div className="slider-images" data-swiper="slider-images">
              <Swiper
                spaceBetween={10}
                modules={[Navigation, Thumbs, Autoplay]}
                effect="fade"
                direction="vertical"
                className="swiper-container"
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                watchSlidesProgress={true}
                onSwiper={setThumbsSwiper}
              >
                {sliderData?.shows?.map((data) => (
                  <SwiperSlide
                    key={data.title + "tranding-main"}
                    tag="li"
                    className="swiper-slide"
                  >
                    <div className="slider--image block-images">
                      {
                        imageUrlCheck(data?.banner || data?.logo) === true ? (
                          <img src={data?.logo || data?.banner} onError={(e) => replaceImage(e)} alt="Banner" />


                        ) : (
                          <img src={`${process.env.REACT_APP_IMAGE_URL}${data?.logo || data?.banner}`} onError={(e) => replaceImage(e)} alt="Banner" />

                        )
                      }
                    </div>
                    <div className="description">
                      <div className="block-description">
                        <ul className="ps-0 mb-0 mb-1 pb-1 list-inline d-flex flex-wrap align-items-center movie-tag">
                          {data?.categories?.length > 0 && data?.categories?.map((category, index) => {
                            return <li className="position-relative text-capitalize font-size-14 letter-spacing-1">
                              <Link
                                to={category?.key ? `/category/${category.key}?career=${category.key}` : null}
                                className="text-white text-decoration-none"
                              >
                                {category?.category_name}
                                
                              </Link>
                            </li>
                          })}
                        </ul>
                        <h2 className="iq-title mb-3">
                          <Link >{data?.show_name}</Link>
                        </h2>
                        <div className="d-flex align-items-center gap-3 mb-3">
                          <div className="slider-ratting d-flex align-items-center">
                            {/* <ul className="ratting-start p-0 m-0 list-inline text-warning d-flex align-items-center justify-content-left">
                              <li>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              </li>
                            </ul> */}
                            <span className="text-white ms-2 font-size-14 fw-500">
                              {data?.duration_text}
                            </span>
                          </div>
                          <span className="text-body">{data.movieTime}</span>
                        </div>
                        <p className="mt-0 mb-3 line-count-2">
                          {data?.synopsis}
                        </p>
                        <div className="iq-button ">
                            <Link
                              to={`/show-details/${data?.vanity_url}`}
                              className="btn text-uppercase position-relative px-4 py-3"
                            >
                              <span className="button-text"><Translate textKey={'play_now'} /></span>
                              <i className="fa-solid fa-play"></i>
                            </Link>
                          </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
})

VerticalSectionSlider.displayName = "VerticalSectionSlider"
export default VerticalSectionSlider