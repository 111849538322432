import { Fragment, memo } from "react";

//react-bootstrap
import { ProgressBar } from "react-bootstrap";

//react-router-dom
import { Link } from "react-router-dom";

const ContinueWatchCard = memo(
  ({ watchedLength, link, imagePath, dataLeftTime, progressValue }) => {

    return (
      <Fragment>
        <div className="iq-top-ten-block">
          <div className="block-image position-relative">
            <div className="img-box">
              <Link className="overly-images" to={link} state={{ watchedLength }}>
                <img
                  src={imagePath}
                  alt="movie-card"
                  className="img-fluid object-cover"
                />
              </Link>
              <div className="iq-preogress">
                {/* <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                  {dataLeftTime}
                </span> */}
                <ProgressBar now={progressValue} style={{ height: "3px" }} />
              </div>
            </div>

          </div>
        </div>
      </Fragment>
    );
  }
);

ContinueWatchCard.displayName = "ContinueWatchCard";
export default ContinueWatchCard;
