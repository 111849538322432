import React from 'react'

const Sample = () => {
      // Usage
      const getDeviceModel = () => {
        const userAgent = navigator.userAgent;
        const model = userAgent.match(/\((.*?)\)/)[1];
        return model;
      }
  
  

  const operatingSystem = navigator.platform;
const screenWidth = window.screen.width;
const screenHeight = window.screen.height;
const language = navigator.language;

const getUserAgent = () => {
  const userAgent = navigator.userAgent;
  return userAgent
}


// Usage
const getIphoneModelName = () => {
    const userAgent = navigator.userAgent;
  
    // Regular expression to extract the iPhone model name from the user agent string
    const regex = /\biPhone(\w+);\s/i;
  
    // Extract the iPhone model name from the user agent string
    const match = userAgent.match(regex);
    if (match) {
      // Return the iPhone model name
      return `iPhone ${match[1]}`;
    } else {
      // Return a default value if the iPhone model name could not be extracted
      return 'Unknown';
    }
  }
  const getBatteryLevel = () => {
    navigator.getBattery().then((battery) => {
      const level = battery.level * 100;
      return level
    });
  }
  
  // Call the function to retrieve the iPhone model name
//   const iphoneModelName = getIphoneModelName();
  return (
    <div style={{paddingTop:"100px",color:"white",fontSize:"16px"}}>
        <h1 style={{fontSize:"16px"}}>device model : {getDeviceModel()}</h1>
        <h1 style={{fontSize:"16px"}}>operatingSystem : {operatingSystem}</h1>
        <h1 style={{fontSize:"16px"}}>screenWidth : {screenWidth}</h1>
        <h1 style={{fontSize:"16px"}}>screenHeight : {screenHeight}</h1>
        <h1 style={{fontSize:"16px"}}>language  :  {language}</h1>
        {/* <h1 style={{fontSize:"16px"}}>Battery : {getBatteryLevel()}</h1> */}
        <h1 style={{fontSize:"16px"}}>User Agent : {getUserAgent()}</h1>
        <h1 style={{fontSize:"16px"}}>IphoneModelName : {getIphoneModelName()}</h1>
    </div>
  )
}

export default Sample