import React, { useState } from 'react';
import { ReactComponent as LiveIcon } from "../../assets/Icons/live.svg";
import parse from "html-react-parser";
import { imageUrlCheck } from "../../utils/utils";

const LivePlayerCenter = ({ 
  loading, 
  channelData, 
  fastchannelData, 
  timeLeft, 
  livePlayer, 
  projectInfo 
}) => {
  const [sliceCount, setSliceCount] = useState(550);
  const [isReadMore, setIsReadMore] = useState(true);
  
  const readMoreOrLessHandler = (type) => {
    if (type === "more") {
      if (channelData?.video_description) {
        setSliceCount(channelData?.video_description?.length);
        setIsReadMore(false);
      }
    } else {
      setSliceCount(550);
      setIsReadMore(true);
    }
  };

  if (loading) return null;

  return (
    <div className="topplayerCenter">
      <div className="wrapper">
      <div className="videoContainer">{livePlayer}</div>
        <div className="bannerContainer">
          <div className="info">
            <div className="channelLogo">
              {fastchannelData?.logo && (
                imageUrlCheck(fastchannelData?.logo) 
                  ? <img src={fastchannelData?.logo} alt="Logo" />
                  : <img src={`${process.env.REACT_APP_IMAGE_URL}${fastchannelData?.logo}`} alt="Logo" />
              )}
            </div>
            <div className='infoContainer'>
            <div className="liveLabel">
              <LiveIcon />
              <span>LIVE</span>
            </div>

            <h1 className="title">{fastchannelData?.now_playing?.video_title}</h1>
            <div className="additionalInfo">
              {timeLeft && <h6 className="timeLeft">{timeLeft} left</h6>}
              {channelData?.rating && <span className="rating">{channelData?.rating}</span>}
            </div>
            <p className="description">
              {fastchannelData?.now_playing?.video_description && (
                <>
                  {parse(
                    fastchannelData?.now_playing?.video_description
                      ?.slice(0, sliceCount)
                      ?.split("\n")
                      ?.join("<br>")
                  )}
                  {fastchannelData?.now_playing?.video_description?.length > sliceCount && (
                    <>
                      {isReadMore ? (
                        <>
                          <span>...</span>
                          <span className="readMore" onClick={() => readMoreOrLessHandler("more")}>
                            Read More
                          </span>
                        </>
                      ) : null}
                    </>
                  )}
                  {!isReadMore && (
                    <span className="readLess" onClick={() => readMoreOrLessHandler("less")}>
                      Read Less
                    </span>
                  )}
                </>
              )}
            </p>
            </div>
           
          </div>
        </div>
       
      </div>
      <div className="gradient"></div>
      
    </div>
  );
};

export default LivePlayerCenter;
