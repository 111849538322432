import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  const user = useSelector((state) => state?.user?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  if (projectInfo?.projectConfig?.config?.INITIAL_PAGE !== "LOGIN") {
    return <Outlet />;
  } else if (projectInfo?.projectConfig?.config?.INITIAL_PAGE === "LOGIN") {
    // if (user) {
    return <Outlet />;
    // } else {
    //   return <Navigate to="/login" />;
    // }
  }
};

export default PrivateRoutes;
