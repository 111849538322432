import React, { useEffect } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
const FaqsPremium = () => {

  const faqPremiumData=[
    {
        question:"What are MMA TV Premium Plans?",
        answer:"MMA TV Premium Plans are subscription packages offered by MMA TV that provide exclusive access to live pay-per-view events and video-on-demand content related to mixed martial arts (MMA) and combat sports."
    },
    {
        question:"What is included in the Premium Plans?",
        answer:"The Premium Plans include all live pay-per-view events organised by MMA TV, allowing you to watch the fights in real-time. Additionally, you gain access to a wide range of video-on-demand content, which includes previous events, highlights, interviews, behind-the-scenes footage, and more."
    },
    {
        question:"Can I watch all the live pay-per-view events with the Premium Plans?",
        answer:" Yes, by subscribing to the MMA TV Premium Plans, you gain access to all the live pay-per-view events, ensuring that you don't miss any of the exciting fights happening in the world of MMA and combat sports."
    },
    {
        question:"Do I need to purchase pay-per-view events separately with the Premium Plans?",
        answer:"No, with the Premium Plans, you don't need to purchase pay-per-view events separately. All live pay-per-view events are included in your subscription, saving you the hassle of making additional payments for each event."
    },
    {
        question:"Can I watch the pay-per-view events on-demand if I miss the live broadcast?",
        answer:"Yes, the live pay-per-view events are available for on-demand viewing. However, they will only be available after the live event has concluded. With the Premium Plans, you can access a wide range of video-on-demand content, which includes previous events, highlights, and other exciting combat sports and MMA-related content."
    },
    {
        question:"Are there any limitations on the video-on-demand content?",
        answer:"The video-on-demand content available with the Premium Plans is extensive and covers a wide range of combat sports and MMA-related material. While specific limitations may vary based on licensing agreements, you can expect a vast library of previous events, highlights, interviews, and other related content."
    },
    {
        question:"Can I access the video-on-demand content outside of the live pay-per-view events?",
        answer:" Absolutely! The Premium Plans provide unrestricted access to the video-on-demand content at any time, regardless of whether there is a live pay-per-view event happening or not. You can explore and enjoy the on-demand content at your convenience."
    },
    {
        question:"How often is the video-on-demand content updated?",
        answer:"MMA TV strives to update the video-on-demand content regularly to ensure a fresh and engaging experience for its subscribers. However, the frequency of updates may vary based on the availability of new material and the scheduling of events."
    },
    {
        question:"How can I subscribe to the MMA TV Premium Plans?",
        answer:"To subscribe to the MMA TV Premium Plans, visit the official MMA TV website or the dedicated subscription page. You will find information on the available plans, pricing details, and instructions on how to sign up."
    },
    {
        question:"Are there different pricing options for Plans?",
        answer:"MMA TV offers different pricing options for the Premium and basic Plans to accommodate various preferences and budgets. The exact pricing details may vary, and it's best to refer to the official MMA TV subscription page for the most up-to-date information."
    },
    {
        question:"Are there any long-term commitments or contracts for the Premium Plans?",
        answer:"MMA TV offers flexible subscription options, and you can choose plans that suit your needs. While there may be options for longer-term commitments, such as annual plans, it's advisable to review the terms and conditions or contact MMA TV directly for specific information regarding commitments and contracts."
    },
  ]
  useEffect(()=>{
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
},[])

  return (
    <div className='faqs'>
        <div className="wrapper">
        <h1 className="heading">FAQ - MMA TV Premium Plans</h1>
        <Accordion allowZeroExpanded>
            {
                faqPremiumData?.map((item,index)=>(
                    
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            {item?.question}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            {item?.answer}
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))
            }
        </Accordion>
        <p className='nb'>Remember, it's always a good idea to visit the official MMA TV website or reach out to their customer support for the most accurate and up-to-date information on the Premium Plans and their offerings.</p>

        </div>
    </div>
  )
}

export default FaqsPremium