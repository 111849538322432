import React, { memo, Fragment, useState, useEffect } from "react";

// // react-bootstrap
import { Col } from "react-bootstrap";

//react-router-dom
import { Link } from "react-router-dom";

//react fslight-box
import FsLightbox from "fslightbox-react";
import VideoJS from "./VideoJsPlayer/VideoJS";
import TrailerModal from "./Modals/TrailerModal";
import TrailerModal1 from "./Modals/TrailerModel1";

const FsLightBox = memo((props) => {

  const [toggler, setToggler] = useState(true);


  return (
    <Fragment>
      <Col
        md="3"
        className="trailor-video  col-12 mt-lg-0 mt-4 mb-md-0 mb-1 text-lg-right"
        style={{ width: "254px" }}
      >
        <Link
          to="#"
          className="video-open playbtn block-images position-relative playbtn_thumbnail"
        >
          <img
            src={props.image}
            className="attachment-medium-large size-medium-large wp-post-image img-fluid"
            alt=""
            loading="lazy"
          />
          <span className="content btn btn-transparant iq-button">
            <i className="fa fa-play me-2 text-white" onClick={() => setToggler(!toggler)}></i>
            <span onClick={() => setToggler(!toggler)}>Play Trailer</span>
          </span>
        </Link>
      </Col>
      <div >
        {/* {trailerLink && <FsLightbox
          maxYoutubeVideoDimensions={{ width: 700, height: 400 }}
          exitFullscreenOnClose={true}
          toggler={toggler}
          sources={[
            {
              src: `${trailerUrl}`,
              type: "application/x-mpegURL",
            }
          ]} />
        } */}
        {/* {videoPlayer && videoPlayer} */}
        {/* <TrailerModal/> */}
        {toggler && <TrailerModal1 teaser={props?.trailerLink} setTrailerModal={setToggler} appInfo={props?.appInfo}/>}
      </div>
    </Fragment>
  );
});
FsLightBox.displayName = "FsLightBox";
export default FsLightBox;
