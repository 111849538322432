import { createSlice } from "@reduxjs/toolkit";

export const languageModalSlice=createSlice({
    name:"languageModal",
    initialState:{
        value:{
            isOpen:false,
            selectedLanguage:null,
            selectedLanguageCode:localStorage.getItem("selectedLanguageCode")? localStorage.getItem("selectedLanguageCode") :'en'
        }
    },
    reducers:{
        getLanguageModal:(state,action)=>{
            state.value=action?.payload?.languageModal
        }
    }
})

export const {getLanguageModal}=languageModalSlice.actions
export default languageModalSlice.reducer
