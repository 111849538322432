import React from 'react';
import { ReactComponent as TickIcon } from "../../assets/Icons/tick.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";


function SubscriptionPlanDetails() {
    return (
        <div className='subscriptionDetailsContainer'>
            <div className="wrapper">
                <div className="planContainer">
                    <h2> PRICE & PLAN FEATURES </h2>
                    <h3>CeyFLiX subscription plans are from month to month or yearly and, we offer 5 streaming plans to meet your needs. You can upgrade any time.</h3>
                    <div className='tableContainer'>
                        <table>

                            <tr>
                                <td ></td>
                                <td colSpan={2}>
                                    STANDARD
                                </td>
                                <td colSpan={2}>
                                    PREMIUM
                                </td>
                                <td>
                                    RENT
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>MONTHLY</td>
                                <td>YEARLY</td>
                                <td>MONTHLY</td>
                                <td>YEARLY</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PRICE</td>
                                <td>AUD 3.99</td>
                                <td>AUD 39.99</td>
                                <td>AUD 4.99</td>
                                <td>AUD 49.99</td>
                                <td>AUD 1.99</td>
                            </tr>
                            <tr>
                                <td>SD/HD AVAILABLE</td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                            </tr>
                            <tr>
                                <td>WATCH UNLIMITED MOVIES AND TV SHOWS</td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td>PER PROGRAM</td>
                            </tr>
                            <tr>
                                <td>SCREENS YOU CAN WATCH ON AT THE SAME TIME</td>
                                <td>2</td>
                                <td>2</td>
                                <td>4</td>
                                <td>4</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>WATCH CEYFLIX ON YOUR LAPTOP, PHONE</td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                            </tr>
                            <tr>
                                <td>WATCH CEYFLIX ON YOUR ROKU TV, FIRETV AND CAN CHROMECAST</td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                            </tr>
                            <tr>
                                <td>WATCH CEYFLIX ON YOUR ANDROID TV, APPLE TV</td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                            </tr>
                            <tr>
                                <td>WATCH CEYFLIX ON YOUR LG TV, SAMSUNG TV (COMING SOON)</td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                            </tr>
                            <tr>
                                <td>ADS ON VIDEOS</td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><TickIcon style={{ stroke: "white", height: "12px", width: "15px" }} /></td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                                <td><CloseIcon style={{ stroke: "white", height: "12px", width: "15px" }}/></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPlanDetails