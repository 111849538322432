import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-ads';
import 'videojs-ima';
import { checkOperatingSystem } from "../../utils/utils";

export const VideoJS = (props) => {
  const [controlColor, setControlColor] = useState();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;
  const location = useLocation();

  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uid = user_id;
  }

  // Configure `beforeRequest` for HLS requests only
  if (videojs.Vhs) {
    videojs.Vhs.xhr.beforeRequest = function (options) {
      if (options?.uri.includes(".m3u8?id=")) {
        options.headers = {
          pubid: projectInfo?.projectConfig?.pubid,
          channelid: projectInfo?.projectConfig?.channelid,
          uid: uid,
          "access-token": accessToken,
        };
      }
      return options;
    };
  } else {
    console.warn("VHS plugin is not available in this version of Video.js.");
  }

  useEffect(() => {
    // Initialize the Video.js player only once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));

      // Configure the IMA plugin for ads
      // if (props?.adUrl) {
      //   const imaOptions = {
      //     adTagUrl: props.adUrl,
      //   };

      //   player.ima(imaOptions);
      // }

    }

    // Cleanup function to dispose of the Video.js player on component unmount
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [options, videoRef, props.adUrl]);

  // Set control color based on project configuration
  useEffect(() => {
    setControlColor(projectInfo?.projectConfig?.config?.BUTTON_COLOR || '#fff');
  }, [projectInfo]);

  return (
    <div data-vjs-player>
      <style>{`
        .video-js .vjs-play-progress {
          background-color: ${controlColor};
        }
        .vjs-control:hover {
          color: ${controlColor}; 
        }
        .vjs-volume-level {
          background-color: ${controlColor} !important;
        }
        .vjs-control:hover > .vjs-icon-placeholder,
        .vjs-control:hover > .vjs-icon-play,
        .vjs-control:hover > .vjs-icon-pause,
        .vjs-control:hover > .vjs-icon-volume-high,
        .vjs-control:hover > .vjs-icon-captions,
        .vjs-control:hover > .vjs-icon-fullscreen-enter,
        .vjs-control:hover > .vjs-icon-fullscreen-exit {
          background: ${controlColor}; 
          color: ${controlColor}; 
        }
      `}</style>
      <video ref={videoRef} className="video-js vjs-big-play-centered">
        {
          props?.subtitles?.map((item, index) => (
            <track
              key={index}
              kind="captions"
              src={item.subtitle_url}
              srclang={item.short_code}
              label={item.language_name}
            />
          ))
        }
      </video>
    </div>
  );
};

export default VideoJS;
