import { Fragment, memo, useState } from "react";
import SectionSlider from "../slider/SectionSlider";
import CardStyle from "../../Components/cards/CardStyle";
import { useSelector } from "react-redux";
import LandscapeThumbnailCard from "../cards/LandscapeThumbnailCard";
const UpcomingMovies = memo(({showDetails, watchVideoHandler, title}) => {
  
  const [selectedSeason, setSelectedSeason] = useState(0)
  const [movies,setMovies] = useState(showDetails?.videos[0]?.videos)
  const handleChange = (event) => {
    setSelectedSeason(event.target.value)
    setMovies(showDetails?.videos[event.target.value]?.videos)
  };
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  return (
    <Fragment>
      <select className="season-select form-select bg-dark text-white" value={selectedSeason} onChange={handleChange}>
        {showDetails?.videos?.map((option, index) => {
          return (
            <option key={option.season} value={index}>{option.season}</option>
          );
        })}
      </select>
      {projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT" ? <SectionSlider
        title={title}
        list={movies}
        className="upcomimg-block streamit-block"
        slidesPerView="6"
        notViewAll={true}
        episode={true}
        episodeTitle={showDetails?.show_name}
        landscapeCard={projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT" ? false : true}
      >
        {(data) => (
          <CardStyle
          image={data?.thumbnail}
          title={data?.video_title}
          movieTime={data?.duration_text}
          watchlistLink={`/videos/${data?.vanity_url}`}
          watchVideo={watchVideoHandler}
          data={data}
          />
        )}
      </SectionSlider>
      :
      <SectionSlider
        title={title}
        list={movies}
        className="upcomimg-block streamit-block"
        slidesPerView={5}
        notViewAll={true}
        episode={true}
        episodeTitle={showDetails?.show_name}
        styleElement={false}
      >
        {(data) => (
          <LandscapeThumbnailCard
          image={data?.thumbnail_350_200}
          title={data?.video_title}
          movieTime={data?.duration_text}
          watchlistLink={`/videos/${data?.vanity_url}`}
          watchVideo={watchVideoHandler}
          data={data}
          />
        )}
      </SectionSlider>}
    </Fragment>
  );
});

// upcommingMovie.DisplayName = upcommingMovie;
export default UpcomingMovies;
