import React, { useEffect } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
const FaqsBasic = () => {
  const faqBasicData = [
    {
        question:"What is the MMA TV Basic Plan?",
        answer:"The MMA TV Basic Plan is a subscription package offered by MMA TV that provides access to a wide range of video-on-demand content related to mixed martial arts (MMA) and combat sports, including all previous events, reality shows, interviews and documentaries."
    },
    {
        question:"What is included in the Basic Plan?",
        answer:"The Basic Plan includes access to a vast library of video-on-demand content on MMA TV. This content encompasses previous events, highlights, interviews, behind-the-scenes footage, and more, providing an immersive experience for MMA and combat sports enthusiasts."
    },
    {
        question:"Can I watch live pay-per-view events with the Basic Plan?",
        answer:"No, the Basic Plan does not include access to live pay-per-view events. It is designed specifically to provide on-demand content, allowing you to enjoy all previous events and other related material at your convenience."
    },
    {
        question:"Can I watch the pay-per-view events on-demand if I have the Basic Plan?",
        answer:"Yes, the PPV event will be available after it has aired live. Unfortunately, the Basic Plan does not provide access to the viewing of live pay-per-view events. However, you can still enjoy the extensive library of video-on-demand content, which covers past events and various other MMA-related and combat sports related material."
    },
    {
        question:"Are there any limitations on the video-on-demand content available with the Basic Plan?",
        answer:"While the Basic Plan grants access to a wide range of video-on-demand content, the specific limitations may vary based on licensing agreements. However, you can expect a comprehensive collection of previous events, highlights, interviews, and other exciting combat sports and MMA-related content."
    },
    {
        question:"How often is the video-on-demand content updated with the Basic Plan?",
        answer:"MMA TV aims to update the video-on-demand content regularly to ensure a fresh and engaging experience for its subscribers. However, the frequency of updates may vary based on the availability of new material and the scheduling of events."
    },
    {
        question:"How can I subscribe to the MMA TV Basic Plan?",
        answer:"To subscribe to the MMA TV Basic Plan, visit the official MMA TV subscription page. You will find information on the available plans, pricing details, and instructions on how to sign up for the Basic Plan."
    },
    {
        question:"Are there different pricing options for the MMA TV Plans?",
        answer:"MMA TV offers different pricing options for the Basic Plan and Premium Plan, catering to various preferences and budgets. The exact pricing details may vary, so it's recommended to refer to the official MMA TV website or subscription page for the most up-to-date information."
    },
    {
        question:"Are there any long-term commitments or contracts for the Basic Plan?",
        answer:"The Basic Plan typically offers flexibility without long-term commitments or contracts. However, it's advisable to review the terms and conditions or contact MMA TV directly for specific information regarding commitments and contracts associated with the Basic Plan."
    },
    {
        question:"Can I upgrade from the Basic Plan to a Premium Plan in the future?",
        answer:"Yes, if you have the Basic Plan and decide you want access to live pay-per-view events and additional features, you can upgrade your subscription to one of the MMA TV Premium Plans. Simply follow the instructions provided by MMA TV to make the switch."
    },
  ]
useEffect(()=>{
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
},[])
  
  return (
    <div className='faqs'>
        <div className="wrapper">
        <h1 className="heading">FAQ - MMA TV Basic Plan</h1>
        <Accordion allowZeroExpanded>
            {
                faqBasicData?.map((item,index)=>(
                    
                    <AccordionItem key={index}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            {item?.question}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            {item?.answer}
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))
            }
        </Accordion>
        <p className='nb'>Remember, for the most accurate and up-to-date information regarding the Basic Plan and its offerings, it's always best to visit the official MMA TV website or reach out to their customer support.</p>

        </div>
    </div>
  )
}

export default FaqsBasic