import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { imageUrlCheck } from '../../utils/utils';
import Translate from "../MultiLanguage/Translate";

const NewsCard = ({ data }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION
  const navigate = useNavigate()


  let dates = new Date(data?.schedule_date)
  let month = dates.toLocaleString('en-us', { month: 'short' })
  let year = dates.getFullYear()
  let day = dates.getDate().toString().length == 1 ? '0' + dates.getDate() : dates.getDate()
  let isoFormat = day + " " + month + " " + year
  let newDate = isoFormat
  return (
    <div className='newsCard'>
      <div className="left">
        <div className="imageContainer">
          {imageUrlCheck(thumbnailOrientation === 'PORTRAIT' ? data?.logo_portrait : data?.logo_landscape) === false ? (
            <img
              src={
                thumbnailOrientation === "PORTRAIT"
                  ? `${process.env.REACT_APP_IMAGE_URL}${data?.logo_portrait
                  }`
                  : `${process.env.REACT_APP_IMAGE_URL}${data?.logo_landscape
                  }`
              }
              alt="ShowImage"
            />
          ) : (
            <img
              src={
                thumbnailOrientation === "PORTRAIT"
                  ? `${data?.logo_portrait}`
                  : `${data?.logo_landscape}`
              }
              alt="ShowImage"
            />
          )}
        </div>
      </div>
      <div className="right">
        <h1 className="title">{data?.title}</h1>
        <p>{data?.description}</p>
        <div className="dateSection">
          {/* <span className="icon">
                    <img src={require("../../images/icon-calendartime.png")} alt="Icon-Calender" />
                </span> */}
          <span className="date">{newDate}</span>
        </div>
        <div className="buttonContainer" onClick={() => navigate("/news-info", { state: { newsId: data?.news_id } })}>
          <div className="background"></div>
          <button className='bg-button'><Translate textKey={'read_more'} /></button>
        </div>
      </div>
    </div>
  )
}

export default NewsCard
