import axios from "axios";
import { fetchApiData } from "../../utils/utils";

export const getPlayerToken = async (accessToken,videoId) => {
    var token = accessToken;
    const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        "access-token": token,
      },
      params:{
        id:videoId
      }
    };
  
    return await axios.get(
      "https://api.gizmott.com/api/v1/playlistV2/generateToken",
      customConfig
    );
  };
  
export const getLiveInfo = async (appInfo)=>{
  const api=`fastchannel/${appInfo?.projectDetails?.projectConfig?.config?.FAST_CHANNEL_ID}`
    return await fetchApiData(appInfo,api)
}

