import React, { Fragment, memo, useState } from "react";

//component
import SectionSlider from "../Components/slider/SectionSlider";
import CardStyle from "../Components/cards/CardStyle";
import LandscapeThumbnailCard from "./cards/LandscapeThumbnailCard";
import { useSelector } from "react-redux";

//static data

const RelatedMovies = memo((props) => {
  const [title] = useState(props?.title);
  const [RelatedMovies] = useState(props?.movies);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  return (
    <Fragment>
      <SectionSlider
        title={title}
        list={RelatedMovies}
        className="related-movie-block"
        slidesPerView={5}
        notViewAll={true}
        episode={props?.episode}
        episodeTitle={props?.episodeTitle}
        styleElement={projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT" ? true : false}
        landscapeCard={projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT" ? false : true}
      >
        {(data) => {
          return (
            projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION == "PORTRAIT" ? (props.similarVideos ? <CardStyle
              image={data?.logo}
              title={data?.show_name}
              movieTime={data?.duration_text}
              watchlistLink={`/show-details/${data?.vanity_url}`}
              link={`/show-details/${data?.vanity_url}`}
              vanity_url={data?.vanity_url}
              share={true}
              data={data}
              showWatchList={true}
            /> : <CardStyle
              image={data?.thumbnail}
              title={data?.video_title}
              movieTime={data?.duration_text}
              watchlistLink={`/videos/${data?.vanity_url}`}
              watchVideo={props?.watchVideoHandler}
              data={data}
            />) : (
              props.similarVideos ? <LandscapeThumbnailCard
                image={data?.logo_thumb}
                title={data?.show_name}
                movieTime={data?.duration_text}
                watchlistLink={`/show-details/${data?.vanity_url}`}
                link={`/show-details/${data?.vanity_url}`}
                vanity_url={data?.vanity_url}
                share={true}
                data={data}
                showWatchList={true}
              /> : <LandscapeThumbnailCard
                image={data?.thumbnail_350_200}
                title={data?.video_title}
                movieTime={data?.duration_text}
                watchlistLink={`/videos/${data?.vanity_url}`}
                watchVideo={props?.watchVideoHandler}
                data={data}
              />
            )
          )
        }}
      </SectionSlider>
    </Fragment>
  );
});

RelatedMovies.displayName = "RelatedMovies";
export default RelatedMovies;
