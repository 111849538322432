import React, { useEffect, useState } from 'react'
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled'
import { useLocation } from 'react-router-dom'
const MainLoading = () => {
    const [li,setLi] = useState([])
    const screenWidth = useWindowWidth()
    const location = useLocation()
    useEffect(()=>{
      if(location?.pathname?.split("/")[1]!=="show-details" && location?.pathname?.split("/")[1]!=="event" && location?.pathname?.split("/")[1]!=="category"  ){
        window.scroll(0,0)

      }
        const interval = setInterval(() => {
            setLi([...li,<li></li>])
            let length=screenWidth>1200? 9:6
            if(li.length===length){
                setLi([])
            }
        }, 20*10);
      return () => {clearInterval(interval)};

        
    },[li,screenWidth])
  return (
    <div className='mainLoading'>

        <div className="topLoader"></div>
        <div className="lists">

          <ul className="loaders">
            <li></li>
              {
                li.map((item,index)=>(
                  item
                ))
              }
          </ul>
        </div>
    </div>
  )
}

export default MainLoading