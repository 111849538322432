import { fetchApiData, fetchApiDataV2 } from "../../utils/utils"

export const  getShowsByCategory=async (appInfo)=>{
    const api=`home`
    // if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
        return await fetchApiDataV2(appInfo,api)
    // }else{
    //     return await fetchApiData(appInfo,api)
    // }
}

