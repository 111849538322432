import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ideabizPurchaseSubscription } from "../../Screens/Subscription/service";

const IdeabizPurchaseModal = ({ appInfo, ideabizToken, ideabizPlan, subscriberPhone }) => {

  const [errors, setErrors] = useState({});
  const [submitButtonActive, setSubmitButtonActive] = useState(true);
  const navigate = useNavigate();

  const cancelHandler = () => {
    window.history.back()
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setSubmitButtonActive(false);
      const inputData = {
        token: ideabizToken,
        phone: subscriberPhone,
        amount: ideabizPlan?.ideabiz_price,
        serviceId: ideabizPlan?.ideabiz_keyword
      }
      const response = await ideabizPurchaseSubscription(appInfo, inputData);
      if (response?.status === 200 && response?.data.success == true) {
        toast.success(response?.data?.message, {
          position: "top-center",
        });
        const ideabizReceipt = response?.data?.data.amountTransaction.serverReferenceCode
        navigate(`/success`, {
          state: { ibr: ideabizReceipt },
        })
      } else {
        let errors = {};
        errors.phone = response?.data?.message;
        setErrors(errors);
        setSubmitButtonActive(true)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
      setSubmitButtonActive(true)
    }
  };

  return (
    <div className="otpModal">
      <ToastContainer />
      <div className="overlayOtp"></div>
      <div className="otpContainer">
        <h4>Confirmation</h4>
        <h6>Your {ideabizPlan?.subscription_type_name} Subscription is Rs {ideabizPlan?.ideabiz_price}+ Tax. Do you want to subscribe?</h6>
        {/* <label htmlFor="otp">Do you want to subscribe?</label> */}

        {errors?.phone && <span className="error">{errors.phone}</span>}
        <div className="buttonsContainer" >
          <button className="cancel" disabled={!submitButtonActive} onClick={(e) => submitButtonActive && cancelHandler(e)}>Cancel</button>
          <button disabled={!submitButtonActive} onClick={(e) => submitButtonActive && submitHandler(e)}>Subscribe</button>
        </div>
      </div>
    </div>
  );
};

export default IdeabizPurchaseModal;
