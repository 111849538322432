import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const Translate = ({ textKey }) => {

    const { t } = useTranslation();
    const languageModal = useSelector((state) => state?.languageModal?.value);
    const projectInfo = useSelector((state) => state?.projectInfo?.value);

    const translateText = () => {
        return languageModal?.selectedLanguageCode ? t(`${textKey}.${languageModal?.selectedLanguageCode}`).replace('APP_NAME', projectInfo?.projectConfig?.config?.TAB_TITLE) : ''
    }

    return (
        <>
            {translateText()}
        </>
    );
};

export default Translate;
