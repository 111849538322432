import { Fragment, memo, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TwitterShareButton, FacebookShareButton } from "react-share";
import { imageUrlCheck } from "../../utils/utils";
import { updateWatchlistData } from "../../network/service"
import { useDispatch, useSelector } from "react-redux";
import { getSelectedChannel } from "../../Redux/SelectedChannel/selectedChannelSlice";
import { getSelectedSchedule } from "../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";


const CardStyle = memo(({ title, movieTime, link, image, vanity_url, share, show_Id, watchVideo, data, subscriptionCheck = false, showWatchList = false, fetchWatchlistShows, isLiveCheck=false, magazine=false }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const locationUrl = (window.location.href).split("/")
  const url = link === 'shorts' ?  locationUrl[0] + "//" + locationUrl[2] + "/shorts/" + vanity_url:locationUrl[0] + "//" + locationUrl[2] + "/show-details/" + vanity_url

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  
  const dispatch = useDispatch()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  const [watchlist, setWatchList] = useState(data?.watchlist_flag)

  const updateWatchlist = async (flag) => {
    try {
      const response = await updateWatchlistData(appInfo, data?.show_id, flag);

      if (response?.status === 200) {
        if (flag === 1) {
          setWatchList(1);
        } else {
          setWatchList(0);
        }
        if (fetchWatchlistShows) {
          fetchWatchlistShows()
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addToWatchlist = () => {
    if (user) {
      updateWatchlist(1);
    } else {
      navigate("/login", { state: { path: location?.pathname } });
    }
  };

  const removeFromWatchlist = () => {
    updateWatchlist(0);
  };
  // useEffect(() => {
  //   if (link === "/live-channels") {
  //     dispatch(
  //       getSelectedChannel({
  //         selectedChannel: { channel_id: data?.channel_id }
  //       })
  //     )
  //     dispatch(
  //       getSelectedSchedule({
  //         selectedSchedule: { channel_id: data?.channel_id }
  //       })
  //     )
  //   }
  // }, [])
  return (
    <Fragment>
      <div className="iq-card card-hover">
        <div className="block-images position-relative w-100" >
          <div className="img-box w-100" onClick={() => { 
            if (watchVideo) { watchVideo(data) }
            if(link === "/live-channels"){
              dispatch(
                getSelectedChannel({
                  selectedChannel: { channel_id: data?.channel_id }
                })
              )
              dispatch(
                getSelectedSchedule({
                  selectedSchedule: { channel_id: data?.channel_id }
                })
              )
              navigate("/live-channels")
            }
          }}>
            {link === "/live-channels" ? <Link
              
              to={Link}
              state={{ movie: show_Id, data: data, videoDetails: { video_id: data?.video_id }, subscriptionCheck: subscriptionCheck }}
              className="position-absolute top-0 bottom-0 start-0 end-0"
            ></Link> :
            link === "shorts" ?
            <Link
              to={`/${link}/${data.vanity_url}`}
                state={{ movie: show_Id, data: data, videoDetails: { video_id: data?.video_id,vanity_url:data?.vanity_url }, subscriptionCheck: subscriptionCheck, isLive: isLiveCheck ? true : false, }}
                className="position-absolute top-0 bottom-0 start-0 end-0"
              ></Link>:
              <Link
                target={magazine ? "_blank" : "_self"} 
                rel={magazine ? "noopener noreferrer" : undefined}
                to={link}
                state={{ movie: show_Id, data: data, videoDetails: { video_id: data?.video_id }, subscriptionCheck: subscriptionCheck, isLive: isLiveCheck ? true : false, }}
                className="position-absolute top-0 bottom-0 start-0 end-0"
              ></Link>}
            {imageUrlCheck(image) ? (
              <img
                src={image}
                alt="movie-card"
                className="img-fluid object-cover w-100 d-block border-0"
              />
            ) : (
              <img
                src={
                  process.env?.REACT_APP_IMAGE_URL + image
                }
                alt="movie-card"
                className="img-fluid object-cover w-100 d-block border-0"
              />
            )}
            {data?.is_free_video === true ? (
              <div className="free-icon-img" >
                <img
                  style={{ aspectRatio: "unset" }}
                  src={require("../../assets/Images/free_icon.png")}
                  alt="FreeIcon"
                />
                
              </div>
            ) : data?.is_locked === true ? <div className="lock-icon-img">
              <img
                style={{ aspectRatio: "unset" }}
                src={require("../../assets/Images/lock.png")}
                alt="FreeIcon"
              />
            </div> : null}

          </div>
          <div className="card-description with-transition">
            <div className="cart-content">
              <div className="content-left">
                <h5 className="iq-title text-capitalize" style={{ maxHeight: "50px", overflow: "hidden" }}>
                <Link to="#" onClick={(e) => e.preventDefault()}>{title}</Link>
                </h5>
                <div className="movie-time d-flex align-items-center " style={{ minHeight: "24px", overflow: "hidden" }}>
                  <span className="movie-time-text font-normal text-white my-1" >{movieTime}</span>
                </div>
              </div>
              {/* <div className="watchlist">
                <Link className="watch-list-not" to={watchlistLink}>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon-10"
                  >
                    <path
                      d="M12 4V20M20 12H4"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <span className="watchlist-label">Watchlist</span>
                </Link>
              </div> */}
            </div>
          </div>
          <div className="block-social-info d-flex align-items-center gap-1">
            <ul className="p-0 m-0 d-flex gap-1 music-play-lists">
              {share && <li className="share position-relative d-flex align-items-center text-center mb-0">
                <span className="w-100 h-100 d-inline-block bg-transparent">
                  <i className="fas fa-share-alt"></i>
                </span>
                <div className="share-wrapper">
                  <div className="share-boxs d-inline-block">
                    <svg
                      width="15"
                      height="40"
                      className="share-shape"
                      viewBox="0 0 15 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.8842 40C6.82983 37.2868 1 29.3582 1 20C1 10.6418 6.82983 2.71323 14.8842 0H0V40H14.8842Z"
                        fill="#191919"
                      ></path>
                    </svg>
                    <div className="overflow-hidden">
                      <Link to="" target="_blank">
                        <FacebookShareButton url={url} >
                          <i className="fab fa-facebook-f"></i>
                        </FacebookShareButton>
                      </Link>
                      <Link to="" target="_blank">
                        <TwitterShareButton url={url} >
                          <i className="fab fa-twitter"></i>
                        </TwitterShareButton>
                      </Link>
                      {/* <Link to="#">
                        <i className="fas fa-link"></i>
                      </Link> */}
                    </div>
                  </div>
                </div>
              </li>}
              {showWatchList && <li className="share position-relative d-flex align-items-center text-center mb-0">
                {watchlist === 1 ?
                  <span className="w-100 h-100 d-inline-block bg-transparent cursor-pointer" onClick={removeFromWatchlist}>
                    <i class="fas fa-check"></i>
                  </span> :
                  <span className="w-100 h-100 d-inline-block bg-transparent cursor-pointer" onClick={addToWatchlist}>
                    <i className="fa-regular fa-plus" ></i>
                  </span>
                }

              </li>}
            </ul>
            <div className="iq-button">
              <Link
                target={magazine ? "_blank" : "_self"} 
                rel={magazine ? "noopener noreferrer" : undefined}
                to={link === 'shorts' ? `/${link}/${data.vanity_url}` : link}
                state={{ movie: show_Id, data: data, videoDetails: { video_id: data?.video_id,vanity_url:data?.vanity_url }, subscriptionCheck: subscriptionCheck,  isLive: isLiveCheck ? true : false,}}
                className="btn d-flex justify-content-center align-items-center text-uppercase position-relative rounded-circle"
              >
                {magazine ? <i className="fa-solid fa-download ms-0"></i> : <i className="fa-solid fa-play ms-0"></i>}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

CardStyle.displayName = "CardStyle";
export default CardStyle;
