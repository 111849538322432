import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import VideoJS from '../../Components/VideoJsPlayer/VideoJS';
import { convertAdUrl, convertTimeToLocal, getEventInfo, onVideoPlayFunction } from '../../utils/utils';
import { getLiveInfo } from './service';
import { ReactComponent as BackButton } from "../../assets/Icons/backButton.svg";
import WatchWithoutAdsModal from '../../Components/Modals/WatchWithoutAdsModal';
import ContinueWatchingModel from '../../Components/Modals/ContinueWatchingModel';
import { getPlayerToken } from '../../network/service';
import Translate from '../../Components/MultiLanguage/Translate';
import Comment from '../Videoplayer_and_Comments/Components/Comment';
let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;
const Videoplayer = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const sessionId = useSelector((state) => state?.sessionId?.value);

  const [vPlayer, setVPlayer] = useState()
  const [videoUrl, setVideoUrl] = useState("")
  const [watchWithoutAds, setWatchWithoutAds] = useState(false)
  const [isContinueWatchingModal, setIsContinueWatchingModal] = useState(false)
  const [watchedDuration, setWatchedDuration] = useState()
  const [adUrl, setAdUrl] = useState()
  const [loading, setLoading] = useState(false)
  const [liveDetails, setLiveDetails] = useState()
  const [subtitleInfo, setSubTitleInfo] = useState()
  const [subscribedUser, setSubscribedUser] = useState(false)

  const location = useLocation()
  const videoDetails = location?.state?.videoDetails
  const showDetails = location?.state?.showDetails
  const isLive = location?.state?.isLive
  const eventId = location?.state?.eventId
  const previousUrl = location?.state?.prevLocation ? location?.state?.prevLocation : '/home'
  const videoType = location?.state?.showDetails?.type === "linear_event" ? "event" : "video"

  const playerRef = useRef(null);

  const navigate = useNavigate()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,

  };
  const liveDetailsRef = useRef(liveDetails);

  useEffect(() => {
    liveDetailsRef.current = liveDetails;
  }, [liveDetails]);

  useEffect(() => {
    window.scroll(0, 0)
    if (isLive) {
      setLoading(true)
      fetchLiveInfo()
    } else if (eventId) {
      setLoading(true)
      fetchEventInfo()
    }
    else {

      if (videoDetails) {
        const isSubscribedUser =
          userSubscriptionData?.data?.length > 0
            ? subscriptionCheck(
              userSubscriptionData?.data,
              videoDetails?.subscriptions
            )
            : false;
        setSubscribedUser(isSubscribedUser)
        if (videoDetails?.free_video) {
          if (!isSubscribedUser) {
            if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "false") {
              if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
                setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive))
                playVideo()
              } else {
                playVideo()
              }
            } else {
              if (videoDetails?.subscriptions?.length === 0) {
                setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo))
                playVideo()

              } else {
                if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
                  setWatchWithoutAds(true)
                } else {
                  playVideo()
                }
              }


            }
          } else {
            if (videoDetails?.watched_duration) {
              setIsContinueWatchingModal(true)
            } else {
              playVideo()
            }
          }
        } else if (videoDetails?.watched_duration) {
          setIsContinueWatchingModal(true)
        } else {
          playVideo()
        }
      }
    }
  }, [])
  useEffect(() => {

    if (liveDetails?.length > 0) {
      const startTime = new Date(liveDetails[0]?.up_next?.start_time).getTime();
      const currentTime = new Date().getTime();

      // Calculate the time difference
      const timeUntilStart = startTime - currentTime;

      if (timeUntilStart > 0) {
        const timer = setTimeout(() => {
          if (eventId) {
            fetchEventInfo()
          } else {
            fetchLiveInfo()
          }
        }, timeUntilStart);

        return () => clearTimeout(timer);
      }

      if (timeUntilStart <= 0) {
        // console.log('Up next has already started!');
      }
    }

  }, [liveDetails]);
  useEffect(() => {
    setVPlayer(null);
    if (videoUrl) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],
        // poster:require("../../../images/thumb.png")
      };

      setVPlayer(
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
          subtitles={subtitleInfo}
        />
      );
    }
  }, [videoUrl]);

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) =>
      videoSub.some((video) => user.sub_id === video.subscription_id)
    );
    return hasMatch;
  };

  const playVideo = async (watchedLength) => {
    if (watchedLength) {
      setWatchedDuration(watchedLength)
    }
    setSubTitleInfo(videoDetails?.subtitles)
    const arr = videoDetails?.video_name?.split("/").reverse();
    const playerTokenData = await fetchPlayerToken(arr[1]);

    if (playerTokenData) {
      setVPlayer(null)
      // setVideoUrl(
      //           "https://poppo.tv/playlist/playlist.m3u8?id=" +
      //             arr[1] +
      //             "&token=" +
      //             playerTokenData +
      //             "&type=video" +
      //             "&pubid=" +
      //             projectInfo?.projectConfig?.pubid
      //         );
      setVideoUrl(
        "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
        arr[1] +
        "&token=" +
        playerTokenData +
        "&type=" + videoType +
        "&pubid=" +
        projectInfo?.projectConfig?.pubid
      );

    }
  }

  const fetchPlayerToken = async (videoId) => {
    const playerTokenResponse = await getPlayerToken(appInfo, videoId);
    if (playerTokenResponse?.status === 200) {
      return playerTokenResponse?.data?.data;
    }
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      videoPlaying = true;
      updateVideoAnalytics(event, player.currentTime());
    });
    player.on("loadedmetadata", () => {
      // player.pause();
      if (watchedDuration > 0) {
        player.currentTime(watchedDuration.toString());
        // player.play();
        // videoPlaying = true;
      } else {
        // player.play();
        // videoPlaying = true;
      }
    });
    player.on("timeupdate", function (e) {
      let event = "POP03";
      videoPlaying = true;
      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        updateVideoAnalytics(event, player.currentTime());
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      videoPlaying = true;
      updateVideoAnalytics(event, player.currentTime());
    });
    player.on("ended", () => {
      let event = "POP05";
      videoPlaying = false;
      // episodeCount = findIndex() + 1;
      updateVideoAnalytics(event, player.currentTime());
      // if (episodes[episodeCount]) {
      //   setNextEpisodeModal(true);
      // }
    });
    player.on("dispose", () => {
      videoPlaying = false;
      videoStarted = false;
      player.pause();
      console.log("disposed");
    });
  };

  const updateVideoAnalytics = async (event, time) => {
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      eventId ? liveDetails : isLive ? liveDetailsRef?.current[0] : videoDetails,
      event,
      time,
      isLive || eventId ? "1" : "0",
      location?.state?.categories,
      showDetails?.show_id
    );
  };

  const fetchLiveInfo = async () => {
    try {
      const response = await getLiveInfo(appInfo);
      setLiveDetails(response?.data?.data)
      setVideoUrl(response?.data?.data[0]?.live_link)
    } catch (err) {
      setLoading(false)
    }
  }

  const fetchEventInfo = async () => {
    try {
      const response = await getEventInfo(appInfo, eventId);
      setLiveDetails(response?.data?.data)
      setVideoUrl(response?.data?.data?.live_url)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const backButtonHandler = () => {
    navigate(previousUrl, { state: { showId: showDetails?.show_id } })
  }

  const firstItem = Array.isArray(liveDetails) && liveDetails.length > 0 ? liveDetails[0] : null;
  const nowPlaying = firstItem ? firstItem.now_playing : null;
  const upNext = firstItem ? firstItem.up_next : null;

  return (
    <div className='live-video-wrapper'>
      <div className='videoPlayer'>
        {watchWithoutAds && (
          <WatchWithoutAdsModal
            setWatchWithoutAds={setWatchWithoutAds}
            showDetails={showDetails}
            appInfo={appInfo}
            setAdUrl={setAdUrl}
            videoDetails={videoDetails}
            playVideo={playVideo}
            setIsContinueWatching={setIsContinueWatchingModal}
          />
        )}
        {isContinueWatchingModal && (
          <ContinueWatchingModel
            setIsContinueWatchingModal={setIsContinueWatchingModal}
            videoDetails={videoDetails}
            playVideo={playVideo}
            showDetails={showDetails}
            appInfo={appInfo}
            setAdUrl={setAdUrl}
          />
        )}
        <div className="topContainer">
          <div className="backButton" onClick={() => backButtonHandler()}>
            <BackButton />
          </div>
          <h1 className="title">{videoDetails?.video_title || liveDetails?.event_name || liveDetails?.video_title}</h1>

        </div>
        {vPlayer}
        {
          !eventId &&
          <div className="bottom">
            <div className="details">
              <div className="left">
                <h1 className="heading"><Translate textKey={'now_playing'} /></h1>
                <h1 className="title">
                  {(liveDetails?.now_playing?.video_title) ? (liveDetails?.now_playing?.video_title) : (nowPlaying?.video_title)}
                </h1>
                <span className="time">
                  {convertTimeToLocal((liveDetails?.now_playing?.start_time) ? (liveDetails?.now_playing?.start_time) : (nowPlaying?.start_time))
                    .toString()
                    .toLowerCase()}
                </span>
                <p className="description">
                  {(liveDetails?.now_playing?.video_description) ? (liveDetails?.now_playing?.video_description) : (nowPlaying?.video_description)}
                </p>
              </div>
              <div className="right">
                <h1 className="heading"><Translate textKey={'up_next'} /></h1>
                <h1 className="title">{(liveDetails?.up_next?.video_title) ? (liveDetails?.up_next?.video_title) : (upNext?.video_title)}</h1>
                <span className="time">
                  {convertTimeToLocal((liveDetails?.up_next?.start_time) ? (liveDetails?.up_next?.start_time) : (upNext?.start_time))
                    // .toString()
                    // .toLowerCase()
                  }
                </span>

                <p className="description">
                  {(liveDetails?.up_next?.video_description) ? (liveDetails?.up_next?.video_description) : (upNext?.video_description)}
                </p>
              </div>
            </div>
          </div>
        }


      </div>
      <span className="line"></span>
      {projectInfo?.projectConfig?.config?.COMMENTS_REQUIRED === "true" &&
        <div classname='cmt-live-wrapper' style={{ background: 'black', width: '100%', height: 'auto' }} >
          <Comment videoId={`event-${liveDetails?.event_id}`} projectInfo={projectInfo} />
        </div>
      }
    </div>


  )
}

export default Videoplayer
